import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Mosaic from "../components/Mosaic/Mosaic";
import Back from '../assets/back.png';
import Title from "../components/Title/Title";
import Header from "../components/Header/Header";
import WorkList from "../components/WorkList/WorkList";
import MosaicSmall from "../components/MosailSmall/MosaicSmall";
import styles from "./WorkPage.module.css";
import gridIcon from "../assets/grid_icon.png";
import projectsData from '../data/projects.js';
import { useLanguage } from '../context/LanguageContext.js';
import globalTranslations from '../data/translations.js';
import MOSAIC_CONFIGS from '../data/mosaic.js';

const MOBILE_BREAKPOINT = 430;

const WorkPage = () => {
  const [showMosaic, setShowMosaic] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_BREAKPOINT);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  
  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { language } = useLanguage();
  const translations = globalTranslations.workPage;

  const handleImagesLoaded = () => {
    setImagesLoaded(true);
  };

  const toggleMosaic = useCallback(() => {
    setShowMosaic(prev => !prev);
    setIsRotated((prev) => !prev);
  }, []);

  useEffect(() => {
    const savedScrollPosition = localStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      const scrollTimeout = setTimeout(() => {
        window.scrollTo({
          top: savedScrollPosition,
          behavior: 'smooth'
        });
        localStorage.removeItem('scrollPosition');
      }, 100);

      return () => clearTimeout(scrollTimeout);
    }
  }, []);

  useEffect(() => {
    let abortController = new AbortController();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
    };

    const loadImages = async () => {
      const images = document.querySelectorAll('img');
      setImagesLoaded(false);

      const promises = [...images].map(img => {
        return new Promise(resolve => {
          if (img.complete) resolve();
          img.onload = resolve;
          img.onerror = resolve;
        });
      });

      try {
        await Promise.all(promises);
        if (!abortController.signal.aborted) {
          setImagesLoaded(true);
        }
      } catch (e) {
        console.warn('Image loading aborted', e);
      }
    };

    window.addEventListener('resize', handleResize);
    loadImages();

    return () => {
      abortController.abort();
      window.removeEventListener('resize', handleResize);
    };
  }, [showMosaic]);

  const ToggleButton = useMemo(() => (
    <button
      className={`${styles.toggleBtn}`}
      onClick={toggleMosaic}
    >
      <img
        src={gridIcon}
        alt="Toggle view"
        style={{ transform: isRotated ? 'rotate(90deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }} // Add smooth transition
      />
    </button>
  ), [isRotated, toggleMosaic]);


  const MosaicView = useMemo(() => {
    if (isMobile) {
      return (
        <div className={styles.mosaicContent}>
          <MosaicSmall projects={projectsData} />
        </div>
      );
    }

    return (
      <>
        {!imagesLoaded && <div className={styles.spinner} />}
        <div className={`${styles.mosaicWrapper} ${imagesLoaded ? styles.loaded : ''}`}>
          {Object.keys(MOSAIC_CONFIGS).map((key, index) => (
            <Mosaic key={index} props={MOSAIC_CONFIGS[key]} onImagesLoaded={handleImagesLoaded} />     
          ))}
          
        </div>
        
      </>
    );
  }, [isMobile, imagesLoaded]);

  return (
    <div className={`${styles.workPage} ${isMobile && showMosaic ? styles.mosaicSmallView : ''}`}>
      <div className={styles.headerSection}>
        <Header />
        <div className={styles.titleWrapper}>
          <div className={styles.buttonsWrapper}>
            {ToggleButton}
          </div>
          <Title titleName={translations[language].title}/>
        </div>
      </div>
      <div className={`${styles.contentWrapper} ${showMosaic ? styles.mosaicView : styles.listView}`}>
        {showMosaic ? MosaicView : (
            <WorkList />
        )}
      </div>
      <button
        className={`${styles.scrollBackBtn} ${!showMosaic ? styles.hidden : ''} ${isAtTop ? styles.notVisible : ''}`}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <img src={Back} alt="Scroll Back" />
      </button>
    </div>
  );
};

export default WorkPage;
