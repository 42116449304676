import React, { useEffect, useRef } from 'react';
import Player from '@vimeo/player';
import Title from "../components/Title/Title.js"
import Header from "../components/Header/Header.js";
import globalTranslations from '../data/translations.js'
import { useLanguage } from '../context/LanguageContext.js';
import showreels from '../data/showreels.js'
import styles from './ShowreelPage.module.css'

function ShowreelPage() {
    const { language } = useLanguage();
    const translations = globalTranslations.showreelPage;
    const playersRef = useRef({});
    const currentlyPlayingRef = useRef(null);
    
    useEffect(() => {
        const players = {};
        
        showreels.forEach(showreel => {
            const iframe = document.getElementById(`vimeo-${showreel.id}`);
            const player = new Player(iframe);
            players[showreel.id] = player;
            
            player.on('play', () => {
                const previouslyPlaying = currentlyPlayingRef.current;
                
                if (previouslyPlaying && previouslyPlaying !== showreel.id) {
                    const previousPlayer = players[previouslyPlaying];
                    if (previousPlayer) {
                        previousPlayer.pause();
                    }
                }
                
                currentlyPlayingRef.current = showreel.id;
            });

            player.on('ended', () => {
                if (currentlyPlayingRef.current === showreel.id) {
                    currentlyPlayingRef.current = null;
                }
            });

            player.on('pause', () => {
                if (currentlyPlayingRef.current === showreel.id) {
                    currentlyPlayingRef.current = null;
                }
            });
        });
        
        playersRef.current = players;
        
        return () => {
            Object.values(players).forEach(player => {
                player.unload();
            });
        };
    }, []);

    return (
        <div className={styles.showreelPage}>
            <Header />
            <Title titleName={translations[language].title}/>
            <div className={styles.videoList}>
                {showreels.map((showreel) => (
                    <div key={showreel.id} className={styles.videoWrapper}>
                        <iframe
                            id={`vimeo-${showreel.id}`}
                            src={`https://player.vimeo.com/video/${showreel.vimeoId}?autoplay=0&title=0&byline=0&portrait=0&badge=0&transparent=0&controls=1&chromecast=0&vimeo_logo=0&pip=0`}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                        <div className={styles.videoTitle}>
                            <div className={styles.name}>
                                <p>{showreel.name}</p>
                                <p>{showreel.year}</p>
                            </div>
                            <div className={styles.credits}>
                                {showreel.credits?.music ? <p>MUSIC : {showreel.credits.music}</p> : ""}
                                {showreel.credits?.da ? <p>DA : {showreel.credits.da}</p> : ""}
                            </div>
                        </div>
                       
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ShowreelPage;