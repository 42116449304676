import React, { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import VimeoVideo from '../VimeoVideo/VimeoVideo.js';
import projectsData from '../../data/projects.js';
import styles from './WorkList.module.css';
import workListOrder from "../../data/workListOrder.js";
import globalTranslations from '../../data/translations.js';
import { useLanguage } from '../../context/LanguageContext.js';

const ProjectLine = React.memo(({ 
  project, 
  isSelected, 
  onClick, 
  onDoubleClick 
}) => (
  <div
    className={`${styles.line} ${isSelected ? styles.selectedLine : ''}`}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
  >
    <p>{project.customer.toUpperCase()}</p>
    <p>{project.name.toUpperCase()}</p>
    <p>
      {project.director && project.director.length > 0 ? project.director[0].toUpperCase() : ""}
    </p>
  </div>
));

ProjectLine.displayName = 'ProjectLine';

const MediaDisplay = React.memo(({ selectedProject }) => {
  if (!selectedProject) return null;

  return  (
    <div className={styles.medias}>
      <div className={styles.videoWrapper}>
        <VimeoVideo 
          videoId={selectedProject.vimeoId} 
          isWorkList
        />
      </div>
    </div>) 
});

MediaDisplay.displayName = 'MediaDisplay';

const WorkList = () => {
  const [selectedProject, setSelectedProject] = useState(projectsData[84]);
  const [searchValue, setSearchValue] = useState("");
  const { language } = useLanguage();
  const translations = globalTranslations.workPage;

  const navigate = useNavigate();

  const handleLineClick = useCallback((project) => {
    setSelectedProject(project);
  }, []);

  const handleDoubleClick = useCallback((projectId) => {
    navigate(`/project/${projectId}`);
  }, [navigate]);

  const sortedProjects = useMemo(() => {
    const orderMap = new Map(workListOrder.map((id, index) => [id, index]));
    
    return [...projectsData].sort((a, b) => {
      const indexA = orderMap.has(a.id) ? orderMap.get(a.id) : Infinity;
      const indexB = orderMap.has(b.id) ? orderMap.get(b.id) : Infinity;
      return indexA - indexB;
    });
  }, []);

  const filteredProjects = useMemo(() => {
    if (!searchValue.trim()) {
      return sortedProjects;
    }
    
    const searchTerm = searchValue.toLowerCase();
    
    return sortedProjects.filter(project => 
      project.customer.toLowerCase().includes(searchTerm) || 
      project.name.toLowerCase().includes(searchTerm) ||
      (project.director && 
       project.director.length > 0 && 
       project.director[0] && 
       project.director[0].toLowerCase().includes(searchTerm))
    );
  }, [sortedProjects, searchValue]);

  const projectList = useMemo(() => (
    <div className={styles.list}>
      <input 
        className={styles.searchbar}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={translations[language].searchbar}
      />
      <div className={styles.wrappedList}>
        {filteredProjects.map((project) => (
        <ProjectLine
          key={project.id}
          project={project}
          isSelected={selectedProject === project}
          onClick={() => handleLineClick(project)}
          onDoubleClick={() => handleDoubleClick(project.id)}
        />
      ))}
      </div>
    </div>
  ), [selectedProject, handleLineClick, handleDoubleClick, filteredProjects, searchValue, language]);

  return (
    <div className={styles.mainWrapper}>
      <MediaDisplay selectedProject={selectedProject} />
      {projectList}
    </div>
  );
};

export default React.memo(WorkList);
