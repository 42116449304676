import React, { useState } from 'react';
import styles from './ContactForm.module.css'; 
import arrow from '../../assets/arrow_forward_white.png';
import { useLanguage } from '../../context/LanguageContext.js';
import globalTranslations from '../../data/translations.js';

function ContactForm() {
    const { language } = useLanguage();
    const translations = globalTranslations.contactPage;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`http://localhost:8000/send_email.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(formData).toString(),  // Convert formData to URL-encoded format
            });

            const result = await response.text();
            alert(result);  // Display success or error message
        } catch (error) {
            console.error('Error submitting the form:', error);
            alert('There was an error submitting the form. Please try again.');
        }
    };

    return (
        <div className={styles.form}>
            <h2 className={styles.title}>{translations[language].message}</h2>
            <form onSubmit={handleSubmit} className={styles.form}>
                <input 
                    name="name" 
                    placeholder={translations[language].name} 
                    value={formData.name}
                    onChange={handleChange}
                    className={styles.input}
                />
                <input 
                    name="email" 
                    placeholder={translations[language].email} 
                    value={formData.email}
                    onChange={handleChange}
                    className={styles.input}
                />
                <input 
                    name="subject" 
                    placeholder={translations[language].subject} 
                    value={formData.subject}
                    onChange={handleChange}
                    className={styles.input}
                />
                <textarea 
                    name="message" 
                    placeholder='Message' 
                    value={formData.message}
                    onChange={handleChange}
                    className={styles.input}
                />
                <button type="submit" className={styles.submit}>{translations[language].sendButton} <img className={styles.arrow} alt="send" src={arrow} ></img></button>
            </form>
        </div>
    );
}

export default ContactForm;
