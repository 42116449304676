import ContactForm from "../components/ContactForm/ContactForm";
import Title from "../components/Title/Title";
import React, { useState } from 'react';  
import styles from './ContactPage.module.css'; 
import arrow from '../assets/arrow_forward.png';
import linkedin from '../assets/linkedin.svg';
import facebook from '../assets/facebook.svg';
import insta from '../assets/insta.svg';
import Header from "../components/Header/Header.js";
import { useLanguage } from '../context/LanguageContext.js';
import globalTranslations from '../data/translations.js';

function ContactPage() {
    const { language } = useLanguage();
    const translations = globalTranslations.contactPage;
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('submit')
        const formData = new URLSearchParams();
        formData.append('email', email);
        console.log(email)
        try {
            const response = await fetch('http://localhost:8000/newsletter-signup.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `email=${email}`
            });
            const result = await response.text();
            alert(result);
        } catch (error) {
            console.error('Error submitting the form:', error);
            alert('There was an error submitting the form. Please try again.');
        }
    };

    return (
        <div className="page">
            <Header />
            <Title titleName="CONTACT"/>
            <div className={styles.mainWrapper}>
                <div className={styles.leftPart}>
                    <div className={styles.inTouch}>
                        <h2>{translations[language].inTouch}</h2>
                        <div className={styles.section}>
                            <p className={styles.sectionTitle}>{translations[language].offices}</p>
                            <div>
                                <p>6, rue d'Enghien 75010 PARIS, FRANCE</p>
                            </div>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.sectionTitle}>COORDINATION</p>
                            <div>
                                <p>Léa Villegoureix</p>
                                <p>+33 6 82 71 12 37</p>
                                <p>lea.villegoureix@st-louis.tv</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.newsletter}>
                        <h2>{translations[language].newsletter}</h2>
                        <form className={styles.form} onSubmit={handleSubmit}>
                            <input 
                                className={styles.input} 
                                placeholder={translations[language].email} 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button className={styles.arrowButton} type="submit">
                                <img className={styles.arrow} src={arrow} alt="Submit" />
                            </button>
                        </form>
                    </div>
                </div>
                <div className={styles.rightPart}>
                    <ContactForm />
                </div>
            </div>
            <div className={styles.links}>
                <a href="https://www.instagram.com/stlouisposthouse/?hl=fr"><img src={insta} alt="Instagram" /></a>
                <a href="https://fr.linkedin.com/company/st-louis-post-house"><img src={linkedin} alt="LinkedIn" /></a>
                <a href="https://www.facebook.com/stlouisposthouse/?locale=fr_FR"><img src={facebook} alt="Facebook" /></a>
            </div>
        </div>
    );
}

export default ContactPage;

