const showreels = [
    {
        id: 1,
        name: "SHOWREEL GENERAL",
        year: "2017-2025",
        vimeoId: 1054524995,
        
        credits: {
            music: 'MarHan',
            da: 'Vanessa Bosio',
        }
    },
    {
        id: 2,
        name: "SHOWREEL",
        year: "2024",
        vimeoId: 1072193386,
        
        credits: {
            music: 'MarHan',
            da: 'Vanessa Bosio',
        }
    },
]

export default showreels
