import { getMediaPath } from "../utils/utils.js"

const projectsData = [
    {
        id: 1,
        vimeoId: 1022545896,
        projectDir: "ALAIN_PAUL_3027",
        customer: "ALAIN PAUL",
        name: "SS 2024",
        production:"EXPERIENTIAL/H",
        director:["MARCEL WEPPER", "https://www.marcelwepper.com/"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("ALAIN_PAUL_3027", "mosaic_AP_v06_WIP04.00_00_38_13.Still006.jpg"),
        video: getMediaPath("ALAIN_PAUL_3027", "AP_v06_WIP04.mp4"),
        XSvideo : getMediaPath("ALAIN_PAUL_3027", "AP_v06_WIP_01_9x16.mp4"),
        year: "2024",
        description: {
            en: "ALAIN PAUL takes a conceptual approach to choreographing clothing around the body. Linked to contemporary ballet, this art form is a powerful muse for the designer. His vision goes beyond simple movement; it's an expression of posture, rigor, artistic freedom and gender non-conformity.",
            fr: "ALAIN PAUL repose sur une approche conceptuelle visant à chorégraphier le vêtement autour du corps. Lié au ballet contemporain, cet art est une muse puissante pour le designer. Sa vision dépasse celle du simple mouvement; c'est une expression de posture, de rigueur, de liberté artistique et de non-conformité de genre."
        },
        credits: [
            ["POST-PRODUCER(F)", "Aurélie Lobin"],
            ["EDITOR", "JP Mure"],
            ["GRADING", "Sylvain Canaux"],
            ["MUSIC", "Senjen Jansen"],
          ],
        visuals: [
            getMediaPath("ALAIN_PAUL_3027/VISUALS", "Alain_Paul_02.mp4"),
            getMediaPath("ALAIN_PAUL_3027/VISUALS", "Alain_Paul_03.mp4"),
            getMediaPath("ALAIN_PAUL_3027/VISUALS", "Alain_Paul_01.mp4")]
    },
    {
      id: 2,
      projectDir: "2808_ANTIDOTE_X_NIKE",
      vimeoId: 660664143,
      customer: "NIKE",
      name: "THE VAPORMAX 2021",
      director: ["YANN WEBER"],
      tags: ["Art&Fashion"],
      mosaicImg: getMediaPath("2808_ANTIDOTE_X_NIKE", "mosaic.jpg"),
      video: getMediaPath("2808_ANTIDOTE_X_NIKE", "NikeAntidote_v09_WIP08_16_9_1.mp4"),
      XSvideo: getMediaPath("2808_ANTIDOTE_X_NIKE", "NikeAntidote_v09_WIP08_16_9_1.mp4"),
      year: "2021",
      description: {
          en: "",
          fr: ""
      },
      credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "Etienne Penin"],
          ["FLAME ARTISTS", "Audrey Alcover, François Gilguy"],
          ["GRADING", "Sylvain Canaux"],
        ],
      visuals: [
        getMediaPath("2808_ANTIDOTE_X_NIKE/Visuals", "NikeAntidote_v09_WIP08_VI.00_00_09_23.Still003.jpg"),
        getMediaPath("2808_ANTIDOTE_X_NIKE/Visuals", "Capture d’écran 2024-11-13 à 13.43.52.jpg"),
        getMediaPath("2808_ANTIDOTE_X_NIKE/Visuals", "Capture d’écran 2024-12-17 à 14.35.29.jpg"),
      ],
    },
    {
        id: 3,
        projectDir: "BULGARI_CADENCE_2891",
        vimeoId: 1022553264,
        customer: "Bvlgari",
        name: "I BELIEVE IN WONDER",
        production:"CADENCE",
        director:["BARNABY ROPER"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("BULGARI_CADENCE_2891", "mosaic_60s_BULGARI_Vuk_logo_HD4444.00_00_47_04.Still007.jpg"),
        video: getMediaPath("BULGARI_CADENCE_2891", "60s_BULGARI_Vuk_logo.mp4"),
        XSvideo : getMediaPath("BULGARI_CADENCE_2891", "60s_BULGARI_9x16.mp4"),
        year: "2023",
        description: {
            en: "Wonder is uniquely precious — an endless fountain from which our greatest hopes spring. Look at the world with fresh eyes this holiday season, because you never know what you might find...",
            fr: "L'émerveillement est un bien précieux, une source intarissable d'où jaillissent nos plus grands espoirs. Regardez le monde d'un œil neuf en cette période de fêtes, car vous ne savez jamais ce que vous pourriez y trouver..."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Gayrade"],
          ["EDITOR", "JP Mure"],
          ["FLAME ARTISTS", "Matteo Destrade, Anita Lech"],
          ["VFX", "Yves Bosson, Olivier Delequeuche, Max Pasquiou"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
            getMediaPath("BULGARI_CADENCE_2891/VISUALS", "60s_BULGARI_Vuk_logo_04.mp4"),
            getMediaPath("BULGARI_CADENCE_2891/VISUALS", "60s_BULGARI_Vuk_logo_05.mp4"),
            getMediaPath("BULGARI_CADENCE_2891/VISUALS", "60s_BULGARI_Vuk_logo_02.mp4"),
            getMediaPath("BULGARI_CADENCE_2891/VISUALS", "60s_BULGARI_Vuk_logo_03.mp4"),
            getMediaPath("BULGARI_CADENCE_2891/VISUALS", "60s_BULGARI_Vuk_logo_01.mp4"),
            getMediaPath("BULGARI_CADENCE_2891/VISUALS", "60s_BULGARI_Vuk_logo_06.mp4")
        ]
    },
    {
        id: 4,
        projectDir: "CARTIER_TIME_PROJECT_2882",
        vimeoId: 1022565969,
        customer: "CARTIER",
        name: "TIME PROJECT",
        production:"PSYCHO",
        director:["SOLVE SUNDSBO"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("CARTIER_TIME_PROJECT_2882", "mosaic_00_120s-TimeProject_1920x1080_VI.00_00_04_03.Still001.jpg"),
        video: getMediaPath("CARTIER_TIME_PROJECT_2882", "00_120s-TimeProject_1920x1080_VF.mp4"),
        XSvideo : getMediaPath("CARTIER_TIME_PROJECT_2882", "00_60s-TimeProject_1080x1920_VF.mp4"),
        year: "2023",
        description: {
            en: "Discover the entire Time project, made up of 12 short films starring Jake Gyllenhaal, directed by Sølve Sundsbø and featuring music by Warren Ellis. ",
            fr: "Découvrez l'intégralité du projet Time, composé de 12 courts-métrages avec Jake Gyllenhaal, réalisé par Sølve Sundsbø et accompagné d'une musique de Warren Ellis. "
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["FLAME ARTISTS", "François Gilguy, Marc Latil"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
            getMediaPath("CARTIER_TIME_PROJECT_2882/VISUALS", "00_120s-TimeProject_1920x1080_VF_01.mp4"),
            getMediaPath("CARTIER_TIME_PROJECT_2882/VISUALS", "00_120s-TimeProject_1920x1080_VF_03.mp4"),
            getMediaPath("CARTIER_TIME_PROJECT_2882/VISUALS", "00_120s-TimeProject_1920x1080_VF_02.mp4")
        ]
    },
    {
      id: 5,
      vimeoId: 1022572381,
      projectDir: "2999_CHANEL_CREME_MAIN_23",
      customer: "CHANEL",
      name: "Crème main",
      production:"JN PRODUCTION",
      director:["TIM ALKAIM","https://www.instagram.com/timelkaim/?hl=fr"],
      tags: ["Beauty"],
      mosaicImg: getMediaPath("2999_CHANEL_CREME_MAIN_23", "mosaic20230915_CHANEL_CREME_MAIN_23_16-9_Exported.00_00_02_02.Still002.jpg"),
      video: getMediaPath("2999_CHANEL_CREME_MAIN_23", "LACREMEMAIN_2024_16x9_15S_VA_20231103.mp4"),
      XSvideo: getMediaPath("2999_CHANEL_CREME_MAIN_23/9_16", "LACREMEMAIN_2024_9x16_15S_VA_20231103.mp4"),
          year: "2023",
      description: {
          en: "CHANEL hand cream by Tim Alkaim with Kim Schell",
          fr: "Crème main CHANEL par Tim Alkaim avec Kim Shell"
      },
      credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["EDITOR", "Paul Lamy"],
          ["FLAME ARTIST", "Marc Latil"],
          ["GRADING", "Sylvain Canaux"],
      ],
      visuals: [
        getMediaPath("2999_CHANEL_CREME_MAIN_23/VISUALS", "Capture d’écran 2024-12-17 à 14.55.38.jpg"),
        getMediaPath("2999_CHANEL_CREME_MAIN_23/VISUALS", "Capture d’écran 2024-12-17 à 14.56.04.jpg"),
        getMediaPath("2999_CHANEL_CREME_MAIN_23/VISUALS", "Capture d’écran 2024-12-17 à 14.56.13.jpg"),
        getMediaPath("2999_CHANEL_CREME_MAIN_23/VISUALS", "Capture d’écran 2024-12-17 à 14.54.55.jpg"),
        getMediaPath("2999_CHANEL_CREME_MAIN_23/VISUALS", "Capture d’écran 2024-12-17 à 14.58.08.jpg"),
      ],
    },  
    {
        id: 6,
        vimeoId: 1022574640,
        projectDir: "CHANEL_FALL_2944",
        customer: "CHANEL",
        name: "Make up collection - Fall 23",
        production: "NEW LIGHT FILMS",
        director: ["SOLVE SUNDSBO","https://www.solvesundsbo.com/"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath("CHANEL_FALL_2944", "mosaic_230523_Chanel_Fall_15s_WW_v07B_Croker_230207_WIP08.00_00_10_05.Still002.jpg"),
        video: getMediaPath("CHANEL_FALL_2944", "230516_FALL_ITW_R1_Q1_13s_16_9.mp4"),
        XSvideo : getMediaPath("CHANEL_FALL_2944", "230516_FALL_ITW_R1_Q1_13s_9_16.mp4"),
        year: "2023",
        description: {
            en: "Kristen Stewart embodies the new Chanel Fall 2023 makeup collection ",
            fr: "Kristen Stewart incarne la nouvelle collection de maquillage Chanel Automne 2023 "
        },
        credits: [
            ["POST-PRODUCER(F)", "Aurélie Lobin"],
            ["EDITOR", "JP Mure"],
            ["FLAME ARTISTS", "Virginie Rigoussen, Marc Latil, Anita Lech"],
            ["GRADING", " Sylvain Canaux"],
        ],
        visuals: [
            getMediaPath("CHANEL_FALL_2944/VISUALS", "230524_FALL_ITW_R1_Q3_PART2_15s.mp4"),
            getMediaPath("CHANEL_FALL_2944/VISUALS", "230516_FALL_ITW_R1_Q2_13s_16_9.mp4")
        ]
    },
    {
        id: 7,
        vimeoId: 1022580071,
        projectDir: "2992_CHANEL_LE_BLANC_24",
        customer: "CHANEL",
        name: "Spring collection by Valentina Li",
        production: "PSYCHO",
        director: ["SOLVE SUNDSBO"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath("2992_CHANEL_LE_BLANC_24", "mosaic_Capture d’écran 2024-11-12 à 18.03.29.jpg"),
        video: getMediaPath("2992_CHANEL_LE_BLANC_24", "231124_Brightening24_15s_noHand_v01c_Speed_BLANC_16x9_WIP09_VA.mp4"),
        XSvideo: getMediaPath("2992_CHANEL_LE_BLANC_24/9_16", "231124_Brightening24_15s_noHand_v01c_Speed_BLANC_9x16_WIP09_VA.mp4"),
        year: "2024",
        description: {
            en: "For the first time, the CHANEL SPRING 2024 COLLECTION has been designed by makeup artist and COMETES COLLECTIVE member Valentina Li. Inspired by the depths of the sea and Gabrielle Chanel's affinity for the ocean, this odyssey brings together luminous iridescent shimmer and subtle aquatic hues, powerfully worn by Vittoria Ceretti. It invites you to create a shimmering, iridescent makeup look illuminated by touches of azure blue.",
            fr: "Pour la première fois, la COLLECTION CHANEL PRINTEMPS 2024 a été conçue par la maquilleuse Valentina Li, membre de COMETES COLLECTIVE. Inspirée des profondeurs marines et de l'affinité de Gabrielle Chanel pour l'océan, cette odyssée réunit des irisations lumineuses et des teintes aquatiques subtiles, ici portées par Vittoria Ceretti. Elle invite à créer un maquillage chatoyant et irisé, illuminé par des touches de bleu azur."
        },
        credits: [
            ["POST-PRODUCER", "Aurélie Lobin"],
            ["EDITOR", "JP MURE"],
            ["FLAME ARTISTS", "Marc Latil, Virginie Rigoussen"],
            ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2992_CHANEL_LE_BLANC_24/VISUALS", "231017_Brightening24_15s_noHand_v01c_Speed_BLANC_16x9_WIP07_VI.00_00_05_10.Still0020.jpg"),
          getMediaPath("2992_CHANEL_LE_BLANC_24/VISUALS", "Capture d’écran 2024-12-17 à 15.21.06.jpg"),
          getMediaPath("2992_CHANEL_LE_BLANC_24/VISUALS", "Capture d’écran 2024-12-17 à 15.21.31.jpg"),
        ],
      },
    {
        id: 8,
        vimeoId: 1022585787,
        projectDir: "CHANEL_N1_2H23_2980",
        customer: "CHANEL",
        name: "N1 2H24 Efficacité générique",
        production: "PSYCHO",
        director: ["SOLVE SUNDSBO"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath("CHANEL_N1_2H23_2980", "mosaic_FILM N1 EFFICACITE GENERIQUE 16-9_VI.00_00_21_24.Still005.jpg"),
        video: getMediaPath("CHANEL_N1_2H23_2980", "_FILM N1 EFFICACITE GENERIQUE 16-9.mp4"),
        XSvideo : null,
        year: "2023",
        description: {
            en: "",
            fr: ""
        },
        credits: [
            ["POST-PRODUCER(F)", " Aurélie Lobin"],
            ["EDITOR", "JP Mure"],
            ["FLAME ARTISTS", "Marc Latil, Anita Lech"],
            ["VFX / AFTER EFFECTS", "Hervé Champeaux"],
            ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
            getMediaPath("CHANEL_N1_2H23_2980/VISUALS", "_FILM N1 EFFICACITE GENERIQUE 16-9_02.mp4"),
            getMediaPath("CHANEL_N1_2H23_2980/VISUALS", "_FILM N1 EFFICACITE GENERIQUE 16-9_03.mp4"),
            getMediaPath("CHANEL_N1_2H23_2980/VISUALS", "_FILM N1 EFFICACITE GENERIQUE 16-9_01.mp4")
        ],
    },
    {
        id: 9,
        vimeoId: 1022804412,
        projectDir: "3000_CHIVAS",
        customer: "CHIVAS",
        name: "Chivas X Dolly",
        director: ["THEODOR GUELAT","https://www.instagram.com/theodorguelat/?hl=fr"],
        production: "CHAOS",
        agency: "McCann",
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("3000_CHIVAS", "Mosaic_Chivas_30s_16x9_231002_VI.00_00_29_21.Still0010.jpg"),
        video: getMediaPath("3000_CHIVAS", "Chivas_30s_16x9_231002.mp4"),
        XSvideo: getMediaPath("3000_CHIVAS/9_16", "Chivas_30s_9x16_231002_VI.mp4"),
            year: "2024",
        description: {
            en: "Chivas Regal entrusts the design of its bottle to artist Dolly Cohen: 300 copies, inspired by the Chivas blend, Dolly Cohen has designed and crafted a unique piece using the same process as her jewellery creations. She shaped and sculpted the organic forms by hand.",
            fr: "Chivas Regal confie le design de sa bouteille à l’artiste Dolly Cohen : 300 exemplaires, inspirés du blend Chivas, Dolly Cohen a dessiné et travaillé une pièce unique selon le même procédé que ses créations joaillières. Elle a modelé et sculpté à la main les formes organiques."
        },
        credits: [
          ["POST-PRODUCER", "Clément Du Passage"],
          ["EDITOR", "JP Mure"],
          ["CGI ARTIST", "Olivier Delequeuche "],
          ["VFX SUPERVISOR", " Yves Bosson"],
          ["GRADING", " Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("3000_CHIVAS/VISUALS", "Capture d’écran 2024-11-12 à 18.08.45.jpg"),
          getMediaPath("3000_CHIVAS/VISUALS", "Capture d’écran 2024-12-17 à 13.55.36.jpg"),
          getMediaPath("3000_CHIVAS/VISUALS", "Capture d’écran 2024-12-17 à 13.56.43.jpg"),
          getMediaPath("3000_CHIVAS/VISUALS", "Capture d’écran 2024-12-17 à 13.58.17.jpg"),
          getMediaPath("3000_CHIVAS/VISUALS", "Capture d’écran 2024-12-17 à 13.59.26.jpg"),
        ],
    },
    {
        id: 10,
        vimeoId: 957125293,
        projectDir: "DIATOMEE_3047",
        customer: "DIATOMÉE WATER ADVENTURE",
        name: "AQUABALLET",
        production:"",
        director:["AXEL VOYER"],
        tags: ["Clips"],
        mosaicImg: getMediaPath("DIATOMEE_3047", "mosaic_diatomee.jpg"),
        alternativeImg: getMediaPath("DIATOMEE_3047", "alternativeMosaic.jpg"),
        video: getMediaPath("DIATOMEE_3047", "Ballet_Aquatique_v10_CONFO_TO_GRADE 3.mp4"),
        XSvideo : getMediaPath("", "Moncler_30s_v3_1080x1920_VF.mp4"),
        year: "2024",
        description: {
            en: "Underwater animal ballet with the talented dancer Marianne Aventurier to music by Stéphane Lopez.",
            fr: "Le ballet d'animaux sous marins avec la talentueuse danseuse Marianne Aventurier sur la musique de Stéphane Lopez."
        },
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "JP Mure"],
          ["FLAME ARTIST", "François Gilguy"],
          ["GRADING", " Sylvain Canaux"],
        ],
        visuals: [
            getMediaPath("DIATOMEE_3047/VISUALS", "Ballet_Aquatique_v10_CONFO_TO_GRADE 3_04.mp4"),
            getMediaPath("DIATOMEE_3047/VISUALS", "Ballet_Aquatique_v10_CONFO_TO_GRADE 3_02.mp4"),
            getMediaPath("DIATOMEE_3047/VISUALS", "Ballet_Aquatique_v10_CONFO_TO_GRADE 3_03.mp4"),
            getMediaPath("DIATOMEE_3047/VISUALS", "Ballet_Aquatique_v10_CONFO_TO_GRADE 3_01.mp4"),
            getMediaPath("DIATOMEE_3047/VISUALS", "Ballet_Aquatique_v10_CONFO_TO_GRADE 3_05.mp4")]    },
    {
        id: 11,
        vimeoId: 1022807511,
        projectDir: "DIOR_CNY_2022_2904",
        customer: "DIOR",
        name: "Nouvel An Lunaire 2023",
        production:"NEWLIGHT FILMS",
        director:["Benn Northover","https://www.instagram.com/bennnorthoverdiary/"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath("DIOR_CNY_2022_2904", "mosaic_Dior_CNY_20s_MASTER_VI.00_00_14_04.Still002.jpg"),
        video: getMediaPath("DIOR_CNY_2022_2904", "Dior_CNY_20s_MASTER_VA.mp4"),
        XSvideo : getMediaPath("", "Moncler_30s_v3_1080x1920_VF.mp4"),
        year: "2023",
        description: {
            en: "",
            fr: ""
        },
        credits: [
            ["POST-PRODUCER", "Clément Du Passage"],
            ["EDITOR", "JP Mure"],
            ["FLAME ARTISTS", "François Gilguy"],
            ["VFX / AFTER EFFECTS", "Serge Miot"],
            ["GRADING", " Sylvain Canaux"],  
        ],
        visuals: [
            getMediaPath("DIOR_CNY_2022_2904/VISUALS", "Dior_CNY_20s_MASTER_VA_03.mp4"),
            getMediaPath("DIOR_CNY_2022_2904/VISUALS", "Dior_CNY_20s_MASTER_VA_01.mp4"),
            getMediaPath("DIOR_CNY_2022_2904/VISUALS", "Dior_CNY_20s_MASTER_VA_02.mp4")
        ]
    },
    {
        id: 12,
        vimeoId : 800797370,
        projectDir: "DIOR_NOEL_2835",
        customer: "DIOR",   
        name: "NOËL",
        director: ["Elina Kechicheva"],
        production: "BRACHFELD",
        tags: ["Luxe"],
        mosaicImg: getMediaPath("DIOR_NOEL_2835", "mosaic_DIOR_NOEL_VLONGUE_EDIT8alt_211202_nologo.00_01_13_06.Still005.jpg"),
        video: getMediaPath("DIOR_NOEL_2835", "DIOR_NOEL_VLONGUE_EDIT8alt_211201_fin.mp4"),
        XSvideo : getMediaPath("", "Moncler_30s_v3_1080x1920_VF.mp4"),
        year: "2021",
        description: {
            en: "Elina Kechicheva's atmospheric images capture the enchantment of the festive season in Greek-inspired representations of Maria Grazia Chiuri's DiorCruise 2022 collection.",
            fr: "Les images atmosphériques d'Elina Kechicheva capturent l'enchantement des fêtes de fin d'année dans des représentations de l'inspiration grecque de la collection Dior Cruise 2022 de Maria Grazia Chiuri."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc raygade"],
          ["EDITOR", "Alexa Marie-Jeanne"],
          ["FLAME ARTISTS", ""],
          ["VFX ARTISTS", "Yves Bosson, Johann Roche"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("DIOR_NOEL_2835/VISUALS", "DIOR_NOEL_VLONGUE_EDIT8alt_211201_03.mp4"),
          getMediaPath("DIOR_NOEL_2835/VISUALS", "DIOR_NOEL_VLONGUE_EDIT8alt_211201_02.mp4"),
          getMediaPath("DIOR_NOEL_2835/VISUALS", "DIOR_NOEL_VLONGUE_EDIT8alt_211201_01.mp4")
        ]
    },
    {
        id: 13,
        vimeoId: 1022809366,
        projectDir: "DUNE_2962",
        customer: "DUNE",
        name: "Think About You",
        director: ["JASON YAN FRANCIS"],
        production: "CHAOS",
        tags: ["Clips"], 
        mosaicImg: getMediaPath("DUNE_2962", "mosaic_230629_DUNE_ThinkAboutYou_prores444.00_01_14_19.Still0010.jpg"),
        video: getMediaPath("DUNE_2962", "230629_DUNE_ThinkAboutYou_h264.mp4"),
        XSvideo : getMediaPath("", "Moncler_30s_v3_1080x1920_VF.mp4"),
        year: "2023",
        description: {
            en: "Dune is a German techno, rave and happy hardcore band from Munich. Formed in the early 1990s. This track is taken from their album Better Never Than Late.",
            fr: "Dune est un groupe de techno, rave et happy hardcore allemand originaire de Munich, formé au début des années 1990. Ce morceau est tiré de leur album : Better Never Than Late."
        },
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["VFX", "Seb Filinger, Joahnn Roche, Rémi Devouassoud"],
        ],
        visuals: [
          getMediaPath("DUNE_2962/VISUALS", "230629_DUNE_ThinkAboutYou_h264_ 01.mp4"),
          getMediaPath("DUNE_2962/VISUALS", "230629_DUNE_ThinkAboutYou_h264_02.mp4"),
          getMediaPath("DUNE_2962/VISUALS", "230629_DUNE_ThinkAboutYou_h264_03.mp4")
        ]
    },
    {
        id: 14,
        vimeoId: 1022810324,
        projectDir: "FENDI_2761",
        customer: "FENDI",
        name: "HAUTE COUTURE Spring/Summer 21",
        production:"",
        director:["MARCEL WEPPER","https://www.marcelwepper.com/"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("FENDI_2761", "mosaic_FENDI_30s_1920x1080_no_Subtitles_proresHQ.00_00_08_00.Still0010.jpg"),
        video: getMediaPath("FENDI_2761", "FENDI_30s_1920x1080_VF.mp4"),
        XSvideo : getMediaPath("FENDI_2761", "FENDI_30s_1080x1920_VF.mp4"),
        year: "2021",
        description: {
            en: "A film by Marcel Wepper for the famous FENDI fashion house, directed by Kim Jones. The film is set in the Bloomsbury world of Virginia Woolf and her seminal novel Orlando. The film script is a collection of selected lines from the correspondence between Woolf and Vita Sackville-West. Music Alkis Livathinos",
            fr: "La célèbre maison de couture FENDI par Kim Jones's maiden. Dans l'univers de Bloomsbury de Virginia Woolf et de son roman phare Orlando. Le scénario du film est une collection de lignes sélectionnées dans la correspondance entre Woolf et Vita Sackville-West. Musique Alkis Livathinos"
        },
        credits: [
            ["POST-PRODUCER(F)", "Aurélie Lobin"],
            ["EDITOR", "JP Mure"],
            ["FLAME ARTIST","François Gilguy "],
            ["GRADING", " Sylvain Canaux"],
        ],
        visuals: [
            getMediaPath("FENDI_2761/VISUALS", "FENDI_47s_1920x1080_VF_04.mp4"),
            getMediaPath("FENDI_2761/VISUALS", "FENDI_47s_1920x1080_VF_01.mp4"),
            getMediaPath("FENDI_2761/VISUALS", "FENDI_47s_1920x1080_VF_03.mp4"),
            getMediaPath("FENDI_2761/VISUALS", "FENDI_47s_1920x1080_VF_02.mp4"),
            getMediaPath("FENDI_2761/VISUALS", "FENDI_47s_1920x1080_VF_05.mp4")]
    },
    {
        id: 15,
        vimeoId: 1022816432,
        projectDir: "FORTE_FORTE_2842",
        customer: "FORTE FORTE",
        name: "LOVE SURFERS",
        production:"",
        director:["Léo Haddad"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("FORTE_FORTE_2842", "mosaic_ForteForte_16X9_NO_TEXT.00_00_40_18.Still012.jpg"),
        video: getMediaPath("FORTE_FORTE_2842", "ForteForte_16X9.mp4"),
        XSvideo : getMediaPath("FORTE_FORTE_2842", "ForteForte_9X16.mp4"),
        year: "2022",
        description: {
            en: "« love surfers » is a place where everything shines: a sparkling, bright summer, marked by a cheerful, sunny holiday rhythm. We remember cool and fresh summer evenings on the beach, admiring enchanting, beautiful mermaids illuminated by the sun...",
            fr: "« love surfers » est un lieu où tout brille : un été étincelant et lumineux, marqué par un rythme de vacances joyeux et ensoleillé. Nous nous souvenons des soirées d'été fraîches sur la plage, en admirant les belles sirènes enchanteresses illuminées par le soleil..."
        },
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "Léo Haddad"],
          ["GRADING", "Sylvain Canaux"],     
        ],
        visuals: [getMediaPath("FORTE_FORTE_2842", "ForteForte_16X9_NO_TEXT.00_00_06_20.Still002.jpg"), getMediaPath("FORTE_FORTE_2842", "ForteForte_16X9_NO_TEXT.00_00_58_09.Still009.jpg"), getMediaPath("FORTE_FORTE_2842", "ForteForte_16X9_NO_TEXT.00_00_56_09.Still010.jpg"), getMediaPath("FORTE_FORTE_2842", "ForteForte_16X9_NO_TEXT.00_00_19_21.Still003.jpg"), getMediaPath("FORTE_FORTE_2842", "ForteForte_16X9_NO_TEXT.00_00_54_14.Still007.jpg"), getMediaPath("FORTE_FORTE_2842", "ForteForte_16X9_NO_TEXT.00_00_00_00.Still001.jpg"), getMediaPath("FORTE_FORTE_2842", "ForteForte_16X9_NO_TEXT.00_00_06_20.Still002.jpg"), getMediaPath("FORTE_FORTE_2842", "ForteForte_16X9_NO_TEXT.00_00_22_01.Still004.jpg"), getMediaPath("FORTE_FORTE_2842", "ForteForte_16X9_NO_TEXT.00_00_35_02.Still006.jpg"), getMediaPath("FORTE_FORTE_2842", "ForteForte_16X9_NO_TEXT.00_00_30_13.Still005.jpg"), getMediaPath("FORTE_FORTE_2842", "ForteForte_16X9_NO_TEXT.00_01_00_23.Still008.jpg"), getMediaPath("FORTE_FORTE_2842", "ForteForte_16X9_NO_TEXT.00_00_52_21.Still011.jpg")],  
    },
    {
        id: 16,
        vimeoId: 1022816929,
        projectDir: "GABRIELLE_ESSENCE_24_3029",
        customer: "CHANEL",
        name: "Gabrielle Essence",
        production: "PSYCHO",
        director: ["SOLVE SUNDSBO"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath("GABRIELLE_ESSENCE_24_3029", "mosaic_240219_CHANEL_GABRIELLE_ESSENCE_30s_16x9_WIP09_00_00_23_13_Still001.jpg"),
        video: getMediaPath("GABRIELLE_ESSENCE_24_3029", "240219_CHANEL_GABRIELLE_ESSENCE_30s_16x9_WIP09.mp4"),
        XSvideo : getMediaPath("GABRIELLE_ESSENCE_24_3029", "240219_CHANEL_GABRIELLE_ESSENCE_30s_9x16.mp4"),
        year: "2024",
        description: {
            en: "GABRIELLE CHANEL and its more voluptuous interpretation, GABRIELLE CHANEL ESSENCE, are the fragrances of a determined, radiant woman. A floral, solar trail, with Rianne Van Rompaey",
            fr: "GABRIELLE CHANEL et son interprétation plus voluptueuse, GABRIELLE CHANEL ESSENCE, sont les fragrances d’une femme déterminée et rayonnante. Un sillage floral et solaire, avec Rianne Van Rompaey."
        },
        credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["EDITOR", "Walter Mauriot"],
          ["FLAME ARTIST","Marc Latil"],
          ["VFX SUPERVISOR","Yves Bosson"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
            getMediaPath("GABRIELLE_ESSENCE_24_3029/VISUALS", "240219_CHANEL_GABRIELLE_ESSENCE_30s_16x9_WIP09 Copy 01.mp4" ),
            getMediaPath("GABRIELLE_ESSENCE_24_3029/VISUALS", "240219_CHANEL_GABRIELLE_ESSENCE_30s_16x9_WIP09.00_00_07_12.Still003.jpg"),
            getMediaPath("GABRIELLE_ESSENCE_24_3029/VISUALS","240219_CHANEL_GABRIELLE_ESSENCE_30s_16x9_WIP09.mp4"),
        ]
    },
    {
        id: 17,
        vimeoId: 1022817381,
        projectDir: "GRAIN_DE_CAFE_CARTIER_2960",
        customer: "CARTIER",
        name: "GRAIN DE CAFÉ",
        production:"PSYCHO",
        director:["ALEX PRAGER"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("GRAIN_DE_CAFE_CARTIER_2960", "mosaic_Capture d’écran 2024-11-12 à 18.12.24.jpg"),
        video: getMediaPath("GRAIN_DE_CAFE_CARTIER_2960", "230525_CARTIER_GdC_45s_4k_cine_grain_25fps_VA.mp4"),
        XSvideo : getMediaPath("GRAIN_DE_CAFE_CARTIER_2960", "240219_CHANEL_GABRIELLE_ESSENCE_30s_9x16.mp4"),
        year: "2023",
        description: {
            fr: "Elle Fanning, ambassadrice de la Maison et égérie de la collection Grain de Café, incarne le glamour de la Côte d'Azur des années 1950 et la beauté radieuse de Grace Kelly.",
            en: "Elle Fanning, Ambassador of the Maison and face of the Grain de Café jewelry collection, channels the glamour of the 1950s Côte d'Azur and the radiant beauty of Grace Kelly. "
        },
        credits: [
            ["POST-PRODUCER", "Jean-Marc Raygade"],
            ["AFTER EFFECTS",  "Seb Filinger, Supralude"],
            ["EDITOR", "Stephane Perrera"],
            ["FLAME ARTISTS", "François Gilguy"],
            ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
            getMediaPath("GRAIN_DE_CAFE_CARTIER_2960/VISUALS", "230525_CARTIER_GdC_45s_4k_cine_grain_25fps_VA_01.mp4"),
            getMediaPath("GRAIN_DE_CAFE_CARTIER_2960/VISUALS", "230525_CARTIER_GdC_45s_4k_cine_grain_25fps_VA_02.mp4"),
            getMediaPath("GRAIN_DE_CAFE_CARTIER_2960/VISUALS", "230525_CARTIER_GdC_45s_4k_cine_grain_25fps_VA_03.mp4"),
        ]
    },
    {
        id: 18,
        vimeoId: 1022817852,
        projectDir: "HELMUT_NEWTON_3039",
        customer: "MOP Foundation",
        name: "Helmut Newton Fact & Fiction",
        production:"Helmut Newton Foundation",
        director:["MARCEL WEPPER", "https://www.marcelwepper.com/"],
        tags: ["Clips"],
        mosaicImg: getMediaPath("HELMUT_NEWTON_3039", "mosaic_240226_HelmutNewton_Exhibition_VI_16-9_proreshq.00_02_53_14.Still0060.jpg"),
        video: getMediaPath("HELMUT_NEWTON_3039", "240226_HelmutNewton_Exhibition_VA_16-9_h264.mp4"),
        XSvideo : getMediaPath("HELMUT_NEWTON_3039", "240403_HelmutNewton_Exhibition_VA_9-16_H264.mp4"),
        year: "2024",
        description: {
            en: "A short documentary for the MOP Foundation's 2023/24 retrospective. Music : Alkis Livathinos",
            fr: "Un court documentaire à l'occasion de la rétrospective 2023/24 de la Fondation MOP. Musique d'Alkis Livathinos."
        },
        credits: [
            ["POST-PRODUCER(F)", "Aurélie Lobin"],
            ["EDITOR", "JP Mure"],
            ["AFTER EFFECTS", "Seb Filinger"],
            ["GRADING", " Sylvain Canaux"],
        ],
        visuals: [
            getMediaPath("HELMUT_NEWTON_3039", "240226_HelmutNewton_Exhibition_VI_16-9_proreshq.00_00_47_17.Still0010.jpg"),
            getMediaPath("HELMUT_NEWTON_3039", "mosaic_240226_HelmutNewton_Exhibition_VI_16-9_proreshq.00_02_53_14.Still0060.jpg"),
            getMediaPath("HELMUT_NEWTON_3039", "240226_HelmutNewton_Exhibition_VI_16-9_proreshq.00_03_07_17.Still0040.jpg"),
            getMediaPath("HELMUT_NEWTON_3039", "240226_HelmutNewton_Exhibition_VI_16-9_proreshq.00_01_39_23.Still0030.jpg"),
            getMediaPath("HELMUT_NEWTON_3039", "240226_HelmutNewton_Exhibition_VA_16-9_proreshq.00_00_02_15.Still0010.jpg"),
            getMediaPath("HELMUT_NEWTON_3039", "240226_HelmutNewton_Exhibition_VI_16-9_proreshq.00_02_38_22.Still0050.jpg"),
            getMediaPath("HELMUT_NEWTON_3039", "240226_HelmutNewton_Exhibition_VI_16-9_proreshq.00_02_15_05.Still0020.jpg"),
        ],
    },
    {
        id: 19,
        master: 2982,
        vimeoId: 1022818217,
        projectDir: "HERMES_BEAUTY_ROUGE_2982",
        customer: "HERMÈS",
        name: " Le Rouge",
        production: "U-TURN",
        director: ["JACK DAVISON","https://www.jackdavison.co.uk/"],
        agency: "PUBLICIS",
        tags: ["Beauty"],
        mosaicImg: getMediaPath("HERMES_BEAUTY_ROUGE_2982", "mosaic_2982.jpg"),
        video: getMediaPath("HERMES_BEAUTY_ROUGE_2982", "Hermès_RAL_H23_15s_v03_230509_FR_MIX_WEB_H264_VA.mp4"),
        XSvideo : getMediaPath("HERMES_BEAUTY_ROUGE_2982", "Herm__s_H23_15s_v03_230509_WIP03_9x16_FR_MIX_WEB_H264_VA.mp4"),
        year: "2024",
        description: {
            en: "",
            fr: ""
        },
        credits: [
            ["POST-PRODUCER", "Daniel Rota"],
            ["EDITOR", "JP Mure"],
            ["FLAME ARTISTS", "François Gilguy"],
            ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
            getMediaPath("HERMES_BEAUTY_ROUGE_2982/VISUALS", "Hermès_RAL_H23_15s_v03_230509_FR_MIX_WEB_H264_VA.00_00_05_10.Still001.jpg"), 
            getMediaPath("HERMES_BEAUTY_ROUGE_2982/VISUALS", "Hermès_RAL_H23_15s_v03_230509_FR_MIX_WEB_MASTER.00_00_04_12.Still001.jpg"), 
            getMediaPath("HERMES_BEAUTY_ROUGE_2982/VISUALS", "Hermès_RAL_H23_15s_v03_230509_FR_MIX_WEB_MASTER.00_00_09_10.Still002.jpg")
          ]
    },
    {
        id: 20,
        vimeoId: 1022818472,
        projectDir: "HERMES_H24_2986",
        customer: "Hermès",
        name: "Herbes Vives",
        production: "U-TURN",
        director: ["CHRISTOPHER ANDERSON","https://www.christopherandersonphoto.com/"],
        agency: "Publicis et Nous",
        tags: ["Beauty"],
        mosaicImg: getMediaPath("HERMES_H24_2986", "mosaic_230628_Hermes_H24_Herbes_Vives_20s_v05_WIP03_FR_16x9_MASTER.00_00_02_21.Still002.jpg"),
        video: getMediaPath("HERMES_H24_2986", "230628_Hermes_H24_Herbes_Vives_20s_v05_WIP03_FR_16x9_H264_LOGO.mp4"),
        XSvideo : getMediaPath("HERMES_H24_2986", "230628_Hermes_H24_Herbes_Vives_20s_v05_WIP03_FR_9x16_H264_LOGO.mp4"),
        year: "2024",
        description: {
            en: "Hermès continues its plant-based approach, in the footsteps of a contemporary man on the move",
            fr: "Hermès poursuit son écriture végétale, dans les pas d’un homme contemporain en mouvement"
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["FLAME ARTISTS", "François Gilguy, Anita Lech"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
            getMediaPath("HERMES_H24_2986", "230628_Hermes_H24_Herbes_Vives_20s_v05_WIP03_FR_16x9_MASTER.00_00_00_00.Still001.jpg"),
            getMediaPath("HERMES_H24_2986", "230628_Hermes_H24_Herbes_Vives_20s_v05_WIP03_FR_16x9_MASTER.00_00_09_16.Still005.jpg"),
            getMediaPath("HERMES_H24_2986", "230628_Hermes_H24_Herbes_Vives_20s_v05_WIP03_FR_16x9_MASTER.00_00_02_21.Still006.jpg"),
            getMediaPath("HERMES_H24_2986", "230628_Hermes_H24_Herbes_Vives_20s_v05_WIP03_FR_16x9_MASTER.00_00_00_23.Still004.jpg"),
            getMediaPath("HERMES_H24_2986", "230628_Hermes_H24_Herbes_Vives_20s_v05_WIP03_FR_16x9_MASTER.00_00_01_23.Still003.jpg"),
        ]
    },
    {
        id: 21,
        vimeoId: 710347201,
        projectDir: "HERMES_LA_MONTRE_ARCEAU_2852",
        customer: "Hermès",
        name: "HERMES ARCEAU",
        production: "U-TURN",
        director: ["BARNABY ROPER"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("HERMES_LA_MONTRE_ARCEAU_2852", "mosaic_ALTV_Man_45sec_16-9_VI.00_00_36_22.Still008.jpg"),
        video: getMediaPath("HERMES_LA_MONTRE_ARCEAU_2852", "ALTV_Man_45sec_16-9_VF.mp4"),
        XSvideo : getMediaPath("HERMES_LA_MONTRE_ARCEAU_2852", "ALTV_Man_45sec_9-16_VF.mp4"),
        year: "2023",
        description: {
            en: "One look at the Arceau Le Temps Voyageur tells you it's time to discover new horizons where reality is suspended in time and space.",
            fr: "Un coup d'œil à l'Arceau Le Temps Voyageur vous dit qu'il est temps de découvrir de nouveaux horizons où la réalité est suspendue dans le temps et l'espace."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", " JP Mure"],
          ["FLAME ARTISTS", "François Gilguy, Marc Latil, Vivien Salvagione"],
          ["VFX", "Serge Miot, Olivier Deleqeuche, Edin Hadzic "],
          ["GRADING", "Sylvain Canaux"],
            
        ],
        visuals: [
            getMediaPath("HERMES_LA_MONTRE_ARCEAU_2852", "ALTV_Man_45sec_16-9_VI.00_00_36_22.Still008.jpg"), 
            getMediaPath("HERMES_LA_MONTRE_ARCEAU_2852", "ALTV_Man_45sec_16-9_VI.00_00_07_14.Still002.jpg"),
            getMediaPath("HERMES_LA_MONTRE_ARCEAU_2852", "ALTV_Man_45sec_16-9_VI.00_00_14_00.Still003.jpg"),
            getMediaPath("HERMES_LA_MONTRE_ARCEAU_2852", "ALTV_Man_45sec_16-9_VI.00_00_30_15.Still007.jpg"),
            getMediaPath("HERMES_LA_MONTRE_ARCEAU_2852", "ALTV_Man_45sec_16-9_VI.00_00_04_20.Still001.jpg"),
            getMediaPath("HERMES_LA_MONTRE_ARCEAU_2852", "ALTV_Man_45sec_16-9_VI.00_00_25_03.Still005.jpg"),
            getMediaPath("HERMES_LA_MONTRE_ARCEAU_2852", "ALTV_Man_45sec_16-9_VI.00_00_27_06.Still006.jpg"),
            getMediaPath("HERMES_LA_MONTRE_ARCEAU_2852", "ALTV_Man_45sec_16-9_VI.00_00_35_17.Still009.jpg")
        ],
    },
    {
        id: 22,
        vimeoId: 1022821051,
        projectDir: "LANCOME_GENEFIC_2022_2849",
        customer: "LANCÔME",
        name: "LANCÔME ADVANCED GÉNÉFIQUE",
        production:"U-TURN",
        director:["BARNABY ROPER"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath("LANCOME_GENEFIC_2022_2849", "GNF-2022_TVC_INTER_30_16-9_Prores_VI.00_00_13_12.Still001.jpg"),
        video: getMediaPath("LANCOME_GENEFIC_2022_2849", "GNF-2022_TVC_INTER_30_16-9_H264_VA.mp4"),
        XSvideo : getMediaPath("LANCOME_GENEFIC_2022_2849", "GNF-2022_TVC_INTER_30_9-16_H264_VA.mp4"),
        year: "2023",
        description: {
            en: "Actress Lily Collins is Lancôme muse",
            fr: "L'actrice Lily Collins est l'égérie Lancôme"
        },
        credits: [
            ["POST-PRODUCER","Daniel Rota"],
            ["EDITOR", " JP Mure"],
            ["VFX", "Yves Bosson, Olivier Delequeuche, Max Pasquiou, Edin Hadzic"],
            ["FLAME ARTIST", " Anita Lech, François Gilguy"],
            ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
            getMediaPath("LANCOME_GENEFIC_2022_2849", "GNF-2022_TVC_INTER_30_16-9_Prores_VI.00_00_20_19.Still002.jpg"),
            getMediaPath("LANCOME_GENEFIC_2022_2849", "GNF-2022_TVC_INTER_30_16-9_Prores_VI.00_00_07_11.Still004.jpg"),
            getMediaPath("LANCOME_GENEFIC_2022_2849", "GNF-2022_TVC_INTER_30_16-9_Prores_VI.00_00_13_12.Still001.jpg")
        ]
    },
    {
        id: 23,
        vimeoId: 1022821568,
        projectDir: "LV_ART_DE_VIVRE_2916",
        customer: "LOUIS VUITTON",
        name: "Louis Vuitton - Art de vivre",
        production: "Brachfeld",
        director: ["MATTHIEU SALVAING"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("LV_ART_DE_VIVRE_2916", "mosaic_Capture d’écran 2024-11-12 à 18.34.11.jpg"),
        alternativeImg: getMediaPath("LV_ART_DE_VIVRE_2916", "alternativeMosaic.jpg"),
        video: getMediaPath("LV_ART_DE_VIVRE_2916", "AOL_2022_ADV_HERO_VIDEO_STORES & STELLAR_1920x1080_LOGO & SOUND.mp4"),
        XSvideo : getMediaPath("LV_ART_DE_VIVRE_2916", "AOL_2022_ADV_HERO_VIDEO_STORES & STELLAR_1080x1920_LOGO & SOUND.mp4"),
        year: "2023",
        description: {
            en: "Louis Vuitton invites its Art de Vivre collections to Palm Springs, California.",
            fr: "Louis Vuitton invite ses collections Art de Vivre à Palm Springs en Californie."
        },
        credits: [
            ["POST-PRODUCER(F)", "Aurélie Lobin"],
            ["EDITOR", "JP Mure"],
            ["VFX / AFTER EFFECTS", " Seb Filinger, Serge Miot"],
            ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
            getMediaPath("LV_ART_DE_VIVRE_2916", "AOL_2022_ADV_HERO_VIDEO_STORES & STELLAR_1920x1080_NO LOGO & NO SOUND.00_00_27_14.Still003.jpg"),
            getMediaPath("LV_ART_DE_VIVRE_2916", "AOL_2022_ADV_HERO_VIDEO_STORES & STELLAR_1920x1080_NO LOGO & NO SOUND.00_00_00_00.Still001.jpg"),
            getMediaPath("LV_ART_DE_VIVRE_2916", "AOL_2022_ADV_HERO_VIDEO_STORES & STELLAR_1920x1080_NO LOGO & NO SOUND.00_00_41_23.Still004.jpg"),
            getMediaPath("LV_ART_DE_VIVRE_2916", "AOL_2022_ADV_HERO_VIDEO_STORES & STELLAR_1920x1080_NO LOGO & NO SOUND.00_01_13_21.Still005.jpg"),
            getMediaPath("LV_ART_DE_VIVRE_2916", "AOL_2022_ADV_HERO_VIDEO_STORES & STELLAR_1920x1080_NO LOGO & NO SOUND.00_01_18_23.Still006.jpg"),
            getMediaPath("LV_ART_DE_VIVRE_2916", "AOL_2022_ADV_HERO_VIDEO_STORES & STELLAR_1920x1080_NO LOGO & NO SOUND.00_00_13_10.Still002.jpg"), 
            getMediaPath("LV_ART_DE_VIVRE_2916", "AOL_2022_ADV_HERO_VIDEO_STORES & STELLAR_1920x1080_NO LOGO & NO SOUND.00_00_22_17.Still007.jpg")]
    },
    {
        id: 24,
        vimeoId: 1022823992,
        projectDir: "2969_MAKING_OF_BLEU_DE_CHANEL",
        customer: "CHANEL",
        name: "BLEU DE CHANEL REVEAL",
        production: "",
        director: ["MARIO SORENTI" ," https://www.artpartner.com/mario-sorrenti"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath("2969_MAKING_OF_BLEU_DE_CHANEL", "mosaic.jpg"),
        video: getMediaPath("2969_MAKING_OF_BLEU_DE_CHANEL", "CHANEL_BLEU_1H2023_ITW_FRANCE_USA_16x9.mp4"),
        XSvideo: getMediaPath("2969_MAKING_OF_BLEU_DE_CHANEL", "CHANEL_BLEU_1H2023_ITW_FRANCE_USA_16x9.mp4"),
            year: "2023",
        description: {
            en: "If You Were interview - BLEU DE CHANEL - Thimotée Chalamet embodies the men's fragrance created by Jacques Polge for Chanel in 2010.  An interview conducted with raw, dynamic energy.  He describes “the visionary, avant-garde spirit of the next chapter of the juice: a man who doesn't limit himself to the ordinary boundaries of life, who looks beyond the decrees of the visible”. ",
            fr: "If You Were interview — BLEU DE CHANEL - Timothée Chalamet incarne le parfum pour homme, créé par Jacques Polge pour Chanel en 2010.  Une interview menée avec une énergie dynamique et brute.  « l’esprit visionnaire et avant-gardiste du prochain chapitre du jus : un homme qui ne se limite pas aux frontières ordinaires de la vie et qui porte son regard au-delà des décrets du visible »"
        },
        credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["FLAME ARTISTS", "Anita lech, Marc Latil"],
        ],
        visuals: [
          getMediaPath("2969_MAKING_OF_BLEU_DE_CHANEL/Visuals", "CHANEL_BLEU_1H2023_ITW_FRANCE_USA_16x9.00_00_39_01.Still003.jpg"),
          getMediaPath("2969_MAKING_OF_BLEU_DE_CHANEL/Visuals", "Capture d’écran 2024-12-17 à 13.44.05.jpg"),
          getMediaPath("2969_MAKING_OF_BLEU_DE_CHANEL/Visuals", "Capture d’écran 2024-12-17 à 13.48.56.jpg"),
          getMediaPath("2969_MAKING_OF_BLEU_DE_CHANEL/Visuals", "Capture d’écran 2024-12-17 à 13.51.18.jpg"),
          getMediaPath("2969_MAKING_OF_BLEU_DE_CHANEL/Visuals", "Capture d’écran 2024-12-17 à 13.52.07.jpg"),
        ],
    },
    {
        id: 25,
        vimeoId: 1022824845,
        projectDir: "MONCLER_2897",
        customer: "MONCLER",
        name: "Summer of lightness",
        production: "U-TURN",
        director: ["BARNABY ROPER"],
        tags: ["Art&Fashion"],
        agency: "McCann",
        mosaicImg: getMediaPath("MONCLER_2897", "Moncler_30s_v3_1920x1080_VI.00_00_27_08.Still005.jpg"),
        video: getMediaPath("MONCLER_2897", "Moncler_30s_v3_1920x1080_VF.mp4"),
        XSvideo : getMediaPath("MONCLER_2897", "Moncler_30s_v3_1080x1920_VF.mp4"),
        year: "2022",
        description: {
            en: "For the launch of the Spring-Summer 2022 collection, we imagined the Summer territory for Moncler, where freedom rhymes with lightness. The “Summer Of Lightness” campaign is a poetic interlude in which dancers are invited to soar through the air, all in lightness, a nod to the brand's iconic down jacket. The aim is to deseasonalize Moncler by showing that it continues to be present by offering a refreshing wardrobe for the summer period!",
            fr: "A l’occasion du lancement de la collection Printemps-Eté 2022, nous avons imaginé le territoire Eté pour Moncler où liberté rime avec légèreté. La campagne « A Summer Of Lightness » est une parenthèse poétique où des danseurs sont invités à s’élancer dans les airs, toute en légèreté, clin d’œil à la doudoune, produit iconique de la marque. L'objectif, désaisonnaliser Moncler en montrant qu’elle continue d’être présente en proposant une garde-robe rafraichissante pour la période estivale!"
        },
        credits: [
          ["POST-PRODUCER","Jean-Marc Raygade"],
          ["EDITORS", "JP Mure, Etienne Penin"],
          ["FLAME ARTISTS", "François Gilguy, Yann Masson"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [getMediaPath("MONCLER_2897", "Moncler_30s_v3_1920x1080_VI.00_00_04_16.Still001.jpg"), getMediaPath("MONCLER_2897", "Moncler_30s_v3_1920x1080_VI.00_00_22_13.Still003.jpg"), getMediaPath("MONCLER_2897", "Moncler_30s_v3_1920x1080_VI.00_00_30_24.Still006.jpg"), getMediaPath("MONCLER_2897", "Moncler_30s_v3_1920x1080_VI.00_00_06_12.Still002.jpg"), getMediaPath("MONCLER_2897", "Moncler_30s_v3_1920x1080_VI.00_00_27_08.Still005.jpg"), getMediaPath("MONCLER_2897", "Moncler_30s_v3_1920x1080_VI.00_00_24_15.Still004.jpg")]
    },
    {
        id: 26,
        vimeoId: 1022825287,
        projectDir: "3005_VOGUE_EDGAR_BERG",
        customer: "VOGUE",
        name: "The Journey Beyond",
        production: "PSYCHO",
        director: ["EDGAR BERG","https://www.edgarberg.com/"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("3005_VOGUE_EDGAR_BERG", "mosaic_Capture d’écran 2024-11-12 à 18.47.21.jpg"),
        video: getMediaPath("3005_VOGUE_EDGAR_BERG", "Vogue_55s_230824_CONFO_h264.mp4"),
        XSvideo: getMediaPath("3005_VOGUE_EDGAR_BERG", "Vogue_55s_230824_CONFO_h264.mp4"),
            year: "2023",
        description: {
            en: "Vogue - Philippines. A magical journey to Iceland with Nxxxxxs music.",
            fr: "Vogue - Philippines. Un voyage magique vers l'Islande avec la musique de Nxxxxxs."
        },
        descriptionFr: "Directed by Edgar Berg (https://www.edgarberg.com/) for Vogue - Philippines. A magical journey to Iceland with Nxxxxxs music.",
        credits: [
            ["POST-PRODUCER(F)", "Aurélie Lobin"],
            ["EDITOR", "JP Mure"],
            ["GRADING", "Sylvain Canaux"],
            ["MUSIC", "Nxxxxxs"],
        ],
        visuals: [
          getMediaPath("3005_VOGUE_EDGAR_BERG/VISUALS", "Capture d’écran 2024-11-12 à 18.43.29.jpg"),
          getMediaPath("3005_VOGUE_EDGAR_BERG/VISUALS", "Capture d’écran 2024-11-12 à 18.44.32.jpg"),
          getMediaPath("3005_VOGUE_EDGAR_BERG/VISUALS", "Capture d’écran 2024-12-17 à 14.02.05.jpg"),
          getMediaPath("3005_VOGUE_EDGAR_BERG/VISUALS", "Capture d’écran 2024-12-17 à 14.02.52.jpg"),
          getMediaPath("3005_VOGUE_EDGAR_BERG/VISUALS", "Capture d’écran 2024-12-17 à 14.00.20.jpg"),
        ],
    },
    {
        id: 27,
        master: 2991,
        vimeoId: 1022841721,
        projectDir: "2991_MAKING_OF_DE_CHANEL",
        customer: "CHANEL",
        director: ["FRANCESCA SCORCESE"],
        name: "MAKING OF LE BLEU",
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2991_MAKING_OF_DE_CHANEL", "mosaic_230725_Vertigo_BTS_120s_16-9_proreshq.00_01_34_11.Still001.jpg"),
        video: getMediaPath("2991_MAKING_OF_DE_CHANEL", "230725_Vertigo_BTS_120s_16-9_h264.mp4"),
        XSvideo : getMediaPath("MONCLER_2897", "Moncler_30s_v3_1080x1920_VF.mp4"),
        year: "2024",
        description: {
            fr: "Coulisses du tournage BLEU de CHANEL avec Thimotée Chalamet & Martin Scorcese",
            en: "Behind the scenes of CHANEL BLEU shoot with Thimotée Chalamet & Martin Scorcese",
        },
        credits: [
          ["POST-PRODUCER(F)","Aurélie Lobin"],
          ["EDITOR", "JP MURE"],
          ["VFX", "Sébastien Filinger, Supralude"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2991_MAKING_OF_DE_CHANEL", "230725_Vertigo_BTS_120s_16-9_proreshq.00_00_24_08.Still004.jpg"),
          getMediaPath("2991_MAKING_OF_DE_CHANEL", "230725_Vertigo_BTS_120s_16-9_proreshq.00_00_46_06.Still003.jpg"),
          getMediaPath("2991_MAKING_OF_DE_CHANEL", "230725_Vertigo_BTS_120s_16-9_proreshq.00_01_43_13.Still002.jpg")
        ]
    },
    {
        id: 28,
        vimeoId: 1022833566,
        projectDir: "2700_HERMES_NOEL",
        customer: "HERMES",
        name: "L'Orange de Noël",
        production: "Excuse me My French",
        director: ["LUKE WHITE"],
        agency: "Publicis et Nous",
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2700_HERMES_NOEL", "mosaic_Capture d’écran 2024-11-13 à 12.52.51.jpg"),
        video: getMediaPath("2700_HERMES_NOEL", "Hermes_Noel_2020_FILM_60s_16-9_Master_vi_h264.mp4"),
        XSvideo : getMediaPath("2700_HERMES_NOEL", "Hermes_Noel_2020_FILM_60s_9-16_Master_vi_h264.mp4"),
        year: "2020",
        description: {
            en: "Celebrate the holiday season. Like childhood memories, objects transport us to the heart of an enchanted world. On an original tune signed Pilooski",
            fr: "Célébrez les fêtes de fin d'année. Comme les souvenirs d'enfance, les objets nous transportent au coeur d'un monde enchanté. Sur une musique originale de Pilooski"
        },
        credits: [
            ["POST-PRODUCER", "Daniel Rota"],
            ["EDITOR", "JP Mure"],
            ["AFTER EFFECTS", "Serge Miot, Seb Fillinger"],
            ["VFX", "Yves Bosson, Olivier Delequeuche"],
            ["ROTOSCOPING","Ludovic Voltaire"],
            ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
            getMediaPath("2700_HERMES_NOEL/VISUALS", "Hermes_Noel_2020_FILM_60s_16-9_Master_vi_V1.mp4"),
            getMediaPath("2700_HERMES_NOEL/VISUALS", "Hermes_Noel_2020_FILM_60s_16-9_Master_vi_V2.mp4"),
            getMediaPath("2700_HERMES_NOEL/VISUALS", "Hermes_Noel_2020_FILM_60s_16-9_Master_vi.00_00_27_06.Still001.jpg"),
            getMediaPath("2700_HERMES_NOEL/VISUALS", "Hermes_Noel_2020_FILM_60s_16-9_Master_vi.00_00_38_11.Still002.jpg"),
            getMediaPath("2700_HERMES_NOEL/VISUALS", "Hermes_Noel_2020_FILM_60s_16-9_Master_vi.00_00_50_15.Still003.jpg")
        ]
    },
    {
        id: 29,
        master: 2715,
        vimeoId: 1023218475,
        projectDir: "2715_HERMES_VERNIS",
        customer: "HERMES",
        name: "LES ROUGES",
        agency: "PUBLICIS",
        production: "PSYCHO",
        director: ["FELIX COOPER","https://felixcooper.com/"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath("2715_HERMES_VERNIS", "mosaic—Hermes_Rouge_Ephemere_PE21_15s_16x9_VI_proresHQ.00_00_00_00.Still001.jpg"),
        video: getMediaPath("2715_HERMES_VERNIS", "Hermes_Vernis_15s_WIP006_VF_H264.mp4"),
        XSvideo: getMediaPath("2715_HERMES_VERNIS", "Hermes_Vernis_15s_WIP006_VF_H264.mp4"),
        year: "2020",
        description: {
            en: "",
            fr: ""
        },
        credits: [
            ["POST-PRODUCER", "Jean-Marc Raygade"],
            ["EDITOR", "JP Mure"],
            ["FLAME ARTISTS", "Christophe Richard, Yann Masson"],
            ["GRADING", "Sylvain Canaux"]
        ],
        visuals: [
          getMediaPath("2715_HERMES_VERNIS/VISUALS", "Hermes_Rouge_Ephemere_PE21_15s_16x9_VI_proresHQ.00_00_00_00.Still001.jpg"),
          getMediaPath("2715_HERMES_VERNIS/VISUALS", "Hermes_Rouge_Ephemere_PE21_15s_16x9_VI_proresHQ.00_00_05_22.Still002.jpg"),
          getMediaPath("2715_HERMES_VERNIS/VISUALS", "Hermes_Rouge_Ephemere_PE21_15s_16x9_VI_proresHQ.00_00_03_15.Still003.jpg"),
          getMediaPath("2715_HERMES_VERNIS/VISUALS", "Hermes_Rouge_Ephemere_PE21_15s_16x9_VI_proresHQ_V1.mp4"),
          getMediaPath("2715_HERMES_VERNIS/VISUALS", "210125_Hermes_Vernis_15s_WIP006_16x9_VI.00_00_04_22.Still001.jpg"),
        ],
      },
      {
        id: 30,
        master: 3025,
        vimeoId: 1028734314,
        projectDir: "3025_ODYSSEE_FLORALE",
        customer: "BAPTISTE OLIVIER",
        name: "FLORAL JOURNEY",
        production: "JEANNE ARCELIN",
        director: ["BAPTISTE OLIVIER"],
        tags: ["Clips"],
        mosaicImg: getMediaPath("3025_ODYSSEE_FLORALE", "mosaic_Capture d’écran 2024-11-13 à 12.31.28.jpg"),
        video: getMediaPath("3025_ODYSSEE_FLORALE", "231120_ODYSSEE_FLORALE_H264.mp4"),
        XSvideo: getMediaPath("3025_ODYSSEE_FLORALE", "231120_ODYSSEE_FLORALE_H264.mp4"),
            year: "2024",
        description: {
            en: "",
            fr: ""
        },
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "Adrien Franke"],
          ["GRADING", "Laura Fries Pizarro"],
        ],
        visuals: [
          getMediaPath("3025_ODYSSEE_FLORALE/VISUALS", "231120_ODYSSEE_FLORALE_H264 Copy 01.mp4"),
          getMediaPath("3025_ODYSSEE_FLORALE/VISUALS", "Capture d’écran 2024-11-20 à 14.53.45.jpg"),
          getMediaPath("3025_ODYSSEE_FLORALE/VISUALS", "Capture d’écran 2024-12-17 à 14.18.23.jpg"),
          getMediaPath("3025_ODYSSEE_FLORALE/VISUALS", "Capture d’écran 2024-12-17 à 14.20.29.jpg"),
          getMediaPath("3025_ODYSSEE_FLORALE/VISUALS", "Capture d’écran 2024-12-17 à 14.17.50.jpg"),
        ],
      },

      {
        id: 31,
        master: 1761,
        vimeoId: 1028763026,        
        projectDir: "1761_CARTIER_BAISER",
        customer: "CARTIER",
        name: "Baiser Volé",
        production: "PSYCHO",
        director: ["SEAN ELLIS"],
        agency: "MAZARINE",
        tags: ["Luxe"],
        mosaicImg: getMediaPath("1761_CARTIER_BAISER", "mosaic_Capture d’écran 2024-10-31 à 16.21.59.jpg"),
        video: getMediaPath("1761_CARTIER_BAISER", "1761HD_Cartier_Baiser_Vole_45s_Sean_Ellis_2011.mp4"),
        XSvideo: getMediaPath("1761_CARTIER_BAISER", "1761HD_Cartier_Baiser_Vole_45s_Sean_Ellis_2011.mp4"),
        year: "2011",
        description: {
            en: "Toni Garr is the face of Cartier's women's fragrance Baiser Volé at the Grand Palais in Paris, accompanied by Lee Byeong-Woo's “Epilogue”.",
            fr: "Toni Garr est l'égérie du parfum féminin Baiser Volé de Cartier sous la Coupole du  Grand Palais Paris accompagné par la musique “Epilogue“ de Lee Byeong-Woo."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["FLAME ARTISTS", "James Senade, Cyril Sozzi, Bénédicte Hostache"],
          ["GRADING", "Bertrand Duval"],
        ],
        visuals: [
          getMediaPath("1761_CARTIER_BAISER/Visuals", "Capture d’écran 2024-10-31 à 16.21.32.jpg"),
          getMediaPath("1761_CARTIER_BAISER/Visuals", "Capture d’écran 2024-12-16 à 18.23.51.jpg"),
          getMediaPath("1761_CARTIER_BAISER/Visuals", "Capture d’écran 2024-12-16 à 18.25.43.jpg"),
          getMediaPath("1761_CARTIER_BAISER/Visuals", "Capture d’écran 2024-12-17 à 10.42.20.jpg"),
          getMediaPath("1761_CARTIER_BAISER/Visuals", "Capture d’écran 2024-12-17 à 10.41.43.jpg"),
        ],
          },
      {
        id: 32,
        master: 1942,
        vimeoId: 1028763827,
        projectDir: "1942_CARTIER_TANK",
        customer: "CARTIER",
        name: "CARTIER TANK - NEVER STOP",
        production: "CAN",
        agency: "133 LUX",
        director: ["BAILLIE WALSH"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("1942_CARTIER_TANK", "mosaic_Capture d’écran 2024-10-31 à 16.24.48.jpg"),
        video: getMediaPath("1942_CARTIER_TANK", "CARTIER_TANK_45s_5994-Apple ProRes 422 HD.mp4"),
        XSvideo: getMediaPath("1942_CARTIER_TANK", "CARTIER_TANK_45s_5994-Apple ProRes 422 HD.mp4"),
          year: "2019",
        description: {
            en: "Directed by Baillie Walsh, Cartier & Andy Lau celebrate the launch of the Tank MC watch with an exclusive short film as fast-paced as their philosophy of time and life. Track credited as Thomas Player – A Series of Numbers",
            fr: "Réalisé par Baillie Walsh, Cartier et Andy Lau célèbrent le lancement de la montre Tank MC avec un court métrage exclusif au pas de leur philosophie du temps et de la vie. Musique par Thomas Player - A Series of Numbers"
        },
        credits: [
          ["POST-PRODUCTION", "Saint-Georges"],
          ["POST-PRODUCER(F)", "Renata Azambuja"],
          ["EDITOR", "Mario Battistel"],
          ["LEAD FLAME ARTIST", "James Senade, Benjamin Laborde"],
          ["FLAME ARTIST", "Cyril Sozzi"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("1942_CARTIER_TANK/Visuals", "Capture d’écran 2024-10-30 à 15.08.29.jpg"),
          getMediaPath("1942_CARTIER_TANK/Visuals", "Capture d’écran 2024-12-17 à 14.44.46.jpg"),
          getMediaPath("1942_CARTIER_TANK/Visuals", "Capture d’écran 2024-12-17 à 14.42.42.jpg"),
          getMediaPath("1942_CARTIER_TANK/Visuals", "Capture d’écran 2024-12-17 à 14.43.14.jpg"),
          getMediaPath("1942_CARTIER_TANK/Visuals", "Capture d’écran 2024-12-17 à 14.44.20.jpg"),
        ],
        },  
      {
        id: 33,
        master: 2000,
        vimeoId: 1028764985,
        projectDir: "2000_CARTIER_BRIDAL",
        customer: "CARTIER",
        name: "CARTIER BRIDAL",
        production: "PSYCHO",
        director: ["SEA ELLIS"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2000_CARTIER_BRIDAL", "mosaic_Capture d’écran 2024-10-31 à 16.27.28.jpg"),
        video: getMediaPath("2000_CARTIER_BRIDAL", "2000_Cartier_Rodin_2min50s_Sean_Ellis_2014_1.mp4"),
        XSvideo: getMediaPath("2000_CARTIER_BRIDAL", "2000_Cartier_Rodin_2min50s_Sean_Ellis_2014_1.mp4"),
        year: "2015",
        description: {
            en: "",
            fr: ""
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Richard Mettler"],
          ["FLAME ARTISTS", "Marc Latil, Sarah Quilbé, Cyril Sozzi"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2000_CARTIER_BRIDAL/Visuals", "Capture d’écran 2024-10-30 à 14.29.00.jpg"),
          getMediaPath("2000_CARTIER_BRIDAL/Visuals", "Capture d’écran 2024-10-31 à 16.28.39.jpg"),
          getMediaPath("2000_CARTIER_BRIDAL/Visuals", "Capture d’écran 2024-10-31 à 16.29.06.jpg"),
          getMediaPath("2000_CARTIER_BRIDAL/Visuals", "Capture d’écran 2024-10-31 à 16.30.12.jpg"),
          getMediaPath("2000_CARTIER_BRIDAL/Visuals", "Capture d’écran 2024-10-31 à 16.30.28.jpg"),
          getMediaPath("2000_CARTIER_BRIDAL/Visuals", "Capture d’écran 2024-10-31 à 16.31.07.jpg"),
        ],
      },
      {
        id: 34,
        master: 3044,
        vimeoId: 1039698904,
        projectDir: "3044_HelenaRubinstein",
        customer: "Helena Rubinstein",
        name: "Re-plasty Age recovery with Michelle Yeoh",
        production: "PSYCHO",
        director: ["SOLVE SUNDSBO"],
        tags: ["Beauty"],
        agency: "Publicis Luxe",
        mosaicImg: getMediaPath("3044_HelenaRubinstein", "mosaic.jpg"),
        video: getMediaPath("3044_HelenaRubinstein", "HR_skin_MYTripleBandage_24_film_27s_16-9_VA copie.mp4"),
        XSvideo: getMediaPath("3044_HelenaRubinstein", "HR_skin_MYTripleBandage_24_film_27s_16-9_VA copie.mp4"),
        year: "2024",
        description: {
          en: "The renowned beauty company Helena Rubinstein proudly announces its collaboration with worldclass actress Michelle Yeoh, now the brand's worldwide muse. This union symbolizes the perfect fusion between the brand's relentless innovation and the inspiring journey of Michelle Yeoh, 2022 Time magazine's Icon of the Year, Oscar winner in 2023 for her role in Everything Everywhere All at Once, and United Nations Goodwill Ambassador.", 
          fr: "La prestigieuse maison de beauté Helena Rubinstein annonce fièrement sa collaboration avec l’actrice Michelle Yeoh, désormais égérie mondiale de la marque. Cette union symbolise la fusion parfaite entre l’innovation incessante de la marque et le parcours inspirant de Michelle Yeoh, icône de l'année 2022 du magazine Time, oscarisée en 2023 pour son rôle Everything Everywhere All at Once, et ambassadrice de bonne volonté des Nations Unies."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["VFX", "Sylvery Bolotte, Edin Hadzic"],
          ["FLAME ARTISTS", "Hugo Soriano, François Gilguy, Camille Miranda"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("3044_HelenaRubinstein/Visuals", "visual1.jpg"),
          getMediaPath("3044_HelenaRubinstein/Visuals", "visual2.jpg"),
          getMediaPath("3044_HelenaRubinstein/Visuals", "visual5.jpg"),
          getMediaPath("3044_HelenaRubinstein/Visuals", "visual3.jpg"),
          getMediaPath("3044_HelenaRubinstein/Visuals", "visual4.jpg"),
        ],
      },
      {
        id: 35,
        master: 2270,
        vimeoId: 1028767110,
        projectDir: "2270_HYERES_FESTIVAL_2017",
        customer: "VILLA NOAILLES",
        name: "32ÈME FESTIVAL D'HYÈRES",
        production: "PSYCHO",
        director: ["LACEY"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2270_HYERES_FESTIVAL_2017", "mosaic_2270_HYERES_FESTIVAL_WIP02_Lacey_2017.00_01_52_16.Still004.jpg"),
        video: getMediaPath("2270_HYERES_FESTIVAL_2017", "2270_HYERES_FESTIVAL_WIP02_Lacey_2017.mp4"),
        XSvideo: getMediaPath("2270_HYERES_FESTIVAL_2017", "2270_HYERES_FESTIVAL_WIP02_Lacey_2017.mp4"),
        year: "2017",
        description: {
            en: "The International Festival of Fashion and Photography, founded in 1985 under the aegis of Jean-Pierre Blanc, director of the Villa Noailles in Hyères, southern France.",
            fr: "Le Festival international de mode et de photographie, fondé en 1985 sous l'égide de Jean-Pierre Blanc, directeur de la villa Noailles à Hyères dans le sud de la France."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Kate Owen"],
          ["FLAME ARTISTS", "Mathieu Caulet, François Gilguy, Sarah Quilbé, Léo Verdier"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2270_HYERES_FESTIVAL_2017/Visuals", "2270_HYERES_FESTIVAL_WIP02_Lacey_2017_V2.mp4"),
          getMediaPath("2270_HYERES_FESTIVAL_2017/Visuals", "2270_HYERES_FESTIVAL_WIP02_Lacey_2017_V1.mp4"),
          getMediaPath("2270_HYERES_FESTIVAL_2017/Visuals", "Capture d’écran 2024-11-12 à 18.56.04.jpg"),
          getMediaPath("2270_HYERES_FESTIVAL_2017/Visuals", "Capture d’écran 2024-11-12 à 18.56.31.jpg"),
          getMediaPath("2270_HYERES_FESTIVAL_2017/Visuals", "Capture d’écran 2024-11-12 à 18.56.52.jpg"),
        ],
      },
      {
        id: 36,
        master: 2283,
        vimeoId: 1028769114,
        projectDir: "2283_CARRE_HERMES",
        customer: "HERMES",
        name: "Le carré",
        production: "",
        director: ["VINCENT WARIN"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2283_CARRE_HERMES", "Mosaic_Hermes.jpg"),
        video: getMediaPath("2283_CARRE_HERMES", "2283_HERMES_HIVER_wip04_white_Vincent_Warin_2017_proresHQ.mp4"),
        XSvideo: getMediaPath("2283_CARRE_HERMES", "2283_HERMES_HIVER_wip04_white_Vincent_Warin_2017_proresHQ.mp4"),
        year: "2017",
        description: {
            en: "",
            fr: ""
        },
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "JP Mure"],
          ["FLAME ARTISTS", "Mathieu Caulet, Damien Peiro, Léo Verdier"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2283_CARRE_HERMES/Visuals", "visual3.mp4"),
          getMediaPath("2283_CARRE_HERMES/Visuals", "visual4.mp4"),
          getMediaPath("2283_CARRE_HERMES/Visuals", "visual2.jpg"),
          getMediaPath("2283_CARRE_HERMES/Visuals", "visual1.jpg"),
          
          getMediaPath("2283_CARRE_HERMES/Visuals", "visual5.jpg"),
        ],
      },
      {
        id: 37,
        master: 2318,
        vimeoId: 1028792083,
        projectDir: "2318_HERMES_MARIONETTES",
        customer: "HERMES",
        name: "Le songe du Poète",
        production: "WACKO",
        director: ["METZ & RACINE"],
        tags: ["Luxe"],
        agency: "Publicis Et Nous",
        mosaicImg: getMediaPath("2318_HERMES_MARIONETTES", "mosaic_Hermes.jpg"),
        video: getMediaPath("2318_HERMES_MARIONETTES", "2318_HERMES_VLongue_FR_Prores_HQ.mp4"),
        XSvideo: getMediaPath("2318_HERMES_MARIONETTES", "2318_HERMES_VLongue_FR_Prores_HQ.mp4"),
        year: "2017",
        description: {
          en: "At the invitation of Hervé Sauvage, Emmanuelle Trazic and Claire Rabant created the puppets for Songe du poète, for the famous Hermès brand using objects from their collection. David Lippe then joined them for the manipulation, during the shoot orchestrated by Metz et Racine, in July 2017.",
          fr: "Sur une invitation d’Hervé Sauvage, Emmanuelle Trazic a créé avec Claire Rabant les marionnettes du Songe du poète, pour la célèbre marque Hermès à partir des objets de leur collection. David Lippe les a ensuite rejoint pour la manipulation, lors du tournage orchestré par Metz et Racine, en juillet 2017."
        },
        credits: [
          ["EDITOR", "JP Mure"],
          ["VFX / AFTER EFFECTS", "Sébastien Filinger, Serge Miot, Cédric Morelln"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2318_HERMES_MARIONETTES/Visuals", "2318_HERMES_VLongue_FR_Prores_HQ_V1.mp4"),
          getMediaPath("2318_HERMES_MARIONETTES/Visuals", "Capture d’écran 2024-12-17 à 11.08.00.jpg"),
          getMediaPath("2318_HERMES_MARIONETTES/Visuals", "Capture d’écran 2024-12-17 à 11.08.21.jpg"),
          getMediaPath("2318_HERMES_MARIONETTES/Visuals", "Capture d’écran 2024-12-17 à 11.08.47.jpg"),
          getMediaPath("2318_HERMES_MARIONETTES/Visuals", "Capture d’écran 2024-12-17 à 11.09.20.jpg"),
        ],
      },  
      {
        id: 38,
        master: 2374,
        vimeoId: 1028792749,
        projectDir: "2374_MY_AMERICA",
        customer: "BARNABY ROPER",
        name: "MY AMERICA",
        production: "CADENCE FILMS",
        director: ["BARNABY ROPER"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2374_MY_AMERICA", "mosaic_Capture d’écran 2024-10-30 à 15.26.01.jpg"),
        video: getMediaPath("2374_MY_AMERICA", "2374_MY_AMERICA_Trailer_Obama_1Min.mp4"),
        XSvideo: getMediaPath("2374_MY_AMERICA", "2374_MY_AMERICA_Trailer_Obama_1Min.mp4"),
        year: "2018",
        description: {
          en: "'My America' is a 17-minute short documentary that raises the voices of a group that are often accounted for, but seldom listened to: youth. The film follows a small group of disenfranchised young people as they describe what their personal America looks like, and how they are trying to enact change in their own way.",
          fr: "'My America' est un court documentaire de 17 minutes qui fait entendre la voix d'un groupe dont on parle souvent, mais que l'on écoute rarement : les jeunes. Le film suit un petit groupe de jeunes privés de leurs droits qui décrivent leur Amérique à eux, et tentent d'y apporter des changements à leur manière."
        },
        credits: [
          ["EDITOR", "Matt Nee"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2374_MY_AMERICA/Visuals", "2374_MY_AMERICA_1MIN_TRAILER.00_00_29_20.Still001.jpg"),
          getMediaPath("2374_MY_AMERICA/Visuals", "Capture d’écran 2024-12-17 à 11.33.17.jpg"),
          getMediaPath("2374_MY_AMERICA/Visuals", "Capture d’écran 2024-12-17 à 11.32.08.jpg"),
          getMediaPath("2374_MY_AMERICA/Visuals", "Capture d’écran 2024-12-17 à 11.31.35.jpg"),
          getMediaPath("2374_MY_AMERICA/Visuals", "Capture d’écran 2024-12-17 à 11.36.50.jpg"),
          getMediaPath("2374_MY_AMERICA/Visuals", "Capture d’écran 2024-12-17 à 11.37.08.jpg"),
        ],
      },
      {
        id: 39,
        master: 2391,
        vimeoId: 1028793626,
        projectDir: "2391_ANTIDOTE_2018",
        customer: "ANTIDOTE",
        name: "PRINTEMPS",
        production: "",
        director: ["YANN WEBER"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2391_ANTIDOTE_2018", "mosaic_ANTIDOTE_2018.jpg"),
        video: getMediaPath("2391_ANTIDOTE_2018", "FILM_PRINTEMPS_v01_HD.mp4"),
        XSvideo: getMediaPath("2391_ANTIDOTE_2018", "FILM_PRINTEMPS_v01_HD.mp4"),
        year: "2018",
        description: {
          en: "This spring, we rise with Antidote to the music of Walhalla by Perez & Mathilde Fernandez",
          fr: "Ce printemps, on prend de la hauteur avec Antidote sur la musique Walhalla de Perez & Mathilde Fernandez"
        },
        credits: [
          ["EDITOR", "JP Mure"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2391_ANTIDOTE_2018/Visuals", "FILM_PRINTEMPS_v01_HD_V1.mp4"),
          getMediaPath("2391_ANTIDOTE_2018/Visuals", "FILM_PRINTEMPS_v01_HD_V2.mp4"),
          getMediaPath("2391_ANTIDOTE_2018/Visuals", "FILM_PRINTEMPS_v01_HD.00_00_12_09.Still002.jpg"),
          getMediaPath("2391_ANTIDOTE_2018/Visuals", "FILM_PRINTEMPS_v01_HD.00_00_23_21.Still003.jpg"),
          getMediaPath("2391_ANTIDOTE_2018/Visuals", "FILM_PRINTEMPS_v01_HD.00_00_43_24.Still001.jpg"),
        ],
      },
      {
        id: 40,
        master: 2410,
        vimeoId: 1028796618,
        projectDir: "2410_HYERES_FESTIVAL_2018",
        customer: "FESTIVAL D'HYÈRES",
        name: "I'LL BE YOUR MIRROR",
        production: "PSYCHO",
        director: ["I COULD NEVER BE A DANCER"],
        tags: ["Clips"],
        mosaicImg: getMediaPath("2410_HYERES_FESTIVAL_2018", "mosaic_Capture d’écran 2024-10-30 à 15.39.21.jpg"),
        video: getMediaPath("2410_HYERES_FESTIVAL_2018", "2410_Hyeres_Vlongue_1920x1080_proresHQ_20180428.mp4"),
        XSvideo: getMediaPath("2410_HYERES_FESTIVAL_2018", "2410_Hyeres_Vlongue_1920x1080_proresHQ_20180428.mp4"),
        year: "2018",
        description: {
          en: "« I’LL BE YOUR MIRROR » - film for the 33rd international fashion festival in Hyeres, presenting the 10 finalists for the accessory design award.", 
          fr: "« I'LL BE YOUR MIRROR » - Un film pour le 33ème festival international de la mode à Hyères, dans la sélection des 10 finalistes pour le prix du design d'accessoires."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["FLAME ARTISTS", "François Gilguy, Léo Verdier"],
          ["GRADING", "Sylvaon Canaux"],
        ],
        visuals: [
          getMediaPath("2410_HYERES_FESTIVAL_2018/Visuals", "Capture d’écran 2024-10-30 à 15.33.51.jpg"),
          getMediaPath("2410_HYERES_FESTIVAL_2018/Visuals", "Capture d’écran 2024-10-30 à 15.37.52.jpg"),
          getMediaPath("2410_HYERES_FESTIVAL_2018/Visuals", "Capture d’écran 2024-11-12 à 19.06.19.jpg"),
          getMediaPath("2410_HYERES_FESTIVAL_2018/Visuals", "Capture d’écran 2024-12-17 à 12.06.44.jpg"),
          getMediaPath("2410_HYERES_FESTIVAL_2018/Visuals", "Capture d’écran 2024-12-17 à 12.09.37.jpg"),
        ],
      },  
      {
        id: 41,
        master: 2459,
        vimeoId: 1028797832,
        projectDir: "2459_LOUBOUTIN_INTERIOR_DESIGN",
        customer: "LOUBOUTIN",
        name: "Interior Design",
        production: "WACKO",
        director: ["MARTIN ALEMAN"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2459_LOUBOUTIN_INTERIOR_DESIGN", "mosaic_LOUBOUTIN_INTERIOR_DESIGN.jpg"),
        video: getMediaPath("2459_LOUBOUTIN_INTERIOR_DESIGN", "LOUBOUTIN_InteriorDesign_dircut_20180727_proresHQ.mp4"),
        XSvideo: getMediaPath("2459_LOUBOUTIN_INTERIOR_DESIGN", "LOUBOUTIN_InteriorDesign_dircut_20180727_proresHQ.mp4"),
        year: "2018",
        description: {en: "", fr: ""},
        credits: [
          ["EDITOR", "Alexandre Andre"],
          ["AFTER EFFECTS ARTIST", "Serge Miot"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2459_LOUBOUTIN_INTERIOR_DESIGN/Visuals", "LOUBOUTIN_InteriorDesign_dircut_20180727_proresHQ_V2.mp4"),
          getMediaPath("2459_LOUBOUTIN_INTERIOR_DESIGN/Visuals", "LOUBOUTIN_InteriorDesign_dircut_20180727_proresHQ_V1.mp4"),
          getMediaPath("2459_LOUBOUTIN_INTERIOR_DESIGN/Visuals", "Capture d’écran 2024-10-30 à 15.42.32.jpg"),
          getMediaPath("2459_LOUBOUTIN_INTERIOR_DESIGN/Visuals", "Capture d’écran 2024-10-30 à 15.43.44.jpg"),
          getMediaPath("2459_LOUBOUTIN_INTERIOR_DESIGN/Visuals", "LOUBOUTIN_InteriorDesign_dircut_20180727_proresHQ.00_00_18_23.Still003.jpg"),
        ],
      },
      {
        id: 42,
        master: 2460,
        vimeoId: 1028798380,
        projectDir: "2460_SESSUN",
        customer: "SESSÙN",
        name: "SPRING SUMMER 2019",
        production: "WACKO",
        director: ["MARTIN ALEMAN"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2460_SESSUN", "mosaic_Capture d’écran 2024-10-30 à 15.57.05.jpg"),
        video: getMediaPath("2460_SESSUN", "SESSUN_DIRCUT_proresHQ.mp4"),
        XSvideo: getMediaPath("2460_SESSUN", "SESSUN_DIRCUT_proresHQ.mp4"),
        year: "2019",
        description: {en: "", fr: ""},
        credits: [
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2460_SESSUN/Visuals", "SESSUN_DIRCUT_proresHQ_V1.mp4"),
          getMediaPath("2460_SESSUN/Visuals", "SESSUN_DIRCUT_proresHQ_V2.mp4"),
          getMediaPath("2460_SESSUN/Visuals", "Capture d’écran 2024-10-31 à 14.12.05.jpg"),
        ],
      },
      {
        id: 43,
        master: 2475,
        vimeoId: 1029306693,
        projectDir: "2475_LOUBOUTIN_LOUBI_RUN",
        customer: "LOUBOUTIN",
        name: "RUN LOUBI RUN",
        production: "WACKO",
        director: ["MARTIN ALEMAN"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2475_LOUBOUTIN_LOUBI_RUN", "mosaic_2475_LOUBOUTIN_RUN_LOUBI_RUN_EDIT13_tc02c_proresHQ.00_00_44_08.Still004.jpg"),
        video: getMediaPath("2475_LOUBOUTIN_LOUBI_RUN", "2475_LOUBOUTIN_RUN_LOUBI_RUN_EDIT13_tc02c_proresHQ.mp4"),
        XSvideo: getMediaPath("2475_LOUBOUTIN_LOUBI_RUN", "2475_LOUBOUTIN_RUN_LOUBI_RUN_EDIT13_tc02c_proresHQ.mp4"),
        year: "2019",
        description: {
          en: "Starring the talented Kevin Mischel", 
          fr: "Avec le talentueux Kevin Mischel"
        },
        credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["EDITORS", "JP Mure, Alexandre André"],
          ["VFX / AFTER EFFECTS", "Serge Miot"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2475_LOUBOUTIN_LOUBI_RUN/Visuals", "2475_LOUBOUTIN_RUN_LOUBI_RUN_EDIT13_tc02c_proresHQ.00_00_25_07.Still003.jpg"),
          getMediaPath("2475_LOUBOUTIN_LOUBI_RUN/Visuals", "Capture d’écran 2024-12-17 à 12.11.16.jpg"),
          getMediaPath("2475_LOUBOUTIN_LOUBI_RUN/Visuals", "Capture d’écran 2024-12-17 à 12.12.15.jpg"),
          getMediaPath("2475_LOUBOUTIN_LOUBI_RUN/Visuals", "Capture d’écran 2024-12-17 à 12.16.14.jpg"),
          getMediaPath("2475_LOUBOUTIN_LOUBI_RUN/Visuals", "Capture d’écran 2024-12-17 à 12.17.41.jpg"),
        ],
    
      },  
      {
        id: 44,
        master: 2902,
        vimeoId: 1039698603,
        projectDir: "2902_CHANEL_LE_BLANC_2022",
        customer: "CHANEL",
        name: "Le Blanc Make Up",
        production: "PSYCHO",
        director: ["SOLVE SUNDSBO"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath("2902_CHANEL_LE_BLANC_2022", "mosaic_chanel.jpg"),
        video: getMediaPath("2902_CHANEL_LE_BLANC_2022", "CHANEL_LE_BLANC_MakeUp_15s_16x9.mp4"),
        XSvideo: getMediaPath("2902_CHANEL_LE_BLANC_2022", "CHANEL_LE_BLANC_MakeUp_15s_16x9.mp4"),
          year: "2022",
        description: {
          en: "",
          fr: ""
        },
        credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["EDITOR", "Flora Jacquot"],
          ["VFX", "Boris Coyere, Serge Miot"],
          ["FLAME ARTIST", "Marc Latil"],
          ["GRADING", "Sylvain Canaux "],
        ],
        visuals: [
          getMediaPath("2902_CHANEL_LE_BLANC_2022/VISUALS", "CHANEL_LE_BLANC_MakeUp_15s_16x9.00_00_11_02.Still002.jpg"),
          getMediaPath("2902_CHANEL_LE_BLANC_2022/VISUALS", "Capture d’écran 2024-12-10 à 11.45.07.jpg"),
          getMediaPath("2902_CHANEL_LE_BLANC_2022/VISUALS", "Capture d’écran 2024-12-11 à 12.46.07.jpg"),
          getMediaPath("2902_CHANEL_LE_BLANC_2022/VISUALS", "Capture d’écran 2024-12-17 à 13.39.07.jpg"),
          getMediaPath("2902_CHANEL_LE_BLANC_2022/VISUALS", "Capture d’écran 2024-12-17 à 13.39.36.jpg"),
        ],
      }, 
      {
        id: 45,
        master: 2479,
        vimeoId: 1029307868,
        projectDir: "2479_CARTIER_PASHA",
        customer: "CARTIER",
        name: "PASHA",
        production: "CAPISCO",
        director: ["OLIVIER ARNAUD"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2479_CARTIER_PASHA", "mosaic_Capture d’écran 2024-11-13 à 11.24.15.jpg"),
        video: getMediaPath("2479_CARTIER_PASHA", "Cartier_Pasha_Faille_16s_MixDarkness_20181203_proresHQ.mp4"),
        XSvideo: getMediaPath("2479_CARTIER_PASHA", "Cartier_Pasha_Faille_16s_MixDarkness_20181203_proresHQ.mp4"),
        year: "2018",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["AFTER EFFECTS ARTIST", "Serge Miot"],
          ["3D ARTIST & SUPERVISOR", "Yves Bosson"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2479_CARTIER_PASHA/Visuals", "Cartier_Pasha_Faille_16s_MixDarkness_20181203_proresHQ_V1.mp4"),
          getMediaPath("2479_CARTIER_PASHA/Visuals", "Capture d’écran 2024-11-13 à 11.22.40.jpg"),
          getMediaPath("2479_CARTIER_PASHA/Visuals", "Capture d’écran 2024-11-13 à 11.21.00.jpg"),
        ],
      },
      {
        id: 46,
        master: 2483,
        vimeoId: 1029308276,
        projectDir: "2483_LOUBOUTIN_OCT18",
        customer: "LOUBOUTIN",
        name: "BLACK TIE",
        production: "WACKO",
        director: ["MARTIN ALEMAN"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2483_LOUBOUTIN_OCT18", "Mosaic_Louboutin.jpg"),
        video: getMediaPath("2483_LOUBOUTIN_OCT18", "LOUBOUTIN_BLACK_TIEL_V_REAL_proresHQ.mp4"),
        XSvideo: getMediaPath("2483_LOUBOUTIN_OCT18", "LOUBOUTIN_BLACK_TIEL_V_REAL_proresHQ.mp4"),
        year: "2018",
        description: {
          en: "With an air of The Love boat, Christian Louboutin unveils his vision of Black Tie, imposed as the festive mood for the end of 2018. A good dose of the finest dandy's dressing room, a dash of 70's disco fever, a pinch of timeless feminine elegance... The Black Tie collection sublimates all women, from the most romantic to the most extravagant.",
          fr: "Sous des airs de Croisière s’amuse, Christian Louboutin dévoile sa vision du Black Tie, imposé comme l’ambiance festive de cette fin 2018. Une bonne dose du dressing des plus beaux dandys, un soupçon de la fièvre disco des 70’s, une pincée d’élégance féminine intemporelle… Une collection Black Tie qui sublime toutes les femmes, de la plus romantique à la plus extravagante. "
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Alexandre Andre"],
          ["AFTER EFFECTS ARTIST", "Serge Miot"],
          ["VFX", "Sébastien Filinger, Supralude"],
          ["FLAME ARTIST", "Léo Verdier"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2483_LOUBOUTIN_OCT18/Visuals", "LOUBOUTIN_BLACK_TIEL_V_REAL_proresHQ_V1.mp4"),
          getMediaPath("2483_LOUBOUTIN_OCT18/Visuals", "LOUBOUTIN_BLACK_TIEL_V_REAL_proresHQ_V2.mp4"),
          getMediaPath("2483_LOUBOUTIN_OCT18/Visuals", "Capture d’écran 2024-10-30 à 16.33.15.jpg"),
          getMediaPath("2483_LOUBOUTIN_OCT18/Visuals", "LOUBOUTIN_BLACK_TIEL_V_REAL_proresHQ.00_00_28_11.Still002.jpg"),
          getMediaPath("2483_LOUBOUTIN_OCT18/Visuals", "LOUBOUTIN_BLACK_TIEL_V_REAL_proresHQ.00_00_40_17.Still003.jpg"),
          getMediaPath("2483_LOUBOUTIN_OCT18/Visuals", "Capture d’écran 2024-10-30 à 16.32.18.jpg"),
        ],
      },
      {
        id: 47,
        master: 2484,
        vimeoId: 1029311670,
        projectDir: "2484_AFSEP",
        customer: "AFSEP",
        name: "LAURA, 30 ANS",
        production: "AGENCE 80",
        director: ["THOMAS KELLY"],
        tags: ["Institutional"],
        mosaicImg: getMediaPath("2484_AFSEP", "mosaic_2484_Afsep_Dircut_30s_HD_24FPS_proresHQ.00_00_00_00.Still001.jpg"),
        video: getMediaPath("2484_AFSEP", "2484_Afsep_Dircut_30s_HD_24FPS_proresHQ.mp4"),
        XSvideo: getMediaPath("2484_AFSEP", "2484_Afsep_Dircut_30s_HD_24FPS_proresHQ.mp4"),
        year: "2018",
        description: {
          en: "For 60 YEARS, AFSEP has been taking care of patients' daily lives. It has had your trust for decades, and historically, AFSEP is the oldest patient association. The common thread running through AFSEP is our convictions, our values, the desire for the common good, and the will to move the lines.",
          fr: "Depuis 60 ANS l'AFSEP s'occupe du quotidien des patients. Elle a votre confiance depuis des décennies et historiquement l'AFSEP est la plus ancienne association de patients. Le fil rouge de l'AFSEP c'est nos convictions, nos valeurs, c'est l'envie de bien commun, et la volonté de faire bouger les lignes."
        },
        credits: [
          ["POST-PRODUCER", "Dan Rota"],
          ["EDITOR", "JP Mure"],
          ["GRADING", "Sylvain Canaux"]
        ],
        visuals: [
          getMediaPath("2484_AFSEP/Visuals", "Capture d’écran 2024-12-17 à 14.49.40.jpg"),
          getMediaPath("2484_AFSEP/Visuals", "Capture d’écran 2024-12-17 à 14.50.39.jpg"),
          getMediaPath("2484_AFSEP/Visuals", "Capture d’écran 2024-12-17 à 14.52.36.jpg"),
        ],
      },
      {
        id: 48,
        master: 2487,
        vimeoId: 1029312152,
        projectDir: "2487_PUIG",
        customer: "PUIG",
        name: "CORPORATE",
        production: "",
        director: ["ARMELLE LALO // GABRIEL MALAPRADE"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2487_PUIG", "mosaic_Capture d’écran 2024-10-30 à 16.38.32.jpg"),
        video: getMediaPath("2487_PUIG", "2487_PUIG_Master_1920x1080_proresHQ.mp4"),
        XSvideo: getMediaPath("2487_PUIG", "2487_PUIG_Master_1920x1080_proresHQ.mp4"),
        year: "2019",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["3D ARTIST", "Jon Chaillot"],
        ],
        visuals: [
          getMediaPath("2487_PUIG/Visuals", "2487_PUIG_Master_1920x1080_proresHQ_V1.mp4"),
          getMediaPath("2487_PUIG/Visuals", "2487_PUIG_Master_1920x1080_proresHQ.00_00_09_16.Still002.jpg"),
          getMediaPath("2487_PUIG/Visuals", "2487_PUIG_Master_1920x1080_proresHQ.00_00_24_05.Still003.jpg"),
        ],
      },
      {
        id: 49,
        master: 2489,
        vimeoId: 1029312761,
        projectDir: "2489_GIVENCHY_LIVE_IRRESISTIBLE",
        customer: "GIVENCHY",
        name: "Live Irresistible",
        production: "WACKO",
        director: ["MARCEL WEPPER & LUNDXMAUVIEL"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2489_GIVENCHY_LIVE_IRRESISTIBLE", "mosaic_Capture d’écran 2024-10-31 à 13.19.41.jpg"),
        video: getMediaPath("2489_GIVENCHY_LIVE_IRRESISTIBLE", "Givenchy_15s_Parfum_v02_1920x1080.mp4"),
        XSvideo: getMediaPath("2489_GIVENCHY_LIVE_IRRESISTIBLE", "Givenchy_15s_Parfum_v02_1920x1080.mp4"),
        year: "2019",
        description: {
          en: "",
          fr: ""
        },
        credits: [
          ["POST-PRODUCER(S)", "Aurélie Lobin, Jean-Marc raygade"],
          ["EDITOR", "JP Mure"],
          ["VFX / AFTER EFFECTS", "Serge Miot"],
          ["GRADING", "Sylvain"],
        ],
        visuals: [
          getMediaPath("2489_GIVENCHY_LIVE_IRRESISTIBLE/Visuals", "Givenchy_15s_Parfum_v02_1920x1080.00_00_12_00.Still002.jpg"),
          getMediaPath("2489_GIVENCHY_LIVE_IRRESISTIBLE/Visuals", "Capture d’écran 2024-12-17 à 12.19.02.jpg"),
          getMediaPath("2489_GIVENCHY_LIVE_IRRESISTIBLE/Visuals", "Capture d’écran 2024-12-17 à 12.19.44.jpg"),
          getMediaPath("2489_GIVENCHY_LIVE_IRRESISTIBLE/Visuals", "Capture d’écran 2024-12-17 à 12.20.30.jpg"),
          getMediaPath("2489_GIVENCHY_LIVE_IRRESISTIBLE/Visuals", "Capture d’écran 2024-12-17 à 12.22.25.jpg"),
        ],    
      },
      {
        id: 50,
        master: 2491,
        vimeoId: 1029313149,
        projectDir: "2491_BERLUTI_PENG",
        customer: "BERLUTI",
        name: "Summer 2019",
        production: "PSYCHO",
        director: ["MATT LAMBERT"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2491_BERLUTI_PENG", "mosaic_Capture d’écran 2024-10-30 à 16.43.21.jpg"),
        video: getMediaPath("2491_BERLUTI_PENG", "2491_Berluti_VLongue_proresHQ_Clean.mp4"),
        XSvideo: getMediaPath("2491_BERLUTI_PENG", "2491_Berluti_VLongue_proresHQ_Clean.mp4"),
        year: "2019",
        description: {
          en: "",
          fr: ""
        },
        credits: [
          ["POST-PRODUCER(S)", "Aurélie Lobin, Jean-Marc Raygade"],
          ["EDITOR", "Adi Pek"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2491_BERLUTI_PENG/VISUALS", "2491_Berluti_VLongue_proresHQ_Clean_V1.mp4"),
          getMediaPath("2491_BERLUTI_PENG/VISUALS", "2491_Berluti_VLongue_proresHQ_Clean_V2.mp4"),
          getMediaPath("2491_BERLUTI_PENG/VISUALS", "2491_Berluti_VLongue_proresHQ_Clean.00_00_03_18.Still001.jpg"),
          getMediaPath("2491_BERLUTI_PENG/VISUALS", "2491_Berluti_VLongue_proresHQ_Clean.00_00_40_17.Still004.jpg"),
          getMediaPath("2491_BERLUTI_PENG/VISUALS", "Capture d’écran 2024-11-13 à 11.43.28.jpg"),
        ],
      },
      {
        id: 51,
        master: 2492,
        vimeoId: 1029318511,
        projectDir: "2492_EDDY_DE_PRETTO_RANDOM",
        customer: "EDDY DE PRETTO",
        name: "RANDOM",
        production: "FRENZY",
        director: ["THIBAULT GREVET"],
        tags: ["Clips"],
        mosaicImg: getMediaPath("2492_EDDY_DE_PRETTO_RANDOM", "mosaic_Capture d’écran 2024-10-30 à 16.48.58.jpg"),
        video: getMediaPath("2492_EDDY_DE_PRETTO_RANDOM", "2492_EddyDePretto_Random_proresHQ.mp4"),
        XSvideo: getMediaPath("2492_EDDY_DE_PRETTO_RANDOM", "2492_EddyDePretto_Random_proresHQ.mp4"),
        year: "2019",
        description: {
          en: "Eddy de Pretto’s new music video « Random » represents the loss of identity through digital masks inspired by the aesthetics of social networks. Saint Louis shares this multi-faceted collaboration with frenzy production and Perimetre for the motion design.",
          fr: "Le clip d'Eddy de Pretto « Random » représente la perte d'identité à travers des masques numériques inspirés de l'esthétique des réseaux sociaux. Une collaboration aux multiples visages que saint louis partage avec la production frenzy et Perimetre pour le motion design."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Flora Jacquot"],
          ["3D SUPERVISOR", "Yves Bosson"],
          ["LEAD COMPOSITING", "Johann Roche"],
          ["COMPOSITING", "Gaëtan Bailleul, Fabrizio Sesé"],
          ["FLAME AT REEPOST STUDIO", "Benoit Messager"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2492_EDDY_DE_PRETTO_RANDOM/Visuals", "2492_EddyDePretto_Random_proresHQ_V2.mp4"),
          getMediaPath("2492_EDDY_DE_PRETTO_RANDOM/Visuals", "2492_EddyDePretto_Random_proresHQ_V1.mp4"),
          getMediaPath("2492_EDDY_DE_PRETTO_RANDOM/Visuals", "Capture d’écran 2024-10-30 à 16.46.30.jpg"),
          getMediaPath("2492_EDDY_DE_PRETTO_RANDOM/Visuals", "2492_EddyDePretto_Random_proresHQ.00_00_33_06.Still001.jpg"),
          getMediaPath("2492_EDDY_DE_PRETTO_RANDOM/Visuals", "2492_EddyDePretto_Random_proresHQ.00_01_15_02.Still003.jpg"),
        ],
      },
      {
        id: 52,
        master: 2494,
        vimeoId: 1029319295,
        projectDir: "2494_HERMES_FACE_CACHEE",
        customer: "HERMÈS",
        name: "La face cachée du temps",
        production: "U-TURN",
        director: ["BARNABY ROPER"],
        tags: ["Luxe"],
        agency: "Publicis Et Nous",
        mosaicImg: getMediaPath("2494_HERMES_FACE_CACHEE", "mosaic_Capture d’écran 2024-10-30 à 16.53.34.jpg"),
        video: getMediaPath("2494_HERMES_FACE_CACHEE", "HERMES_60_DIRCUT_036_H264.mp4"),
        XSvideo: getMediaPath("2494_HERMES_FACE_CACHEE", "HERMES_60_DIRCUT_036_H264.mp4"),
        year: "2019",
        description: {
          en: "Live a weightless dream. The Arceau L'heure de la lune watch invites you to lose your spatio-temporal bearings.",
          fr: "Vivez un rêve en apesanteur. La montre Arceau L’heure de la lune vous invite à perdre vos repères spatio-temporels."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Matt Nee"],
          ["FLAME ARTISTS", "François Gilguy, Marc Latil, Aude Nguyen Ngoc, Sébastien Petit, Léo Verdier"],
          ["VFX / AFTER EFFECTS", "Serge Miot"],
          ["3D ARTIST & SUPERVISOR", "Yves Bosson"],
          ["GRADING", "Sylvain Canaux "],
        ],
        visuals: [
          getMediaPath("2494_HERMES_FACE_CACHEE/Visuals", "Capture d’écran 2024-12-17 à 12.26.25.jpg"),
          getMediaPath("2494_HERMES_FACE_CACHEE/Visuals", "Capture d’écran 2024-12-17 à 12.25.29.jpg"),
          getMediaPath("2494_HERMES_FACE_CACHEE/Visuals", "Capture d’écran 2024-12-17 à 12.25.01.jpg"),
          getMediaPath("2494_HERMES_FACE_CACHEE/Visuals", "Capture d’écran 2024-12-17 à 12.26.54.jpg"),
          getMediaPath("2494_HERMES_FACE_CACHEE/Visuals", "Capture d’écran 2024-12-17 à 12.28.05.jpg"),
        ],    
      },
      {
        id: 53,
        master: 2500,
        vimeoId: 310126253,
        projectDir: "2500_ANTIDOTE_GIVENCHY",
        customer: "GIVENCHY",
        name: "THE GIVENCHY JAW SNEAKERS",
        production: "ANTIDOTE",
        director: ["YANN WEBER"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2500_ANTIDOTE_GIVENCHY", "mosaic_Capture d’écran 2024-10-30 à 17.00.50.jpg"),
        video: getMediaPath("2500_ANTIDOTE_GIVENCHY", "2500_Antidote_Givenchy_Film_PRORES.mp4"),
        XSvideo: getMediaPath("2500_ANTIDOTE_GIVENCHY", "2500_Antidote_Givenchy_Film_PRORES.mp4"),
        year: "2019",
        description: {
          en: "Since the arrival of Artistic Director Clare Waight Keller at the helm of the French fashion house, Givenchy has been undergoing a major transformation. An evolution marked by a return to the haute-couture calendar, the creation of Meghan Markle's wedding dress, and now the release of a new version of the already iconic JAW sneakers.",
          fr: "Depuis l’arrivée de la directrice artistique Clare Waight Keller à la tête de la maison française, Givenchy est en pleine transformation. Une évolution marquée par un retour dans le calendrier haute-couture, la création de la robe de mariée de Meghan Markle, et maintenant la sortie d’une nouvelle version des JAW, modèle de sneakers déjà iconique."
        },
        credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["EDITOR", "JP Mure"],
          ["AFTER EFFECTS", "Serge Miot"],
          ["FLAME", "Léo verdier"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2500_ANTIDOTE_GIVENCHY/Visuals", "2500_Antidote_Givenchy_Film_PRORES_V2.mp4"),
          getMediaPath("2500_ANTIDOTE_GIVENCHY/Visuals", "2500_Antidote_Givenchy_Film_PRORES_V1.mp4"),
          getMediaPath("2500_ANTIDOTE_GIVENCHY/Visuals", "2500_Antidote_Givenchy_Film_PRORES.00_00_08_07.Still002.jpg"),
          getMediaPath("2500_ANTIDOTE_GIVENCHY/Visuals", "2500_Antidote_Givenchy_Film_PRORES.00_00_29_12.Still001.jpg"),
        ],
      },
      {
        id: 54,
        master: 2505,
        vimeoId: 1029323200,
        projectDir: "2505_CARTIER_ALTER_EGO",
        customer: "CARTIER",
        name: "ALTER EGO",
        production: "PSYCHO",
        director: ["GORDON VON STEINER"],
        tags: ["Art&Fashion"],
        agency: "Agency 133",
        mosaicImg: getMediaPath("2505_CARTIER_ALTER_EGO", "mosaic_Capture d’écran 2024-10-30 à 17.09.59.jpg"),
        video: getMediaPath("2505_CARTIER_ALTER_EGO", "2505_Cartier_Clash_45s_1920x1080_proresHQ.mp4"),
        XSvideo: getMediaPath("2505_CARTIER_ALTER_EGO", "XS_Cartier_Clash_45s_1080x1920_proresHQ.mp4"),
        year: "2019",
        description: {en: "A film with Kaya Scodelario", fr: "Un film avec Kaya Scodelario "},
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Gordon Von Steiner"],
          ["FLAME ARTISTS", "Yann Masson, Damien Peiro, Léo Verdier"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2505_CARTIER_ALTER_EGO/Visuals", "2505_Cartier_Clash_45s_1920x1080_proresHQ_V2.mp4"),
          getMediaPath("2505_CARTIER_ALTER_EGO/Visuals", "2505_Cartier_Clash_45s_1920x1080_proresHQ_V1.mp4"),
          getMediaPath("2505_CARTIER_ALTER_EGO/Visuals", "2505_Cartier_Clash_45s_1920x1080_proresHQ.00_00_06_14.Still002.jpg"),
          getMediaPath("2505_CARTIER_ALTER_EGO/Visuals", "2505_Cartier_Clash_45s_1920x1080_proresHQ.00_00_16_09.Still001.jpg"),
          getMediaPath("2505_CARTIER_ALTER_EGO/Visuals", "2505_Cartier_Clash_45s_1920x1080_proresHQ.00_00_23_13.Still003.jpg"),
        ],
      },
      {
        id: 55,
        master: 2518,
        vimeoId: 1029323778,
        projectDir: "2518_CHAUMET_EVIDENCE",
        customer: "CHAUMET",
        name: "LIENS ÉVIDENCE",
        production: "U-TURN",
        director: ["BARNABY ROPER"],
        tags: ["Luxe"],
        agency: "M&C Saatchi GAD",
        mosaicImg: getMediaPath("2518_CHAUMET_EVIDENCE", "mosaic_2518_Chaumet_60s_proresHQ.00_00_05_05.Still003.jpg"),
        video: getMediaPath("2518_CHAUMET_EVIDENCE", "2518_Chaumet_60s_proresHQ.mp4"),
        XSvideo: getMediaPath("2518_CHAUMET_EVIDENCE", "2518_Chaumet_60s_proresHQ.mp4"),
        year: "2019",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["FLAME ARTISTS", "François Gilguy, Yann Masson, Damien Peiro, Cyril Sozzi, Léo Verdier"],
          ["AFTER EFFECTS", "Serge Miot"],
          ["COMPOSITING", "Johann Roche"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["VFX ARTISTS", "Yves Bosson, Edin Hadzic, Nicolas vessière"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2518_CHAUMET_EVIDENCE/Visuals", "2518_Chaumet_60s_proresHQ_V2.mp4"),
          getMediaPath("2518_CHAUMET_EVIDENCE/Visuals", "2518_Chaumet_60s_proresHQ_V1.mp4"),
          getMediaPath("2518_CHAUMET_EVIDENCE/Visuals", "2518_Chaumet_60s_proresHQ.00_00_25_17.Still002.jpg"),
          getMediaPath("2518_CHAUMET_EVIDENCE/Visuals", "2518_Chaumet_60s_proresHQ.00_00_37_11.Still001.jpg"),
          getMediaPath("2518_CHAUMET_EVIDENCE/Visuals", "Capture d’écran 2024-10-30 à 17.24.32.jpg"),
        ],
      },
      {
        id: 56,
        master: 2549,
        vimeoId: 1029325153,
        projectDir: "2549_HYERES_2019",
        customer: "VILLA NOAILLES",
        name: "DESIGN PARADE D'HYÈRES 2019",
        production: "PSYCHO",
        director: ["ZOE LE BER"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2549_HYERES_2019", "mosaic—Capture d’écran 2024-11-13 à 11.54.34.jpg"),
        video: getMediaPath("2549_HYERES_2019", "Hyères_2019_def_05_H264.mp4"),
        XSvideo: getMediaPath("2549_HYERES_2019", "Hyères_2019_def_05_H264.mp4"),
        year: "2019",
        description: {
          en: "Created in 2006, Design Parade Hyères aims to share contemporary design with the public and professionals alike.",
          fr: "Créé en 2006, Design Parade Hyères a pour ambition de partager la création contemporaine dans le domaine du design avec le public et les professionnels."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Etienne Penin"],
          ["VFX SUPERVISOR", "Serge Miot"],
          ["FLAME ARTISTS", "François Gilguy, Léo Verdier"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2549_HYERES_2019/Visuals", "Capture d’écran 2024-10-30 à 17.28.54.jpg"),
          getMediaPath("2549_HYERES_2019/Visuals", "Capture d’écran 2024-10-30 à 17.29.40.jpg"),
          getMediaPath("2549_HYERES_2019/Visuals", "Capture d’écran 2024-10-30 à 17.27.49.jpg"),
        ],
      },
      {
        id: 57,
        master: 2562,
        vimeoId: 1029325930,
        projectDir: "2562_HERMES_MERVEILLES",
        customer: "HERMES",
        name: "Eau des merveilles",
        production: "U-TURN",
        director: ["BARNABY ROPER"],
        tags: ["Beauty"],
        agency: "Publicis Et Nous",
        mosaicImg: getMediaPath("2562_HERMES_MERVEILLES", "mosaic_Capture d’écran 2024-10-30 à 17.31.45.jpg"),
        video: getMediaPath("2562_HERMES_MERVEILLES", "191122_HERMES_30s_WIP37+tard_mixWEB_9x16_H264.mp4"),
        XSvideo: getMediaPath("2562_HERMES_MERVEILLES", "191122_HERMES_30s_WIP37+tard_mixWEB_9x16_H264.mp4"),
        year: "2020",
        description: {
          en: "Invented in 2004, Eau des Merveilles d'Hermés is “the perfume of enchantment”, the surprising and magical scent of sparkling femininity. Between heaven and earth, the film directed by Barnaby Roper brings a new perspective to the whimsy of this sparkling fragrance.",
          fr: "Créé en 2004, Eau des Merveilles d’Hermés est « le parfum de l’enchantement » surprenant et magique d’une féminité scintillante. Entre ciel et terre, le film réalisé par Barnaby Roper décline sous un nouvel angle la fantaisie de cette fragrance pétillante."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Matt Nee"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["3D ARTISTS", "Edin Hadzic, Max Pasquiou"],
          ["2D ARTIST/AFTER EFFECTS", 'Serge Miot'],
          ["ROTOSCOPING", "Ludovic Voltaire, Margaux le Neures"],
          ["COMPOSITING", "Johann Roche"],
          ["FLAME ARTISTS", "François Gilguy, Yann Masson, Christophe Richard, Léo Verdier"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2562_HERMES_MERVEILLES/Visuals", "191122_HERMES_30s_WIP37+tard_mixWEB_16x9_proresHQ.00_00_00_00.Still001.jpg"),
          getMediaPath("2562_HERMES_MERVEILLES/Visuals", "191122_HERMES_30s_WIP37+tard_mixWEB_16x9_proresHQ.00_00_08_18.Still002.jpg"),
          getMediaPath("2562_HERMES_MERVEILLES/Visuals", "191122_HERMES_30s_WIP37+tard_mixWEB_16x9_proresHQ.00_00_13_20.Still003.jpg"),
          getMediaPath("2562_HERMES_MERVEILLES/Visuals", "191122_HERMES_30s_WIP37+tard_mixWEB_16x9_proresHQ.00_00_17_17.Still004.jpg"),
          getMediaPath("2562_HERMES_MERVEILLES/Visuals", "191122_HERMES_30s_WIP37+tard_mixWEB_16x9_proresHQ_V1.mp4"),
          getMediaPath("2562_HERMES_MERVEILLES/Visuals", "191122_HERMES_30s_WIP37+tard_mixWEB_16x9_proresHQ_V2.mp4"),
        ],
      },
      {
        id: 58,
        master: 2602,
        vimeoId: 1029327168,
        projectDir: "2602_DANCE_PROJECT_FIRST_DAY",
        customer: "THE LITTLE ROCK NINE",
        name: "FIRST DAY",
        production: "CATWIZARD FILMS, CADENCE FILMS & U-TURN",
        director: ["BARNABY ROPER"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2602_DANCE_PROJECT_FIRST_DAY", "mosaic_Capture d’écran 2024-11-12 à 12.55.45.jpg"),
        alternativeImg: getMediaPath("2602_DANCE_PROJECT_FIRST_DAY", "alternativeMosaic.jpg"),
        video: getMediaPath("2602_DANCE_PROJECT_FIRST_DAY", "Danceproject.mp4"),
        XSvideo: getMediaPath("2602_DANCE_PROJECT_FIRST_DAY", "Danceproject.mp4"),
        year: "2020",
        description: {
          en: "This dance and experimental video is meant as an hommage to Elizabeth Ann Eckford and the other members of the Little Rock Nine. Those nine black students enrolled at Central High in Little Rock Arkansas to protest against racial segregation on september 4th, 1957.",
          fr: "Cette vidéo de danse et d'expérimentation est un hommage à Elizabeth Ann Eckford et aux autres membres des « Little Rock Nine ». Ces neuf étudiants noirs se sont inscrits au Central High à Little Rock Arkansas pour protester contre la ségrégation raciale le 4 septembre 1957."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["3D ARTISTS", "Edin Hadzic, Max Pasquiou"],
          ["2D ARTIST/AFTER EFFECTS", "Serge Miot"],
          ["COMPOSITING", "Johann Roche"],
          ["ANIMATOR", "Thomas Arnould"],
          ["ROTOSCOPING", "Ludovic Voltaire, Margaux le Neures"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2602_DANCE_PROJECT_FIRST_DAY/visuals", "Danceproject_V1.mp4"),
          getMediaPath("2602_DANCE_PROJECT_FIRST_DAY/visuals", "Danceproject_V2.mp4"),
          getMediaPath("2602_DANCE_PROJECT_FIRST_DAY/visuals", "Capture d’écran 2024-11-12 à 12.56.59.jpg"),
          getMediaPath("2602_DANCE_PROJECT_FIRST_DAY/visuals", "Capture d’écran 2024-11-12 à 12.56.45.jpg"),
          getMediaPath("2602_DANCE_PROJECT_FIRST_DAY/visuals", "Capture d’écran 2024-11-12 à 12.56.27.jpg"),
          getMediaPath("2602_DANCE_PROJECT_FIRST_DAY/visuals", "Capture d’écran 2024-11-12 à 12.55.59.jpg"),
        ],
      },
      {
        id: 59,
        master: 2639,
        vimeoId: 1029327858,
        projectDir: "2639_CALM",
        customer: "CALM",
        name: "CALM X LEBRON JAMES",
        production: "CADENCE",
        agency: "UNINTERRUPTED",
        director: ["BARNABY ROPER"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2639_CALM", "mosaic.jpg"),
        video: getMediaPath("2639_CALM", "2639_Calm_30s_1080x1920_H264.mp4"),
        XSvideo: getMediaPath("2639_CALM", "2639_Calm_30s_1080x1920_H264.mp4"),
        year: "2019",
        description: {
          en: "Calm is teaming up with LeBron James to raise awareness about the importance of mental fitness. LeBron credits meditation, sleep, and a healthy mindset as the keys to his extraordinary achievements and personal happiness.",
          fr: "Calm s'associe à LeBron James pour sensibiliser à la santé mentale. LeBron attribue à la méditation, au sommeil et à un état d'esprit sain les clés de ses succès extraordinaires et de son bonheur personnel."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["3D ARTISTS", "Max Pasquiou, Boris Coyere"],
          ["COMPOSITING", "Johann Roche"],
          ["FLAME ARTISTS", "François Gilguy, Marc Latil, Yann Masson, Christophe Richard, Léo Verdier"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2639_CALM/Visuals", "2639_Calm_30s_1920x1080_proresHQ.00_00_23_11.Still001.jpg"),
          getMediaPath("2639_CALM/Visuals", "2639_Calm_30s_1920x1080_proresHQ.00_00_06_04.Still002.jpg"),
          getMediaPath("2639_CALM/Visuals", "2639_Calm_30s_1920x1080_proresHQ.00_00_00_00.Still003.jpg"),
          getMediaPath("2639_CALM/Visuals", "2639_Calm_30s_1920x1080_proresHQ_V1.mp4"),
          getMediaPath("2639_CALM/Visuals", "2639_Calm_30s_1920x1080_proresHQ_V2.mp4"),
          getMediaPath("2639_CALM/Visuals", "Capture d’écran 2024-10-31 à 14.45.35.jpg"),
        ],
      },
      {
        id: 60,
        master: 2640,
        vimeoId: 1029340773,
        projectDir: "2640_LOUBOUTIN_FLUO",
        customer: "LOUBOUTIN",
        name: "Holiday Season 2019",
        production: "PSYCHO",
        director: ["FELIX COOPER"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2640_LOUBOUTIN_FLUO", "mosaic.jpg"),
        video: getMediaPath("2640_LOUBOUTIN_FLUO", "191108_LOUBOUTIN_60s_MIxWeb_1920x1080_H264.mp4"),
        XSvideo: getMediaPath("2640_LOUBOUTIN_FLUO", "191108_LOUBOUTIN_60s_MIxWeb_1920x1080_H264.mp4"),
        year: "2019",
        description: {
          en: "It's almost time for the curtains to go up. Get ready to shine in your own production of the Holiday Season.",
          fr: "Il est presque temps de lever le rideau. Préparez-vous à briller dans votre propre production des fêtes de fin d'année."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Stéphane Pelissier"],
          ["FLAME ARTIST", "Christophe Richard, Léo Verdier"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2640_LOUBOUTIN_FLUO/Visuals", "191108_LOUBOUTIN_60s_MIxWeb_1920x1080_proresHQ.00_00_12_12.Still001.jpg"),
          getMediaPath("2640_LOUBOUTIN_FLUO/Visuals", "191108_LOUBOUTIN_60s_MIxWeb_1920x1080_proresHQ_V1.mp4"),
          getMediaPath("2640_LOUBOUTIN_FLUO/Visuals", "191108_LOUBOUTIN_60s_MIxWeb_1920x1080_proresHQ_V2.mp4"),
          getMediaPath("2640_LOUBOUTIN_FLUO/Visuals", "Capture d’écran 2024-10-31 à 14.58.16.jpg"),
          getMediaPath("2640_LOUBOUTIN_FLUO/Visuals", "Capture d’écran 2024-10-31 à 15.03.25.jpg"),
          getMediaPath("2640_LOUBOUTIN_FLUO/Visuals", "Capture d’écran 2024-10-31 à 14.53.17.jpg"),
        ],
      },
      {
        id: 61,
        master: 2653,
        vimeoId: 1029341252,
        projectDir: "2653_ANTIDOTE_PRINTEMPS_COM",
        customer: "PRINTEMPS",
        name: "PRINTEMPS.COM",
        production: "ANTIDOTE",
        director: ["YANN WEBER"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2653_ANTIDOTE_PRINTEMPS_COM", "mosaic_Capture d’écran 2024-11-12 à 16.44.34.jpg"),
        video: getMediaPath("2653_ANTIDOTE_PRINTEMPS_COM", "200120_Printemps_30s_VI_1080x1920_proresHQ.mp4"),
        XSvideo: getMediaPath("2653_ANTIDOTE_PRINTEMPS_COM", "200120_Printemps_30s_VI_1080x1920_proresHQ.mp4"),
        year: "2020",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "JP Mure"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2653_ANTIDOTE_PRINTEMPS_COM/Visuals", "Capture d’écran 2024-10-31 à 15.06.50.jpg"),
          getMediaPath("2653_ANTIDOTE_PRINTEMPS_COM/Visuals", "Capture d’écran 2024-10-31 à 15.08.39.jpg"),
          getMediaPath("2653_ANTIDOTE_PRINTEMPS_COM/Visuals", "Capture d’écran 2024-10-31 à 15.09.29.jpg"),
          getMediaPath("2653_ANTIDOTE_PRINTEMPS_COM/Visuals", "Capture d’écran 2024-10-31 à 15.10.31.jpg"),
          getMediaPath("2653_ANTIDOTE_PRINTEMPS_COM/Visuals", "Capture d’écran 2024-10-31 à 15.11.07.jpg"),
          getMediaPath("2653_ANTIDOTE_PRINTEMPS_COM/Visuals", "Capture d’écran 2024-11-12 à 16.46.53.jpg"),
        ],
      },
      {
        id: 62,
        master: 2670,
        vimeoId: 396974733,
        projectDir: "2670_BURBERRY_ANTIDOTE",
        customer: "PRINTEMPS X BURBERRY",
        name: "BURBERRY LOVES PRINTEMPS",
        production: "ANTIDOTE",
        director: ["YANN WEBER"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2670_BURBERRY_ANTIDOTE", "mosaic_Capture d’écran 2024-10-31 à 15.16.34.jpg"),
        video: getMediaPath("2670_BURBERRY_ANTIDOTE", "ANTIDOTE_PRINTEMPS_BURBE_MASTER_02_h264.mp4"),
        XSvideo: getMediaPath("2670_BURBERRY_ANTIDOTE", "ANTIDOTE_PRINTEMPS_BURBE_MASTER_02_h264.mp4"),
        year: "2020",
        description: {
          en: "From February 24 to August 23, 2020, the English fashion house founded in 1856 by Thomas Burberry will take up residence at Printemps Haussmann. It's an opportunity to take over the atrium and windows of the Parisian department store, for which it has designed exclusive pieces - and to offer an immersive experience around its new Spring-Summer 2020 collection. Starring french music artist Shy.",
          fr: "Du 24 février au 23 août 2020, la maison anglaise fondée en 1856 par Thomas Burberry prend ses quartiers au Printemps Haussmann. L'occasion pour elle d'investir l'atrium et les vitrines du grand magasin parisien, pour lequel elle a imaginé des pièces exclusives - et de proposer une expérience immersive autour de sa nouvelle collection Printemps-Été 2020. Avec la chanteuse Shy."
        },
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "JP Mure"],
          ["VFX SUPERVISOR", "Serge Miot"],
          ["AFTER EFFECTS", "Nemo Rota"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2670_BURBERRY_ANTIDOTE/Visuals", "ANTIDOTE_PRINTEMPS_BURBE_MASTER_02_proresHQ_V1.mp4"),
          getMediaPath("2670_BURBERRY_ANTIDOTE/Visuals", "ANTIDOTE_PRINTEMPS_BURBE_MASTER_02_proresHQ.00_00_38_18.Still003.jpg"),
          getMediaPath("2670_BURBERRY_ANTIDOTE/Visuals", "ANTIDOTE_PRINTEMPS_BURBE_MASTER_02_proresHQ_V2.mp4"),
          getMediaPath("2670_BURBERRY_ANTIDOTE/Visuals", "Capture d’écran 2024-10-31 à 15.14.15.jpg"),
          getMediaPath("2670_BURBERRY_ANTIDOTE/Visuals", "Capture d’écran 2024-10-31 à 15.14.49.jpg"),
          getMediaPath("2670_BURBERRY_ANTIDOTE/Visuals", "Capture d’écran 2024-10-31 à 15.17.10.jpg"),
        ],
      },
      {
        id: 63,
        master: 2675,
        vimeoId: 1031481640,
        projectDir: "2675_HERMES_ARTISAN",
        customer: "HERMES",
        name: "ARTISAN DES LUMIÈRES",
        production: "PSYCHO",
        director: ["ANDRE CHEMETOFF"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2675_HERMES_ARTISAN", "mosaic_Hermes_Grand_Jeté_VI_30s_horizontal_prores.00_00_08_16.Still006.jpg"),
        video: getMediaPath("2675_HERMES_ARTISAN", "Hermes_Grand_Jeté_VF_30s_horizontal_h264.mp4"),
        XSvideo: getMediaPath("2675_HERMES_ARTISAN/9_16", "Hermes_Grand_Jeté_VF_30s_vertical_h264.mp4"),
        year: "2020",
        description: {
          en: "From line comes light: Hermès jewelry is a jewelry of form. It sculpts the material, reveals the brilliance, illuminates the precious stone. A jewellery tradition reinvented in the service of creation.",
          fr: "De la ligne, naît la lumière : la joaillerie Hermès est une joaillerie de la forme. C’est elle qui sculpte la matière, elle qui révèle l’éclat, elle qui éclaire la pierre précieuse. Une tradition joaillière réinventée au service de la création."
        },
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "Léo Gatelier"],
          ["2D ARTIST/AFTER EFFECTS", "Serge Miot"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2675_HERMES_ARTISAN/Visuals", "Hermes_Grand_Jeté_VI_30s_horizontal_prores.00_00_15_00.Still003.jpg"),
          getMediaPath("2675_HERMES_ARTISAN/Visuals", "Hermes_Grand_Jeté_VI_30s_horizontal_prores00_00_04_01Still004.jpg"),
          getMediaPath("2675_HERMES_ARTISAN/Visuals", "Hermes_Grand_Jeté_VI_30s_horizontal_prores.00_00_06_08.Still005.jpg"),
          getMediaPath("2675_HERMES_ARTISAN/Visuals", "Hermes_Grand_Jeté_VI_30s_horizontal_prores_V1.mp4"),
          getMediaPath("2675_HERMES_ARTISAN/Visuals", "Hermes_Grand_Jeté_VI_30s_horizontal_prores_V2.mp4"),
        ],
      },
      {
        id: 64,
        master: 2714,
        vimeoId: 1031483462,
        projectDir: "2714_LOUBOTIN_SHARK",
        customer: "LOUBOUTIN",
        name: "LOUBISHARK",
        production: "",
        director: ["FELIX COOPER"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2714_LOUBOTIN_SHARK", "mosaic_200805_LOUBOUTIN_60s_V6_16x9_mix02_proresHQ.00_00_54_23.Still003.jpg"),
        video: getMediaPath("2714_LOUBOTIN_SHARK", "200805_LOUBOUTIN_60s_V6_16x9_mix02_proresHQ.mp4"),
        XSvideo: getMediaPath("2714_LOUBOTIN_SHARK/9_16", "200805_LOUBOUTIN_60s_V6_9x16_mix02_proresHQ.mp4"),
        year: "2020",
        description: {
          en: "Watch out! The Loubishark is coming for you - or is it the other way around?",
          fr: "Donnez du mordant à votre look ! Audacieuse avec son jeu d'association de matières, la sneaker Loubishark ne passera pas inaperçue…"
        },
        credits: [
          ["POST-PRODUCER(S)", "Jean-Marc Raygade, Lison Muh-Salaun"],
          ["EDITOR", "Stéphanie Pelissier"],
          ["FLAME ARTISTS", "François Gilguy, Damien Peiro, Christophe Richard, Léo Verdier"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2714_LOUBOTIN_SHARK/Visuals", "200805_LOUBOUTIN_60s_V6_16x9_mix02_proresHQ.00_00_46_13.Still002.jpg"),
          getMediaPath("2714_LOUBOTIN_SHARK/Visuals", "200805_LOUBOUTIN_60s_V6_16x9_mix02_proresHQ_V1.mp4"),
          getMediaPath("2714_LOUBOTIN_SHARK/Visuals", "200805_LOUBOUTIN_60s_V6_16x9_mix02_proresHQ_V2.mp4"),
          getMediaPath("2714_LOUBOTIN_SHARK/Visuals", "Capture d’écran 2024-10-31 à 15.28.05.jpg"),
          getMediaPath("2714_LOUBOTIN_SHARK/Visuals", "200805_LOUBOUTIN_60s_V6_16x9_mix02_proresHQ.00_00_23_23.Still001.jpg"),
          getMediaPath("2714_LOUBOTIN_SHARK/Visuals", "Capture d’écran 2024-10-31 à 15.26.54.jpg"),
        ],
      },
      {
        id: 65,
        master: 2717,
        vimeoId: 1031484001,
        projectDir: "2717_LA_FEMME_CLIPS",
        customer: "LA FEMME",
        name: "PARADIGMES",
        production: "Disque Pointu & WACKO",
        director: ["Aymeric Bergada Du Cadet"],
        tags: ["Clips"],
        mosaicImg: getMediaPath("2717_LA_FEMME_CLIPS", "mosaic_200908_Paradigme_v12_TCDEF_V06_proresHQ.00_01_35_06.Still003.jpg"),
        video: getMediaPath("2717_LA_FEMME_CLIPS", "200908_Paradigme_v12_TCDEF_V06_proresHQ.mp4"),
        XSvideo: getMediaPath("2717_LA_FEMME_CLIPS", "200908_Paradigme_v12_TCDEF_V06_proresHQ.mp4"),
        year: "2020",
        description: {
          en: "" /* "To the question “What is Man's biggest problem?”, La Femme attempts to answer with Disconnexion. Co-directed by Aymeric Bergada du Cadet, the clip immerses us into a parallel dimension stirred by the fantasy of the disco years." */,
          fr: "" /* "À la question « Quel est le plus grand problème de l’Homme ? », La Femme tente de répondre avec Disconnexion. Le clip co-réalisé par Aymeric Bergada du Cadet nous plonge dans une dimension parallèle agitée par le fantasme des années disco." */
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Etienne Penin"],
          ["VFX", "Serge Miot, François Gilguy, Yann Masson"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2717_LA_FEMME_CLIPS/Visuals", "Capture d’écran 2024-12-17 à 12.30.14.jpg"),
          getMediaPath("2717_LA_FEMME_CLIPS/Visuals", "Capture d’écran 2024-12-17 à 12.32.00.jpg"),
          getMediaPath("2717_LA_FEMME_CLIPS/Visuals", "AFGH200908_Paradigme_v12_TCDEF_V06_proresHQ.00_03_32_02.Still004.jpg"),
          getMediaPath("2717_LA_FEMME_CLIPS/Visuals", "Capture d’écran 2024-12-17 à 12.36.04.jpg"),
          getMediaPath("2717_LA_FEMME_CLIPS/Visuals", "Capture d’écran 2024-12-17 à 12.37.02.jpg"),
        ],
      },
      {
        id: 66,
        master: 2723,
        vimeoId: 1031484744,
        projectDir: "2723_HYERES_2020",
        customer: "VILLA NOAILLES",
        name: "FESTIVAL D'HYÈRES 2020",
        production: "U-TURN",
        director: ["BLOOM"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2723_HYERES_2020", "mosaic_Capture d’écran 2024-10-31 à 15.47.33.jpg"),
        video: getMediaPath("2723_HYERES_2020", "Hyeres_Teaser_19s_ProresHQ.mp4"),
        XSvideo: getMediaPath("2723_HYERES_2020", "Hyeres_Teaser_19s_ProresHQ.mp4"),
        year: "2020",
        description: {
          en: "Music and Choreography by Roméo Agid",
          fr: "Musique et Choreographie de Roméo Agid"
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITORS", "JP Mure, Adrien Franke"],
          ["FLAME ARTIST", "François Gilguy"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2723_HYERES_2020/Visuals", "Hyeres_v07_FLAME_vi_V2.mp4"),
          getMediaPath("2723_HYERES_2020/Visuals", "Hyeres_v07_FLAME_vi_V1.mp4"),
          getMediaPath("2723_HYERES_2020/Visuals", "Capture d’écran 2024-10-31 à 15.46.38.jpg"),
          getMediaPath("2723_HYERES_2020/Visuals", "Capture d’écran 2024-10-31 à 15.47.02.jpg"),
          getMediaPath("2723_HYERES_2020/Visuals", "Capture d’écran 2024-10-31 à 15.49.08.jpg"),
        ],
      },
      {
        id: 67,
        master: 2728,
        vimeoId: 1031486063,
        projectDir: "2728_ANTIDOTE_JURA",
        customer: "PRINTEMPS",
        name: "JURA",
        production: "ANTIDOTE",
        director: ["YANN WEBER"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2728_ANTIDOTE_JURA", "mosaic_Capture d’écran 2024-10-31 à 15.56.08.jpg"),
        video: getMediaPath("2728_ANTIDOTE_JURA", "201023_Printemps_Jura_10s_B_TC_16x9_proresHQ.mp4"),
        XSvideo: getMediaPath("2728_ANTIDOTE_JURA", "201023_Printemps_Jura_10s_B_TC_16x9_proresHQ.mp4"),
        year: "2020",
        description: {"en": "", "fr": ""},
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "JP Mure"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2728_ANTIDOTE_JURA/Visuals", "201023_Printemps_Jura_10s_B_TC_16x9_proresHQ.00_00_00_00.Still001.jpg"),
          getMediaPath("2728_ANTIDOTE_JURA/Visuals", "Capture d’écran 2024-12-17 à 12.39.32.jpg"),
          getMediaPath("2728_ANTIDOTE_JURA/Visuals", "Capture d’écran 2024-12-17 à 12.40.35.jpg"),
          getMediaPath("2728_ANTIDOTE_JURA/Visuals", "Capture d’écran 2024-12-17 à 12.41.44.jpg"),
          getMediaPath("2728_ANTIDOTE_JURA/Visuals", "Capture d’écran 2024-12-17 à 12.42.17.jpg"),
        ],
      },
      {
        id: 68,
        master: 2757,
        vimeoId: 1031486422,
        projectDir: "2757_LOUBOUTIN_PASTEL",
        customer: "LOUBOUTIN",
        name: "PASTEL",
        production: "PSYCHO",
        director: ["FELIX COOPER"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2757_LOUBOUTIN_PASTEL", "mosaic_Capture d’écran 2024-10-31 à 16.08.31.jpg"),
        video: getMediaPath("2757_LOUBOUTIN_PASTEL", "Louboutin_Pastel_55s_v06_210127_V02_9x16.mp4"),
        XSvideo: getMediaPath("2757_LOUBOUTIN_PASTEL", "Louboutin_Pastel_55s_v06_210127_V02_9x16.mp4"),
        year: "2021",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["FLAME ARTISTS", "François Gilguy, Damien Peiro"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2757_LOUBOUTIN_PASTEL/Visuals", "Louboutin_Pastel_55s_v06_210127_V02_16x9.00_00_27_11.Still002.jpg"),
          getMediaPath("2757_LOUBOUTIN_PASTEL/Visuals", "Louboutin_Pastel_55s_v06_210127_V02_16x9.00_00_43_24.Still003.jpg"),
          getMediaPath("2757_LOUBOUTIN_PASTEL/Visuals", "Louboutin_Pastel_55s_v06_210127_V02_16x9_V2.mp4"),
          getMediaPath("2757_LOUBOUTIN_PASTEL/Visuals", "Louboutin_Pastel_55s_v06_210127_V02_16x9_V1.mp4"),
          getMediaPath("2757_LOUBOUTIN_PASTEL/Visuals", "Capture d’écran 2024-10-31 à 16.07.40.jpg"),
          getMediaPath("2757_LOUBOUTIN_PASTEL/Visuals", "mosaic_Capture d’écran 2024-10-31 à 16.08.52.jpg"),
        ],
      },
      {
        id: 69,
        master: 2762,
        vimeoId: 1031486826,
        projectDir: "2762_PERFECT_MAG",
        customer: "PERFECT MAGAZINE",
        name: "COUTURE",
        production: "PSYCHO",
        director: ["FELIX COOPER"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2762_PERFECT_MAG", "mosaic_Capture d’écran 2024-11-12 à 13.50.32.jpg"),
        video: getMediaPath("2762_PERFECT_MAG", "210222_Perfect_Mag_49s_v06_WIP02_16x9_proresHQ.mp4"),
        XSvideo: getMediaPath("2762_PERFECT_MAG/9_16", "210224_Perfect_Mag_49s_V3_9x16.mp4"),
        year: "2021",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2762_PERFECT_MAG/Visuals", "210222_Perfect_Mag_49s_v06_WIP02_16x9_proresHQ.00_00_33_01.Still003.jpg"),
          getMediaPath("2762_PERFECT_MAG/Visuals", "210222_Perfect_Mag_49s_v06_WIP02_16x9_proresHQ_V1.mp4"),
          getMediaPath("2762_PERFECT_MAG/Visuals", "210222_Perfect_Mag_49s_v06_WIP02_16x9_proresHQ_V2.mp4"),
          getMediaPath("2762_PERFECT_MAG/Visuals", "Capture d’écran 2024-10-31 à 16.14.33.jpg"),
          getMediaPath("2762_PERFECT_MAG/Visuals", "Capture d’écran 2024-10-31 à 16.14.59.jpg"),
          getMediaPath("2762_PERFECT_MAG/Visuals", "Capture d’écran 2024-10-31 à 16.13.50.jpg"),
        ],
      },
      {
        id: 70,
        master: 2766,
        vimeoId: 1031487185,
        projectDir: "2766_CARTIER_VEGAN_TANK",
        customer: "CARTIER",
        name: "TANK SOLAR BEAT",
        production: "WACKO",
        agency: "PUBLICIS LUXE",
        director: ["MAXIME DURAND"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2766_CARTIER_VEGAN_TANK", "mosaic_Capture d’écran 2024-10-31 à 16.19.27.jpg"),
        video: getMediaPath("2766_CARTIER_VEGAN_TANK", "210401_CARTIER_TANK_LONG_9-16_MASTER_VI.mp4"),
        XSvideo: getMediaPath("2766_CARTIER_VEGAN_TANK", "210401_CARTIER_TANK_LONG_9-16_MASTER_VI.mp4"),
        year: "2021",
        description: {
          en: "",
          fr: ""
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["VFX", "Sylvery Bolotte, Edin Hadzic, Johann Roche"],
          ["FLAME ARTIST", "Sylvian Fabre"],
        ],
        visuals: [
          getMediaPath("2766_CARTIER_VEGAN_TANK/Visuals", "210401_CARTIER_TANK_LONG_16-9_MASTER_vi.00_00_13_12.Still001.jpg"),
          getMediaPath("2766_CARTIER_VEGAN_TANK/Visuals", "210401_CARTIER_TANK_LONG_16-9_MASTER_vi.00_00_16_01.Still002.jpg"),
          getMediaPath("2766_CARTIER_VEGAN_TANK/Visuals", "210401_CARTIER_TANK_LONG_16-9_MASTER_vi.00_00_22_01.Still003.jpg"),
          getMediaPath("2766_CARTIER_VEGAN_TANK/Visuals", "210401_CARTIER_TANK_LONG_16-9_MASTER_vi_V1.mp4"),
          getMediaPath("2766_CARTIER_VEGAN_TANK/Visuals", "210401_CARTIER_TANK_LONG_16-9_MASTER_vi_V2.mp4"),
        ],
      },
      {
        id: 71,
        master: 2771,
        vimeoId: 1031488708,
        projectDir: "2771_DOM_PERIGNON",
        customer: "DOM PERIGNON",
        name: "VINTAGE 2012 : EXPLOSIVE HARMONY",
        production: "VINGT VINGT-QUATRE",
        director: ["YVES BOSSON, SYLVERY BOLOTTE"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2771_DOM_PERIGNON", "mosaic.jpg"),
        video: getMediaPath("2771_DOM_PERIGNON", "DPV_EDIT_VLONG_53s_v25A_graded_mix_211012_16x9_VI.mp4"),
        XSvideo: getMediaPath("2771_DOM_PERIGNON/9_16", "DPV_EDIT_VLONG_53s_v25A_graded_mix_211012_9x16_VI.mp4"),
        year: "2021",
        description: {
          en: "Listen. Converse with nature. Compose tensions that harmonise. Introducing Dom Pérignon Vintage 2012: a story of explosive harmony",
          fr: "Écouter. Dialoguer avec la nature. Composer des tensions qui s'harmonisent. Présentation de Dom Pérignon Vintage 2012 : un équilibre paradoxal"
        },
        credits: [
          ["POST-PRODUCER", "Clément Du Passage"],
          ["EDITOR", "JP Mure, Fabrice Minier"],
          ["DEVELOPER", "François Morvillier"],
          ["AFTER EFFECTS ARTISTS", "Serge Miot, Boris Coyere"],
          ["NUKE ARTISTS", "Alexis Amant, Edouard Koen, Diego Palacios, Fabrice Fernandez, Dan Rapaport"],
          ["3D ARTISTS", "Frederic Randuineau, Edin Hadzic, Max Pasquiou, Pierre Carcedo"],
          ["GRADING", "Sylvain Canaux"]
        ],
        visuals: [
          getMediaPath("2771_DOM_PERIGNON/Visuals", "DPV_EDIT_VLONG_53s_v25A_graded_mix_211012_16x9_VI.00_00_46_17.Still001.jpg"),
          getMediaPath("2771_DOM_PERIGNON/Visuals", "DPV_EDIT_VLONG_53s_v25A_graded_mix_211012_16x9_VI_V1.mp4"),
          getMediaPath("2771_DOM_PERIGNON/Visuals", "DPV_EDIT_VLONG_53s_v25A_graded_mix_211012_16x9_VI_V2.mp4"),
          getMediaPath("2771_DOM_PERIGNON/Visuals", "Capture d’écran 2024-11-12 à 14.05.09.jpg"),
          getMediaPath("2771_DOM_PERIGNON/Visuals", "Capture d’écran 2024-11-12 à 14.07.38.jpg"),
          getMediaPath("2771_DOM_PERIGNON/Visuals", "Capture d’écran 2024-11-12 à 14.03.41.jpg"),
        ],
      },
      {
        id: 72,
        master: 2776,
        vimeoId: 1031488906,
        projectDir: "2776_SCH",
        customer: "SCH",
        name: "Mannschaft (Feat. Freeze Corleone)",
        production: "PELICAN PARIS",
        director: ["Gregory Ohrel"],
        tags: ["Clips"],
        mosaicImg: getMediaPath("2776_SCH", "mosaic_Capture d’écran 2024-11-12 à 14.18.31.jpg"),
        video: getMediaPath("2776_SCH", "Mannschaft_v13_MASTER.mp4"),
        XSvideo: getMediaPath("2776_SCH", "Mannschaft_v13_MASTER.mp4"),
        year: "2021",
        description: {
          en: "",
          fr: ""
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Etienne Penin"],
          ["ASSISTANT EDITOR / CONFORMATION", "Adrien Franke"],
          ["VFX", "Serge Miot"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2776_SCH/Visuals", "Mannschaft_v13_MASTER.00_02_53_02.Still001.jpg"),
          getMediaPath("2776_SCH/Visuals", "Capture d’écran 2024-11-12 à 14.22.28.jpg"),
          getMediaPath("2776_SCH/Visuals", "Capture d’écran 2024-11-12 à 14.22.10.jpg"),
        ],
      },
      {
        id: 73,
        master: 2788,
        vimeoId: 1034454590,
        projectDir: "2788_Hermes_Joaillerie",
        customer: "HERMES",
        name: "HAUTE JOAILLERIE",
        production: "PSYCHO",
        agency: "PUBLICIS",
        director: ["SOLVE SUNDSBO","https://www.solvesundsbo.com/"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2788_Hermes_Joaillerie", "mosaic_2788.jpg"),
        video: getMediaPath("2788_Hermes_Joaillerie", "HERMES_KELLYGAVROCHE_15s_16_9_VF_nologo.mp4"),
        XSvideo: getMediaPath("2788_Hermes_Joaillerie", "HERMES_KELLYGAVROCHE_15s_16_9_VF_nologo.mp4"),
        year: "2022",
        description: {
          en: "",
          fr: ""
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Stéphanie Pélissier - CUTAWAY"],
          ["VFX", "Yann Masson, François Gilguy"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2788_Hermes_Joaillerie/Visuals", "HERMES_CHAINE-D-ANCRE_15s_16_9_VF_nologo.mp4"),
          getMediaPath("2788_Hermes_Joaillerie/Visuals", "Capture d’écran 2024-11-26.jpg"),
          getMediaPath("2788_Hermes_Joaillerie/Visuals", "HERMES_RESEAULUMIERE_15s_16_9_VF_nologo.mp4"),
          getMediaPath("2788_Hermes_Joaillerie/Visuals", "Capture d’écran 2024-11-26 à 12.30.21.jpg"),
          getMediaPath("2788_Hermes_Joaillerie/Visuals", "Capture d’écran 2024-11-26 à 12.31.20.jpg"),
        ],
      },
      {
        id: 74,
        master: 2794,
        vimeoId: 1031489756,
        projectDir: "2794_CARTIER_NOA",
        customer: "CARTIER",
        name: "DIABOLO DE CARTIER",
        production: "WACKO",
        director: ["METZ+RACINE"],
        tags: ["Luxe"],
        agency: "PUBLICIS LUXE",
        mosaicImg: getMediaPath("2794_CARTIER_NOA", "mosaic_Capture d’écran 2024-11-12 à 14.49.11.jpg"),
        video: getMediaPath("2794_CARTIER_NOA", "Dred_ProRes_211214_16-9.mp4"),
        XSvideo: getMediaPath("2794_CARTIER_NOA", "Dred_ProRes_211214_16-9.mp4"),
        year: "2024",
        description: {
          en: "",
          fr: ""
        },
        credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["CGI ARTISTS", "Edin Hadzic, Olivier Delequeuche"],
          ["VFX ANIMATORS", "Geoffrey Lerus, Marianne Cruchant, Marion Guichenuy"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2794_CARTIER_NOA/Visuals", "Dred_ProRes_211214_16-9.00_00_05_22.Still001.jpg"),
          getMediaPath("2794_CARTIER_NOA/Visuals", "Capture d’écran 2024-11-12 à 14.47.39.jpg"),
          getMediaPath("2794_CARTIER_NOA/Visuals", "Capture d’écran 2024-11-12 à 14.49.11 copie.jpg"),
        ],
      },
      {
        id: 75,
        master: 2795,
        vimeoId: 1031494820,
        projectDir: "2795_HERMES_DOMINOS",
        customer: "HERMES",
        name: "DOMINOS",
        production: "U-TURN",
        director: ["BLOOM"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2795_HERMES_DOMINOS", "mosaic_Capture d’écran 2024-11-12 à 14.51.03.jpg"),
        video: getMediaPath("2795_HERMES_DOMINOS", "Hermes_RAL_15s_v15_WIP01_vf_HD.mp4"),
        XSvideo: getMediaPath("2795_HERMES_DOMINOS", "Hermes_RAL_15s_v15_WIP01_vf_HD.mp4"),
        year: "2021",
        description: {
          en: "",
          fr: ""
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Etienne Penin"],
          ["VFX", "Yann Masson"],
          ["GRADING", "Sylvain Canaux "],
        ],
        visuals: [
          getMediaPath("2795_HERMES_DOMINOS/visuals", "Hermes_RAL_15s_v15_WIP01_vf_HD.00_00_04_14.Still001.jpg"),
          getMediaPath("2795_HERMES_DOMINOS/visuals", "Capture d’écran 2024-12-17 à 12.44.58.jpg"),
          getMediaPath("2795_HERMES_DOMINOS/visuals", "Capture d’écran 2024-12-17 à 12.46.34.jpg"),
        ],
      },
      {
        id: 76,
        master: 2799,
        vimeoId: 1031495266,
        projectDir: "2799_CLIP_ORELSAN",
        customer: "ORELSAN",
        name: "MILLIONS",
        production: "PELICAN PARIS",
        director: ["GREGORY ORHEL"],
        tags: ["Clips"],
        mosaicImg: getMediaPath("2799_CLIP_ORELSAN", "mosaic_Capture d’écran 2024-11-12 à 14.59.41.jpg"),
        video: getMediaPath("2799_CLIP_ORELSAN", "Millions_v11_2-35_WIP05_proresLT.mp4"),
        XSvideo: getMediaPath("2799_CLIP_ORELSAN", "Millions_v11_2-35_WIP05_proresLT.mp4"),
        year: "2021",
        description: {
          en: "Millions is a track from Orelsan's album No Limit, featuring Ninho.",
          fr: "Millions est un titre tiré de l'album No Limit d'Orelsan en featuring avec Ninho."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Etienne Penin"],
          ["VFX", "Serge Miot"],
          ["FLAME ARTIST", "Yann Masson, François Gilguy, Audrey Alcover Martinez, Christophe Croidieu, Duy Anh Nguyen"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2799_CLIP_ORELSAN/Visuals", "Capture d’écran 2024-11-12 à 15.01.38.jpg"),
          getMediaPath("2799_CLIP_ORELSAN/Visuals", "Capture d’écran 2024-11-12 à 15.02.40.jpg"),
          getMediaPath("2799_CLIP_ORELSAN/Visuals", "Capture d’écran 2024-11-12 à 15.07.40.jpg"),
        ],
      },
      {
        id: 77,
        master: 2804,
        vimeoId: 1031495659,
        projectDir: "2804_HUBLOT",
        customer: "HUBLOT",
        name: "Big Bang Unico Summer",
        production: "ADEUS",
        director: ["Adrien Langier & Ousmane Ly"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2804_HUBLOT", "mosaic.jpg"),
        video: getMediaPath("2804_HUBLOT", "210706_HUBLOT_40s_4-5_proreshq.mp4"),
        XSvideo: getMediaPath("2804_HUBLOT/9_16", "210706_HUBLOT_40s_9-16_proreshq.mp4"),
        year: "2021",
        description: {
          en: "It is the dazzling blues that nature creates, transformed by the summer light, and so hypnotic that they seem unique and impossible to reproduce, that we would most like to bring back with us. Through its expertise in materials technology, Hublot is immortalising this perfect simplicity with the creation of the Big Bang Unico Summer.",
          fr: "Ce sont les bleus éblouissants que la nature crée, transformés par la lumière de l'été, et si hypnotiques qu'ils semblent uniques et impossibles à reproduire, que l'on aimerait le plus ramener avec soi. Grâce à son expertise dans la technologie des matériaux, Hublot immortalise cette simplicité parfaite avec la création de la Big Bang Unico Summer."
        },
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "Adrien Franke"],
          ["VFX", "Sébastien Filinger"], 
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2804_HUBLOT/visuals", "210706_HUBLOT_40s_4-5_proreshq.00_00_20_06.Still001.jpg"),
          getMediaPath("2804_HUBLOT/visuals", "210706_HUBLOT_40s_4-5_proreshq.00_00_10_01.Still002.jpg"),
          getMediaPath("2804_HUBLOT/visuals", "210706_HUBLOT_40s_4-5_proreshq.00_00_35_08.Still003.jpg"),
        ],
      },
      {
        id: 78,
        master: 2806,
        vimeoId: 1031496139,
        projectDir: "2806_HERMES_NOEL",
        customer: "HERMES",
        name: "Joyeux Objets!",
        production: "Excuse My French",
        director: ["LUKE WHITE"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2806_HERMES_NOEL", "Capture d’écran 2024-11-12 à 15.18.42.jpg"),
        video: getMediaPath("2806_HERMES_NOEL", "Hermes_Noel_Film03_10s_v02_210727.mp4"),
        XSvideo: getMediaPath("2806_HERMES_NOEL", "Hermes_Noel_Film03_10s_v02_210727.mp4"),
        year: "2021",
        description: {
          en: "",
          fr: ""
        },
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "JP Mure"],
          ["GRADING", "Sylvain Canaux"]],
        visuals: [
          getMediaPath("2806_HERMES_NOEL/visuals", "Hermes_Noel_Film01_10s_v03_210727.00_00_04_05.Still001.jpg"),
          getMediaPath("2806_HERMES_NOEL/visuals", "Hermes_Noel_Film01_10s_v03_210727.00_00_14_04.Still003.jpg"),
          getMediaPath("2806_HERMES_NOEL/visuals", "Hermes_Noel_Film01_10s_v03_210727.00_00_23_18.Still004.jpg"),
          getMediaPath("2806_HERMES_NOEL/visuals", "Hermes_Noel_Film01_10s_v03_210727.00_00_28_05.Still005.jpg"),
          getMediaPath("2806_HERMES_NOEL/visuals", "Hermes_Noel_Film01_10s_v03_210727.00_00_34_23.Still006.jpg"),
          getMediaPath("2806_HERMES_NOEL/visuals", "Hermes_Noel_Film01_10s_v03_210727.mp4"),
        ],
      },
      {
        id: 79,
        master: 2917,
        vimeoId: 1031501237,
        projectDir: "2917_ANTIDOTE_LOOKS_2022",
        customer: "ANTIDOTE",
        name: "LOOKS 2022",
        production: "ANTIDOTE",
        director: ["YANN WEBER"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2917_ANTIDOTE_LOOKS_2022", "mosaic.jpg"),
        video: getMediaPath("2917_ANTIDOTE_LOOKS_2022", "Antidote_Looks_v02.mp4"),
        XSvideo: getMediaPath("2917_ANTIDOTE_LOOKS_2022", "Antidote_Looks_v02.mp4"),
        year: "2022",
        description: {
          en: "",
          fr: ""
        },
        credits: [
          ["GRADING", "Sylvain Canaux"]
        ],
        visuals: [
          getMediaPath("2917_ANTIDOTE_LOOKS_2022/Visuals", "Capture d’écran 2024-11-12 à 16.09.04.jpg"),
          getMediaPath("2917_ANTIDOTE_LOOKS_2022/Visuals", "Capture d’écran 2024-11-12 à 16.09.46.jpg"),
          getMediaPath("2917_ANTIDOTE_LOOKS_2022/Visuals", "mosaic_Capture d’écran 2024-11-12 à 16.06.50.jpg"),
          getMediaPath("2917_ANTIDOTE_LOOKS_2022/Visuals", "Capture d’écran 2024-12-17 à 13.41.47.jpg"),
          getMediaPath("2917_ANTIDOTE_LOOKS_2022/Visuals", "Capture d’écran 2024-12-17 à 13.42.10.jpg"),
        ],
      },
      {
        id: 80,
        master: 2953,
        vimeoId: 1031501602,
        projectDir: "2953_CHLOE_SEEDS_OF_HOPE",
        customer: "COTY",
        name: "CHLOÉ SEEDS OF HOPE",
        production: "PSYCHO",
        director: ["NEELS CASTILLON"],
        agency:"French Circus",
        tags: ["Beauty"],
        mosaicImg: getMediaPath("2953_CHLOE_SEEDS_OF_HOPE", "mosaic.jpg"),
        video: getMediaPath("2953_CHLOE_SEEDS_OF_HOPE", "CLO_EDPI_24_Main_Video_45sec_16x9_FR.mp4"),
        XSvideo: getMediaPath("2953_CHLOE_SEEDS_OF_HOPE/9_16", "CLO_EDPI_24_Main_Video_45sec_9x16_FR.mp4"),
        year: "2024",
        description: {
          en: "To express the spontaneous charm that characterizes the CHLOÉ style, perfumers Michel Almairac and Amandine Marie have created floral scents with round, velvety facets, embodying the free spirit and timeless elegance of the CHLOÉ woman. Editing : Sébastien Rouquet (Motion Palace), music : Chloé Thevenin.",
          fr: "Pour exprimer le charme spontané qui caractérise le style CHLOÉ, les parfumeurs Michel Almairac et Amandine Marie ont élaboré des senteurs florales aux facettes rondes et veloutées, qui incarnent l’esprit libre et l’élégance intemporelle de la femme CHLOÉ. Montage de Sébastien Rouquet (Motion Palace) Musique de Chloé Thevenin."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["CGI ARTIST", "Edin Hadzic, Olivier Delequeuche, Rémi Devouassoud"],
          ["FLAME ARTISTS", "Anita Lech, Hugos Soriano, François Gilguy, Benjamin Ravalec, Vincent Heine, Camille Miranda"],
          ["NUKE ARTIST", "Joahnn Roche"],
          ["COLOR GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2953_CHLOE_SEEDS_OF_HOPE/VISUALS", "CLO_EDPI_24_Main_Video_45sec_16x9_FR.00_00_13_08.Still002.jpg"),
          getMediaPath("2953_CHLOE_SEEDS_OF_HOPE/VISUALS", "CLO_EDPI_24_Main_Video_45sec_16x9_FR.00_00_08_18.Still001.jpg"),
          getMediaPath("2953_CHLOE_SEEDS_OF_HOPE/VISUALS", "CLO_EDPI_24_Main_Video_45sec_16x9_FR_01.mp4"),
          getMediaPath("2953_CHLOE_SEEDS_OF_HOPE/VISUALS", "Capture d’écran 2024-11-12 à 16.18.53.jpg"),
          getMediaPath("2953_CHLOE_SEEDS_OF_HOPE/VISUALS", "CLO_EDPI_24_Main_Video_45sec_16x9_FR_02.mp4"),
          getMediaPath("2953_CHLOE_SEEDS_OF_HOPE/VISUALS", "CLO_EDPI_24_Main_Video_45sec_16x9_FR_04.mp4"),
        ],
      },
      {
        id: 81,
        master: 2825,
        vimeoId: 1031507912,
        projectDir: "2825_FESTIVAL_HYERES_2021",
        customer: "VILLA NOAILLES",
        name: "36ÈME FESTIVAL D'HYÈRES",
        production: "U-TURN",
        director: ["Genial Pictures"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2825_FESTIVAL_HYERES_2021", "mosaic_Capture d’écran 2024-11-12 à 15.23.51.jpg"),
        video: getMediaPath("2825_FESTIVAL_HYERES_2021", "211012_FESTIVAL_HYERES_MASTER.mp4"),
        XSvideo: getMediaPath("2825_FESTIVAL_HYERES_2021", "211012_FESTIVAL_HYERES_MASTER.mp4"),
        year: "2021",
        description: {
          en: "",
          fr: ""
        },
        credits: [
          ["POST-PRODUCER", "Clément Du Passage"],
          ["EDITOR", "Adrien Franke"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2825_FESTIVAL_HYERES_2021/Visuals", "Capture d’écran 2024-11-12 à 15.25.37.jpg"),
          getMediaPath("2825_FESTIVAL_HYERES_2021/Visuals", "Capture d’écran 2024-11-12 à 15.26.39.jpg"),
          getMediaPath("2825_FESTIVAL_HYERES_2021/Visuals", "Capture d’écran 2024-11-12 à 15.27.09.jpg"),
          getMediaPath("2825_FESTIVAL_HYERES_2021/Visuals", "Capture d’écran 2024-11-12 à 15.27.41.jpg"),
          getMediaPath("2825_FESTIVAL_HYERES_2021/Visuals", "Capture d’écran 2024-11-12 à 15.28.30.jpg"),
        ],
      },
      {
        id: 82,
        master: 2828,
        vimeoId: 1031508356,
        projectDir: "2828_CLIP_FISHBACH",
        customer: "FISHBACH",
        name: "Masque d'Or",
        production: "WACKO",
        year:"2021",
        director: ["Aymeric Bergada Du Cadet"],
        tags: ["Clips"],
        mosaicImg: getMediaPath("2828_CLIP_FISHBACH", "mosaic_Capture d’écran 2024-11-12 à 15.45.25.jpg"),
        video: getMediaPath("2828_CLIP_FISHBACH", "MasqueDor_v05_CONFO_MASTER_def.mp4"),
        XSvideo: getMediaPath("2828_CLIP_FISHBACH", "MasqueDor_v05_CONFO_MASTER_def.mp4"),
        description: {
          en: "With the vintage and irresistible “Masque d'or”, Fishback  goes back to the 80's and kicks off the release of her second album for February 2022, Avec les yeux. For her inevitably danceable and golden clip, in which she sports a new look and dances around a golden ladder, Fishbach called on director Aymeric Bergada du Cadet, a loyal collaborator of La Femme.”",
          fr: "Avec le vintage et irrésistible 'Masque d'or', l'artiste Fishback remonte dans les années 80 et amorce l'arrivée de son deuxième album pour février 2022, Avec les yeux. Pour son clip forcément dansant et doré, dans lequel elle arbore un nouveau look et danse autour d'une échelle en or, Fishbach a fait appel au réalisateur Aymeric Bergada du Cadet, fidèle collaborateur de La Femme."
        },
        credits: [
          ["EDITOR", "Étienne Penin"],
        ],
        visuals: [
          getMediaPath("2828_CLIP_FISHBACH/Visuals", "Capture d’écran 2024-11-12 à 15.42.57.jpg"),
          getMediaPath("2828_CLIP_FISHBACH/Visuals", "Capture d’écran 2024-11-12 à 15.44.00.jpg"),
          getMediaPath("2828_CLIP_FISHBACH/Visuals", "Capture d’écran 2024-11-12 à 15.45.55.jpg"),
        ],
      },
      {
        id: 83,
        master: 3107,
        vimeoId: 1039694498,
        projectDir: "3107_Sailed",
        customer: "KIASMOS",
        name: "SAILED",
        production: "MOTION PALACE",
        director: ["NEELS CASTILLON"],
        tags: ["Clips"],
        mosaicImg: getMediaPath("3107_Sailed", "mosaic_Capture d’écran 2024-12-10 à 12.46.29.jpg"),
        year: "2024",
        description: {
          en: "A music video selected as runner-up for this category at the FilmLight Awards 2024. Director of photography : Éric Blanckaert. Post production : Monumental",
          fr: "Un clip musical sélectionné en deuxième place pour l'étalonnage de cette catégorie aux FilmLight Awards 2024. Directeur de la photographie : Éric Blanckaert. Post production : Monumental"
        },
        credits: [
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("3107_Sailed/Visuals", "Capture d’écran 2024-12-10 à 12.33.57.jpg"),
          getMediaPath("3107_Sailed/Visuals", "Capture d’écran 2024-12-10 à 12.40.56.jpg"),
          getMediaPath("3107_Sailed/Visuals", "Capture d’écran 2024-12-10 à 12.38.08.jpg"),
          getMediaPath("3107_Sailed/Visuals", "Capture d’écran 2024-12-10 à 12.40.16.jpg"),
          getMediaPath("3107_Sailed/Visuals", "Capture d’écran 2024-12-10 à 12.36.54.jpg"),
          getMediaPath("3107_Sailed/Visuals", "Capture d’écran 2024-12-10 à 12.38.50.jpg"),
        ],
      },
      {
        id: 84,
        master: 2884,
        vimeoId: 1031510019,
        projectDir: "2884_CARTIER_NOA_CANDLE_MACHINE",
        customer: "CARTIER",
        name: "CARTIER CANDLE MACHINE",
        production: "WACKO",
        director: ["METZ+RACINE"],
        tags: ["Luxe"],
        agency: "PUBLICIS LUXE",
        mosaicImg: getMediaPath("2884_CARTIER_NOA_CANDLE_MACHINE", "mosaic.jpg"),
        video: getMediaPath("2884_CARTIER_NOA_CANDLE_MACHINE", "220908_CARTIER_NOA_CANDLE_FILM2_v2_9x16.mp4"),
        XSvideo: getMediaPath("2884_CARTIER_NOA_CANDLE_MACHINE", "220908_CARTIER_NOA_CANDLE_FILM2_v2_9x16.mp4"),
        year: "2024",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["CGI ARTISTS", "Edin Hadzic, Olivier Delequeuche"],
          ["VFX ANIMATORS", "Geoffrey Lerus, Marianne Cruchant, Marion Guichenuy"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2884_CARTIER_NOA_CANDLE_MACHINE/Visuals", "Capture d’écran 2024-11-12 à 16.00.58.jpg"),
          getMediaPath("2884_CARTIER_NOA_CANDLE_MACHINE/Visuals", "Capture d’écran 2024-11-12 à 15.59.14.jpg"),
          getMediaPath("2884_CARTIER_NOA_CANDLE_MACHINE/Visuals", "Capture d’écran 2024-11-12 à 16.03.13.jpg"),
        ],
      },
      {
        id: 85,
        master: 3041,
        vimeoId: 1031517768,
        projectDir: "3041_NINARICCI",
        customer: "Nina Ricci",
        name: "Eyewear",
        production: "",
        director: ["Léo Haddad"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("3041_NINARICCI", "mosaic_Capture d’écran 2024-11-19 à 13.47.37.jpg"),
        video: getMediaPath("3041_NINARICCI", "231218_NinaRicci_24s_VA_1-1_DEF.mp4"),
        XSvideo: getMediaPath("3041_NINARICCI", "231218_NinaRicci_24s_VA_1-1_DEF.mp4"),
        year: "2024",
        description: {
          en: "The Nina Ricci sunglasses collection combines elegant frames with the bold spirit of the Maison in a selection of models that play with colors and geometric shapes for a style that is both unique and timeless.", 
          fr: "La collection de lunettes de soleil Nina Ricci associe des montures élégantes à l’esprit audacieux de la Maison dans une sélection de modèles qui jouent avec les couleurs et les formes géométriques pour un style à la fois unique et intemporel."
        },
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "JP Mure"],
          ["AFTER EFFECTS", "Seb Filinger"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("3041_NINARICCI/Visuals", "Capture d’écran 2024-11-19 à 13.48.10.jpg"),
          getMediaPath("3041_NINARICCI/Visuals", "Capture d’écran 2024-11-19 à 13.50.50.jpg"),
          getMediaPath("3041_NINARICCI/Visuals", "Capture d’écran 2024-11-19 à 14.06.33.jpg"),
          getMediaPath("3041_NINARICCI/Visuals", "Capture d’écran 2024-11-19 à 18.01.38.jpg"),
          getMediaPath("3041_NINARICCI/Visuals", "Capture d’écran 2024-11-19 à 18.03.00.jpg"),
        ],
      },
      {
        id: 86,
        master: 3054,
        vimeoId: 1031518702,
        projectDir: "3054_GIVENCHY",
        customer: "GIVENCHY",
        name: "L'INTERDIT",
        production: "SMUGGLER",
        agency: "BETC ÉTOILE ROUGE",
        director: ["JOE WRIGHT","https://en.wikipedia.org/wiki/Joe_Wright"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("3054_GIVENCHY", "mosaic_LINTERDIT_60s_16x9_SOLO_master.00_00_46_02.Still001.jpg"),
        video: getMediaPath("3054_GIVENCHY", "LINTERDIT_60s_16x9_DUO_master_VI.MP4"),
        XSvideo: getMediaPath("3054_GIVENCHY", "LINTERDIT_60s_16x9_DUO_master_VI.MP4"),
        year: "2024",
        description: {
          en: `L'Interdit, Givenchy's new fragrance for women with Rooney Mara.
Feel the thrill of the forbidden. Its fragrance releases a white flower crossed by black notes, for a luminous femininity that flirts with darkness.`, 
          fr: `L'Interdit, le nouveau parfum féminin signé Givenchy avec Rooney Mara.
Ressentez le frisson de l'interdit. Sa fragrance libère une fleur blanche traversée de notes noires, pour une féminité lumineuse qui flirte avec l"obscurité.`
        },
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],     
          ["EDITOR", "Struan Clay"],
          ["FLAME", "Hugo Soriano, Camille Miranda, François Gilguy, Vincent Heine"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["VFX", "Sylvery Bolotte, Edin Hadzic, Olivier Delequeuche"],
          ["AFTER EFFECTS","Sébastien Filinger"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("3054_GIVENCHY/Visuals", "visual1.jpg"),
          getMediaPath("3054_GIVENCHY/Visuals", "visual2.jpg"),
          getMediaPath("3054_GIVENCHY/Visuals", "visual3.jpg"),
          getMediaPath("3054_GIVENCHY/Visuals", "visual4.jpg"),
          getMediaPath("3054_GIVENCHY/Visuals", "visual5.jpg"),
        ],
      },
      {
        id: 87,
        master: 3080,
        vimeoId: 1031519548,
        projectDir: "3080_SOLEIA",
        customer: "SOLEÏLA",
        name: "UN COUP DE SOLEÏLA",
        production: "EXCUSE MY FRENCH",
        director: ["KINGA BURZA","https://www.kingaburza.com/"],
        tags: ["Clips"],
        mosaicImg: getMediaPath("3080_SOLEIA", "mosaic_Capture d’écran 2024-11-12 à 16.37.03.jpg"),
        video: getMediaPath("3080_SOLEIA", "Dance_v10_CONFO_240523.mp4"),
        XSvideo: getMediaPath("3080_SOLEIA", "Dance_v10_CONFO_240523.mp4"),
        year: "2024",
        description: {
          en: "Kinga Burza features choreographer Soleïla's fusion of flamenco, voguing and hip-hop, set in the Beaugrenelle district of Paris.", 
          fr: "Kinga Burza, met en scéne la chorégraphe Soleïla qui fusionne le flamenco, le voguing et le hip-hop, dans le quartier de Beaugrenelle, à Paris."
        },
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "JP Mure"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("3080_SOLEIA/Visuals", "Capture d’écran 2024-11-12 à 16.32.49.jpg"),
          getMediaPath("3080_SOLEIA/Visuals", "Capture d’écran 2024-11-12 à 16.33.09.jpg"),
          getMediaPath("3080_SOLEIA/Visuals", "Capture d’écran 2024-11-12 à 16.34.13.jpg"),
          getMediaPath("3080_SOLEIA/Visuals", "Capture d’écran 2024-11-12 à 16.35.59.jpg"),
          getMediaPath("3080_SOLEIA/Visuals", "Capture d’écran 2024-11-12 à 16.38.03.jpg"),
        ],
      },
      {
        id: 88,
        master: 3015,
        vimeoId: 1031619223,
        projectDir: "3015_TRINITY_OF_SOUND",
        customer: "CARTIER",
        name: "Trinity of sound",
        production: "U-TURN",
        director: ["BARNABY ROPER"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("3015_TRINITY_OF_SOUND", "mosaic.jpg"),
        video: getMediaPath("3015_TRINITY_OF_SOUND", "CARTIERTRINITY_H264.mp4"),
        XSvideo: getMediaPath("3015_TRINITY_OF_SOUND", "CARTIERTRINITY_H264.mp4"),
          year: "2024",
        description: {
          en: "Directed by Barnaby Roper for the CARTIER exhibition celebrating the TRINITY centenary in 2024. This immersive cinematic experience explores the interconnectedness of nature, humanity and technology. Through captivating images and a symphony of sound, we explore the cyclical nature of life and the power of unity.", 
          fr: "Réalisé par Barnaby Roper pour l’exposition de CARTIER célébrant le centenaire de la TRINITY en 2024. Cette expérience cinématographique immersive explore l’interconnexion de la nature, de l’humanité et de la technologie.Grâce à des images captivantes et à une symphonie de sons, nous explorons la nature cyclique de la vie et le pouvoir de l’unité."},
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP MURE"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["VFX ARTISTS", "Edin Hadzic, Olivier Delequeuche, Remi Devouassoud"],
          ["GRADING", "Sylvain Canaux"],
          ["FLAME ARTISTS", "François Gilguy, Christophe Richard"],
        ],
        visuals: [
          getMediaPath("3015_TRINITY_OF_SOUND/Visuals", "Cartier_4K_231110_06584 copie.jpg"),
          getMediaPath("3015_TRINITY_OF_SOUND/Visuals", "Capture d’écran 2024-12-17 à 14.06.09.jpg"),
          getMediaPath("3015_TRINITY_OF_SOUND/Visuals", "Capture d’écran 2024-12-17 à 14.05.45.jpg"),
          getMediaPath("3015_TRINITY_OF_SOUND/Visuals", "Capture d’écran 2024-12-17 à 14.09.10.jpg"),
          getMediaPath("3015_TRINITY_OF_SOUND/Visuals", "Capture d’écran 2024-12-17 à 14.16.37.jpg"),
        ],
        },  
      {
        id: 89,
        master: 3034,
        vimeoId: 1031620329,
        projectDir: "3034_HERMES_FH24",
        customer: "HERMES",
        name: "BARÉNIA",
        agency: "Publicis Et Nous",
        production: "PSYCHO",
        director: ["JAMES GRAY","https://fr.wikipedia.org/wiki/James_Gray_(r%C3%A9alisateur)"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("3034_HERMES_FH24", "mosaic.jpg"),
        alternativeImg: getMediaPath("3034_HERMES_FH24", "alternativeMosaic.jpg"),
        video: getMediaPath("3034_HERMES_FH24", "Hermès_FH24_60s_16_9_VA.mp4"),
        XSvideo: getMediaPath("3034_HERMES_FH24", "Hermès_FH24_60s_16_9_VA.mp4"),
        year: "2024",
        description: {
          fr:`« La femme Hermès, c'est une femme que rien n'arrête. Une femme mue par une curiosité et un vrai élan. Guidée par son instinct, ses pas de côtés ne sont jamais des faux pas. » – Christine Nagel, parfumeur d"Hermès. Du cuir à la peau, il n'y a qu"un pas. 
L"iconique bracelet Collier de Chien de la Maison Hermès inspire le flacon d"un parfum à son image. Quand le verre aux courbes limpides rencontre le métal précieux. avec Malgosia Bela`,
          en: `“The Hermès woman is a woman who stops at nothing. A woman driven by curiosity and a true élan. Guided by her instinct, her sideways steps are never missteps.” - Christine Nagel, Hermès perfumer. From leather to skin, there's just one step. 
The iconic Collier de Chien bracelet from the House of Hermès inspires the bottle of a fragrance in its image. When limpid glass meets precious metal. with Malgosia Bela`
        },
        credits: [
          ["POST-PRODUCER(S)", "Jean-Marc Raygade, Aurélie Lobin"], 
          ["EDITOR", "Thierry Hoss"], 
          ["FLAME ARTISTS", "Hugo Soriano, François Gilguy, Camille Miaranda, Vincent Heine, Anita Lech"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("3034_HERMES_FH24/Visuals", "Hermès_FH24_60s_16_9_VA.00_00_00_00.Still001.jpg"),
          getMediaPath("3034_HERMES_FH24/Visuals", "Hermès_FH24_60s_16_9_VA.00_00_04_01.Still002.jpg"),
          getMediaPath("3034_HERMES_FH24/Visuals", "Hermès_FH24_60s_16_9_VA.00_00_44_08.Still003.jpg"),
          getMediaPath("3034_HERMES_FH24/Visuals", "Hermès_FH24_60s_16_9_VA_Sub_02.00.mp4"),
          getMediaPath("3034_HERMES_FH24/Visuals", "Hermès_FH24_60s_16_9_VA_Sub_01.00.mp4"),
        ],
      },
      {
        id: 90,
        master: 2792,
        vimeoId: 1033511016,
        projectDir: "2792_ART_DE_VIVRE",
        customer: "LOUIS VUITTON",
        name: "ART DE VIVRE",
        production: "BRACHFELD",
        director: ["MATTHIEU SALVAING"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2792_ART_DE_VIVRE", "Mosaic_LV.jpg"),
        video: getMediaPath("2792_ART_DE_VIVRE", "210618_LV_ART_DE_VIVRE_1-30s_16-9.mp4"),
        XSvideo: getMediaPath("2792_ART_DE_VIVRE", "210618_LV_ART_DE_VIVRE_1-30s_16-9.mp4"),
        year: "2023",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["EDITOR", "JP Mure"],
          ["VFX", "Serge Miot, Sébastien Filinger"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2792_ART_DE_VIVRE/VISUALS", "210618_10s_BOUQUET_ET_VERRES_16-9.mp4"),
          getMediaPath("2792_ART_DE_VIVRE/VISUALS", "210618_10s_FONTAINES_MALLES_16-9.mp4"),
          getMediaPath("2792_ART_DE_VIVRE/VISUALS", "210618_10s_GOLF_16-9.mp4"),
          getMediaPath("2792_ART_DE_VIVRE/VISUALS", "210618_10s_PERROQUET_BUREAU_16-9.mp4"),
          getMediaPath("2792_ART_DE_VIVRE/VISUALS", "210618_10s_SALLE_DE_JEU_16-9.mp4"),
          getMediaPath("2792_ART_DE_VIVRE/VISUALS", "210618_10s_SALON_MUSIQUE_16-9.mp4"),
        ],
      },
      {
        id: 91,
        master: 3105,
        vimeoId: 1034451504,
        projectDir: "3105_LV_PREFALL_2020",
        customer: "LOUIS VUITTON",
        name: "LV PREFALL 20 BOOK CLUB",
        production: "",
        director: ["MARCEL WEPPER","https://www.marcelwepper.com/"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("3105_LV_PREFALL_2020", "mosaic_Capture d’écran 2024-11-26 à 13.28.37.jpg"),
        video: getMediaPath("3105_LV_PREFALL_2020", "LV_PREFALL_2020_Long_Version_ST_H264_SOCIAL_MEDIA.mp4"),
        XSvideo: getMediaPath("3105_LV_PREFALL_2020", "LV_PREFALL_2020_Long_Version_ST_H264_SOCIAL_MEDIA.mp4"),
        year: "2020",
        description: {
          en: "For its Fall 2020-2021 pre-collection campaign, Louis Vuitton has turned fashion into a novel. Nicolas Ghesquière imagined a wearable library, where, as in a book, each outfit would write its own chapter. For this series of shots, whose patina is inspired by William Peter Blatty's cult science-fiction novel, “The Exorcist”, the artistic director of the Women's collections called on the house's muses: Léa Seydoux, Emma Roberts, Jaden Smith, Sophie Turner, Zhong Ghuxi Yaya, Noémie Merlant, Emma Roberts, Laure Harrier... who, for the occasion, slipped into the skin of adventurers.", 
          fr: "Pour sa campagne pré-collection automne hier 2020-2021, Louis Vuitton a fait de la mode un roman. En effet, Nicolas Ghesquière a imaginé une bibliothèque-à-porter, où, comme dans un livre, chaque tenue écrirait son propre chapitre. Pour cette série de clichés, dont la patine est inspirée du roman de science-fiction culte de William Peter Blatty, «L’Exorciste », le directeur artistique des collections Femmes a fait appel aux égéries de la maison : Léa Seydoux, Emma Roberts, Jaden Smith, Sophie Turner, Zhong Ghuxi Yaya, Noémie Merlant, Emma Roberts, Laure Harrier…, qui, pour l’occasion, se sont glissées dans la peau d’aventuriers."
        },
        credits: [
          ["POST-PRODUCER", "Aurélie Lobin"],
          ["EDITOR", "JP Mure"],
          ["2D ARTIST/AFTER EFFECTS", "Serge Miot"],
          ["VFX", "Boris Coyere, Johan Roche"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("3105_LV_PREFALL_2020/Visuals", "LV_PREFALL_2020_Long_Version_ST_H264_SOCIAL_MEDIA.00_00_32_03.Still003.jpg"),
          getMediaPath("3105_LV_PREFALL_2020/Visuals", "Capture d’écran 2024-11-26 à 13.03.17.jpg"),
          getMediaPath("3105_LV_PREFALL_2020/Visuals", "LV_PREFALL_2020_Long_Version_ST_H264_SOCIAL_MEDIA_Sub_01.00.mp4"),
          getMediaPath("3105_LV_PREFALL_2020/Visuals", "Capture d’écran 2024-11-26 à 13.04.14.jpg"),
          getMediaPath("3105_LV_PREFALL_2020/Visuals", "Capture d’écran 2024-11-26 à 13.23.32.jpg"),
        ],
      },
      {
        id: 92,
        master: 3014,
        vimeoId: 1034452497,
        projectDir: "3014_HERMES NOEL",
        customer: "HERMES",
        name: "Le 24 fait son numéro",
        production: "Mirror Mirror",
        director: ["ALICE KUNISUÉ"],
        year:"2023",
        agency:"Publicis et nous",
        tags: ["Luxe"],
        mosaicImg: getMediaPath("3014_HERMES NOEL", "mosaic_3014.jpg"),
        video: getMediaPath("3014_HERMES NOEL", "271023_HERMES_HOLIDAYS23_30s_16-9_VF_H264.mp4"),
        XSvideo: getMediaPath("3014_HERMES NOEL", "271023_HERMES_HOLIDAYS23_30s_16-9_VF_H264.mp4"),
        description: {
          en: "The Pegasus is a twirling little guide who takes us from one shop window to the next to help us discover, through his adventure, each universe singularly imagined by Isabelle Daëron.", 
          fr: "Le Pégase est un petit guide virevoltant qui nous emmène d’une vitrine à l’autre pour nous faire découvrir, à travers son aventure, chaque univers singulièrement imaginé par Isabelle Daëron"
        },
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "JP Mure"],
          ["FLAME ARTIST", "Marc Latil, Virginie Rigoussen"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("3014_HERMES NOEL/Visuals", "271023_HERMES_HOLIDAYS23_30s_16-9_VF_H264.00_00_10_11.Still001.jpg"),
          getMediaPath("3014_HERMES NOEL/Visuals", "271023_HERMES_HOLIDAYS23_30s_16-9_VF_H264.00_00_20_02.Still002.jpg"),
          getMediaPath("3014_HERMES NOEL/Visuals", "271023_HERMES_HOLIDAYS23_30s_16-9_VF_H264.00_00_25_06.Still003.jpg"),
          getMediaPath("3014_HERMES NOEL/Visuals", "271023_HERMES_HOLIDAYS23_30s_16-9_VF_H264.00_00_02_05.Still004.jpg"),
          getMediaPath("3014_HERMES NOEL/Visuals", "Capture d’écran 2024-11-27 à 12.28.52.jpg")
        ],
      },
      {
        id: 93,
        master: 4001,
        vimeoId: 1039695027,
        projectDir: "4001_CASSINA",
        customer: "Festive season",
        name: "CASSINA",
        production: "ANIMAL ANIMAL",
        year: "2024",
        director: ["Céline Lopez"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("4001_CASSINA", "mosaic_Capture d’écran 2024-12-06 à 15.40.44.jpg"),
        video: getMediaPath("4001_CASSINA", "Cassina_MainVideo_30s_16x9_VI copie.mp4"),
        XSvideo: getMediaPath("4001_CASSINA", "Cassina_MainVideo_30s_16x9_VI copie.mp4"),
        description: {
          en: "For Christmas, Cassina invites us to look up and marvel at the sparkling stars. Will you recognize these new constellations? This enchanting campaign resonates deeply as the holiday season approaches. It takes us back to our childhood, to those moments when, with fingers outstretched toward the sky, one eye closed and the other wide open, we drew shapes in the winter night, dreaming of catching a glimpse of a flying sleigh.", 
          fr: "Pour Noël, Cassina nous invite à lever les yeux et à nous émerveiller devant le scintillement des étoiles. Reconnaîtrez-vous ces nouvelles constellations ? Cette campagne enchanteresse résonne profondément à l'approche des fêtes de fin d'année. Elle nous ramène à notre enfance, à ces moments où, les doigts tendus vers le ciel, un œil fermé et l'autre grand ouvert, nous dessinions des formes dans la nuit d'hiver en rêvant d'apercevoir un traîneau volant."
        },
        credits: [
          ["ARTISTIC DIRECTION", "Céline Lopez"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["VFX", "Edin Hadzic"],
          ["ASSISTANT EDITOR", "Ilona Wargny"],
        ],
        visuals: [
          getMediaPath("4001_CASSINA/Visuals", "Capture d’écran 2024-12-06 à 15.39.12.jpg"),
          getMediaPath("4001_CASSINA/Visuals", "Capture d’écran 2024-12-06 à 15.39.51.jpg"),
          getMediaPath("4001_CASSINA/Visuals", "Capture d’écran 2024-12-06 à 15.42.25.jpg"),
        ],
      },
      {
        id: 94,
        master: 2818,
        vimeoId: 1034455021,
        projectDir: "818_HOTHOTHOT_MAGAZINE",
        customer: "HOT HOT HOT MAGAZINE",
        name: "Editorial Film",
        production: "CLM",
        director: ["JULIA NONNI"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2818_HOTHOTHOT_MAGAZINE", "mosaic.jpg"),
        video: getMediaPath("2818_HOTHOTHOT_MAGAZINE", "210920_HHH_MAGAZINE_v04.mp4"),
        XSvideo: getMediaPath("2818_HOTHOTHOT_MAGAZINE", "210920_HHH_MAGAZINE_v04.mp4"),
        year: "2022",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER", "Clément Du Passage"],
          ["EDITOR", "Adrien Franke"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2818_HOTHOTHOT_MAGAZINE/Visuals", "Capture d’écran 2024-12-17 à 16.56.53.jpg"),
          getMediaPath("2818_HOTHOTHOT_MAGAZINE/Visuals", "Capture d’écran 2024-12-17 à 16.57.18.jpg"),
          getMediaPath("2818_HOTHOTHOT_MAGAZINE/Visuals", "Capture d’écran 2024-12-17 à 16.57.49.jpg"),
          getMediaPath("2818_HOTHOTHOT_MAGAZINE/Visuals", "Capture d’écran 2024-12-17 à 16.58.42.jpg"),
          getMediaPath("2818_HOTHOTHOT_MAGAZINE/Visuals", "Capture d’écran 2024-12-17 à 17.06.01.jpg"),
          getMediaPath("2818_HOTHOTHOT_MAGAZINE/Visuals", "Capture d’écran 2024-12-17 à 17.05.16.jpg"),
        ],
      },
      {
        id: 95,
        master: 2848,
        vimeoId: 1034455674,
        projectDir: "2848_ANTIDOTE_SMTH",
        customer: "ANTIDOTE",
        name: "YANIS SMITH X LALLA RAMI",
        production: "ANTIDOTE",
        director: ["YANN WEBER"],
        tags: ["Clips"],
        mosaicImg: getMediaPath("2848_ANTIDOTE_SMTH", "mosaic_Capture d’écran 2024-11-12 à 15.49.48.jpg"),
        video: getMediaPath("2848_ANTIDOTE_SMTH", "SMTH_v06_220114.mp4"),
        XSvideo: getMediaPath("2848_ANTIDOTE_SMTH", "SMTH_v06_220114.mp4"),
        year: "2022",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "Etienne Penin"],
        ],
        visuals: [
          getMediaPath("2848_ANTIDOTE_SMTH/Visuals", "Capture d’écran 2024-11-12 à 15.47.59.jpg"),
          getMediaPath("2848_ANTIDOTE_SMTH/Visuals", "Capture d’écran 2024-12-17 à 12.49.49.jpg"),
          getMediaPath("2848_ANTIDOTE_SMTH/Visuals", "Capture d’écran 2024-12-17 à 12.50.38.jpg"),
          getMediaPath("2848_ANTIDOTE_SMTH/Visuals", "Capture d’écran 2024-12-17 à 12.51.29.jpg"),
          getMediaPath("2848_ANTIDOTE_SMTH/Visuals", "Capture d’écran 2024-12-17 à 13.27.25.jpg"),
        ],
      },    
      {
        id: 96,
        master: 2320,
        vimeoId: 1034457303,
        projectDir: "2320_Brigitte-Palladium",
        customer: "Brigitte",
        name: "Palladium",
        production: "PSYCHO FREENJOY",
        director: ["AURELIE SAADA"],
        tags: ["Clips"],
        mosaicImg: getMediaPath("2320_Brigitte-Palladium", "mosaic_Brigitte.jpg"),
        video: getMediaPath("2320_Brigitte-Palladium", "2320_CLIP_BRIGITTE_PALLADIUM_WIP09_MIX_WEB_Aurélie_Saada_2017.mp4"),
        XSvideo: getMediaPath("2320_Brigitte-Palladium", "2320_CLIP_BRIGITTE_PALLADIUM_WIP09_MIX_WEB_Aurélie_Saada_2017.mp4"),
        year: "2017",
        description: {
          en: "Palladium track from the BRIGITTE album NUES.", 
          fr: "Palladium, titre extrait de l'album NUES des BRIGITTE."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Mariana Blanco"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2320_Brigitte-Palladium/Visuals", "Capture d’écran 2024-12-16 à 11.25.50.jpg"),
          getMediaPath("2320_Brigitte-Palladium/Visuals", "Capture d’écran 2024-12-17 à 15.04.18.jpg"),
          getMediaPath("2320_Brigitte-Palladium/Visuals", "Capture d’écran 2024-12-17 à 15.04.45.jpg"),
          getMediaPath("2320_Brigitte-Palladium/Visuals", "Capture d’écran 2024-12-17 à 15.04.56.jpg"),
          getMediaPath("2320_Brigitte-Palladium/Visuals", "Capture d’écran 2024-12-17 à 15.05.15.jpg"),
        ],
      },
      {
        id: 97,
        master: 2353,
        vimeoId: 1034457774,
        projectDir: "2353_ICCARRE",
        customer: "ICCARRE",
        name: "ICCARRE X ARTISTES",
        production: "U-TURN",
        director: ["BLANCA LI"],
        tags: ["Clips"],
        mosaicImg: getMediaPath("2353_ICCARRE", "mosaic_2353.jpg"),
        video: getMediaPath("2353_ICCARRE", "2353_ICCARRE_Version_longue_4min30_20171129 STBLANC.mp4"),
        XSvideo: getMediaPath("2353_ICCARRE", "2353_ICCARRE_Version_longue_4min30_20171129 STBLANC.mp4"),
        year: "2017",
        description: {
          en: "Tilda Swinton and 4 dancers from the Blanca Li Company. Slate Hemedi, Alexandra Jezouin, Julien Gaillac and Joseph Gebrael. Film directed and choreographed by Blanca Li. Thanks to Nick Cave for Love Letter",
          fr: "Tilda Swinton et 4 danseurs de la Blanca Li Company. Slate Hemedi, Alexandra Jezouin, Julien Gaillac et Joseph Gebrael. Film réalisé et chorégraphié par Blanca Li. Remerciements à Nick Cave pour Love Letter"
        },
        credits: [
          ["EDITOR", "Mario Battistel"],
          ["AFTER EFFECTS", "Etienne Penin"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2353_ICCARRE/Visuals", "Capture d’écran 2024-12-17 à 11.17.31.jpg"),
          getMediaPath("2353_ICCARRE/Visuals", "Capture d’écran 2024-12-17 à 11.18.36.jpg"),
          getMediaPath("2353_ICCARRE/Visuals", "2353_ICCARRE_Version_longue_4min30_20171129 STBLANC.00_01_21_01.Still002.jpg"),
        ],
      },
      {
        id: 98,
        master: 2114,
        vimeoId: 1039679377,
        projectDir: "2114_Nissan",
        customer: "NISSAN",
        name: "NAVARA",
        production: "SMUGGLER",
        director: ["TRISTAN PATTERSON"],
        tags: ["Institutional"],
        agency: "",
        mosaicImg: getMediaPath("2114_Nissan/Visuals", "Capture d’écran 2024-12-10 à 16.13.47.jpg"),
        video: getMediaPath("2114_Nissan", "2114_Nissan_Navara_70sec_V2A_wip20_INTER_remaster_janvier_VI_Tristan_Patterson_2016_proresHQ.mp4"),
        XSvideo: getMediaPath("2114_Nissan", "2114_Nissan_Navara_70sec_V2A_wip20_INTER_remaster_janvier_VI_Tristan_Patterson_2016_proresHQ.mp4"),
        year: "2016",
        description: {
          en: "", 
          fr: ""
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Thierry Hoss"],
          ["VFX SUPERVISOR", "Mathieu Caulet"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2114_Nissan/Visuals", "Capture d’écran 2024-12-10 à 16.09.51.jpg"),
          getMediaPath("2114_Nissan/Visuals", "Capture d’écran 2024-12-10 à 16.11.07.jpg"),
          getMediaPath("2114_Nissan/Visuals", "Capture d’écran 2024-12-10 à 16.11.55.jpg"),
          getMediaPath("2114_Nissan/Visuals", "Capture d’écran 2024-12-10 à 16.13.47.jpg"),
          getMediaPath("2114_Nissan/Visuals", "Capture d’écran 2024-12-10 à 16.15.36.jpg"),
          getMediaPath("2114_Nissan/Visuals", "Capture d’écran 2024-12-16 à 11.13.16.jpg"),
        ],
      },
      {
        id: 99,
        master: 2212,
        vimeoId: 1039680241,
        projectDir: "2212_CHANEL_J_12",
        customer: "CHANEL",
        name: "J12 BLACK",
        production: "PSYCHO",
        director: ["JACOBS SUTTON"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2212_CHANEL_J_12", "Mosaic_Capture d’écran 2024-12-16 à 14.25.03.jpg"),
        video: getMediaPath("2212_CHANEL_J_12", "2212_Chanel_J12_White_21s_WIP09_Jacob_Sutton_2016.mp4"),
        XSvideo: getMediaPath("2212_CHANEL_J_12", "2212_Chanel_J12_White_21s_WIP09_Jacob_Sutton_2016.mp4"),
        year: "2017",
        description: {
          en: "The J12. In keeping with Mademoiselle Chanel's values, he designed a watch with a sporty look, a large, easy-to-read dial and a scratch-resistant ceramic bracelet. ", 
          fr: "La J12. Dans un souci de rester fidèle aux valeurs de Mademoiselle Chanel, il imagine une montre à l’allure sportive au cadran large et lisible et au bracelet en céramique inrayable. "
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Rugh Hegarty"],
          ["LEAD FLAME ARTIST", "Mathieu Caulet"],
          ["FLAME ARTISTS", "Julien Aucouturier, François Gilguy, Léo Verdier"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2212_CHANEL_J_12/Visuals", "Capture d’écran 2024-12-16 à 14.22.25.jpg"),
          getMediaPath("2212_CHANEL_J_12/Visuals", "Capture d’écran 2024-12-16 à 14.22.43.jpg"),
          getMediaPath("2212_CHANEL_J_12/Visuals", "Capture d’écran 2024-12-16 à 14.23.38.jpg"),
          getMediaPath("2212_CHANEL_J_12/Visuals", "Capture d’écran 2024-12-16 à 14.23.49.jpg"),
          getMediaPath("2212_CHANEL_J_12/Visuals", "Capture d’écran 2024-12-16 à 14.24.12.jpg"),
          getMediaPath("2212_CHANEL_J_12/Visuals", "Capture d’écran 2024-12-16 à 14.24.30.jpg"),
        ],
      },
      {
        id: 100,
        master: 2244,
        vimeoId: 1039680658,
        projectDir: "2244_Banquepostale",
        customer: "LA BANQUE POSTALE",
        name: "BANQUE & CITOYENNE",
        production: "LA PAC",
        director: ["AB/CD/CD"],
        agency: "M&C Saatchi. GAD",
        tags: ["Institutional"],
        mosaicImg: getMediaPath("2244_Banquepostale/Visuals", "Capture d’écran 2024-12-10 à 17.34.32.jpg"),
        video: getMediaPath("2244_Banquepostale", "2244_LA_BANQUE_POSTALE_30s_MixWEB_ABCDCD_2017.mp4"),
        XSvideo: getMediaPath("2244_Banquepostale", "2244_LA_BANQUE_POSTALE_30s_MixWEB_ABCDCD_2017.mp4"),
        year: "2017",
        description: {
          en: "",
          fr: ""
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Mark Maborough"],
          ["LEAD FLAME ARTIST", "Mathieu Caulet"],
          ["FLAME ARTISTS", "François Gilguy, Sarah Quilbé, Léo verdier"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["3D ARTIST", "Pablo Dartigues"],
          ["3D ANIMATION", "Marion Guichenuy"],
          ["TRACKING", "Yvan Galtié"],
          ["NUKE ARTISTS", "Victor Carlier, Jonathan Chaillot"],
          ["MATTE PAINTING", "Stéphanie Dargent"],
          ["AFTER EFFECTS", "Serge 'Bonnet rouge' Miot"],
          ["ILLUSTRATIONS", "Bruno Salomone"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2244_Banquepostale/Visuals", "Capture d’écran 2024-12-10 à 17.32.47.jpg"),
          getMediaPath("2244_Banquepostale/Visuals", "Capture d’écran 2024-12-10 à 17.34.32.jpg"),
          getMediaPath("2244_Banquepostale/Visuals", "Capture d’écran 2024-12-16 à 11.15.06.jpg"),
          getMediaPath("2244_Banquepostale/Visuals", "Capture d’écran 2024-12-16 à 11.17.05.jpg"),
          getMediaPath("2244_Banquepostale/Visuals", "Capture d’écran 2024-12-16 à 11.17.43.jpg"),
        ],
      },
      {
        id: 101,
        master: 2258,
        vimeoId: 1039681052,
        projectDir: "2258_DIOR_LOVECHAIN",
        customer: "DIOR",
        name: "LOVECHAIN",
        production: "CAPISCO",
        director: ["Léo Haddad"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2258_DIOR_LOVECHAIN", "mosaic_Capture d’écran 2024-12-16 à 13.16.24.jpg"),
        video: getMediaPath("2258_DIOR_LOVECHAIN", "2258_DIOR_LOVECHAIN_CHARITY_Egeries_16-9_Leo_Haddad_2017.mp4"),
        XSvideo: getMediaPath("2258_DIOR_LOVECHAIN", "2258_DIOR_LOVECHAIN_CHARITY_Egeries_16-9_Leo_Haddad_2017.mp4"),
        year: "2017",
        description: {
          en: "What would you do for love? For Dior, a host of inspiring, funny and iconic artists answered this question, and launched the Love chain.  Portman, Charlize Theron, Jennifer Lawrence, Camille Rowe, Eva Herzigova, Bella Hadid, Johnny Depp and Robert Pattinson, but also Carine Roitfeld, Carina Lau, Angelababy and Huang Xuan...superstars and anonymous, who join hands to launch the Love chain.",
          fr: "Que feriez-vous par amour ? Pour Dior, une pléiade d’artistes inspirants, drôles, iconiques, ont répondu à cette question, et lancé la Love chain, ou chaîne de l’amour.  Portman, Charlize Theron, Jennifer Lawrence, Camille Rowe, Eva Herzigova, Bella Hadid, Johnny Depp et Robert Pattinson, mais aussi Carine Roitfeld, Carina Lau, Angelababy et Huang Xuan...superstars et anonymes, qui se donnent la main pour lancer la Love chain."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Pascal Revelard"],
          ["AFTER EFFECTS ARTIST", "Sébastien Filinger"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2258_DIOR_LOVECHAIN/Visuals", "Capture d’écran 2024-12-16 à 13.16.52.jpg"),
          getMediaPath("2258_DIOR_LOVECHAIN/Visuals", "Capture d’écran 2024-12-16 à 13.17.29.jpg"),
          getMediaPath("2258_DIOR_LOVECHAIN/Visuals", "Capture d’écran 2024-12-16 à 13.17.46.jpg"),
          getMediaPath("2258_DIOR_LOVECHAIN/Visuals", "Capture d’écran 2024-12-16 à 13.18.08.jpg"),
          getMediaPath("2258_DIOR_LOVECHAIN/Visuals", "Capture d’écran 2024-12-16 à 13.18.31.jpg"),
          getMediaPath("2258_DIOR_LOVECHAIN/Visuals", "Capture d’écran 2024-12-16 à 13.19.07.jpg"),
        ],
      },
      {
        id: 102,
        master: 2388,
        vimeoId: 1039686174,
        projectDir: "2388_CHANEL_BRUME",
        customer: "CHANEL",
        name: "N°5 L'EAU ALL-OVER SPRAY",
        production: "PSYCHO",
        director: ["JACOB SUTTON"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath("2388_CHANEL_BRUME", "mosaic.jpg"),
        alternativeImg: getMediaPath("2388_CHANEL_BRUME", "alternativeMosaic.jpg"),
        video: getMediaPath("2388_CHANEL_BRUME", "2388_Chanel_50secs_vertical_Mix_20180423_proresHQ.mp4"),
        XSvideo: getMediaPath("2388_CHANEL_BRUME", "2388_Chanel_50secs_vertical_Mix_20180423_proresHQ.mp4"),
        year: "2018",
        description: {
          en: "For Chanel, the launch of N°5 l'Eau All-Over Spray and N°5 l'Eau On Hand Cream was an opportunity to create a high-intensity digital campaign. The idea is as precise as it is innovative: a short film about a dance with paradoxical gestures. Imagined by choreographer Dimitri Chamblas, the movements performed here are an energetic evocation of the new gestures induced by N°5 l'Eau All-Over Spray and N°5 l'Eau On Hand Cream. An orchestration of lines, steps and bodies; that of a “double” woman expressing her different temperaments through a cathartic dance.",
          fr: "L’occasion du lancement de N°5 l’Eau All-Over Spray et N°5 l’Eau On Hand Cream fut pour la maison Chanel celle d’éditer une campagne digitale à forte intensité. L’idée est aussi précise qu’innovante : un court film sur une danse à la gestuelle paradoxale. Imaginés par le chorégraphe Dimitri Chamblas, les mouvements ici interprétés sont l’évocation énergique des nouveaux gestes induits par N°5 l’Eau All-Over Spray et N°5 l’Eau On Hand Cream. Une orchestration de lignes, de pas et des corps ; celui d’une femme “double” exprimant ses différents tempéraments à travers une danse cathartique."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Ruth Hegarty"],
          ["VFX SUPERVISOR", "Serge Miot"],
          ["VFX ARTISTS","Margaux Le Neurès, Ludovic Voltaire"],
          ["FLAME", "François Gilguy, Marc Latil, Léo Verdier"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          
          getMediaPath("2388_CHANEL_BRUME/Visuals", "visual1.jpg"),

          getMediaPath("2388_CHANEL_BRUME/Visuals", "visual2.jpg"),
          getMediaPath("2388_CHANEL_BRUME/Visuals", "visual3.jpg"),
        ],
      },
      {
        id: 103,
        master: 2596,
        vimeoId: 1039686627,
        projectDir: "2596_Berluti",
        customer: "BERLUTI",
        name: "EDDIE PENG INTERVIEW",
        production: "WEEPER & CO",
        director: ["MARCEL WEPPER"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2596_Berluti", "Mosaic_Capture d’écran 2024-12-16 à 14.03.23.jpg"),
        video: getMediaPath("2596_Berluti", "Berluti_Eddie_Peng_55s_v11_ST_Traditional_Chinese.mp4"),
        XSvideo: getMediaPath("2596_Berluti", "Berluti_Eddie_Peng_55s_v11_ST_Traditional_Chinese.mp4"),
        year: "2019",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["EDITOR", "JP Mure"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2596_Berluti/Visuals", "Capture d’écran 2024-12-16 à 14.03.57.jpg"),
          getMediaPath("2596_Berluti/Visuals", "Capture d’écran 2024-12-16 à 14.04.27.jpg"),
          getMediaPath("2596_Berluti/Visuals", "Capture d’écran 2024-12-16 à 14.04.42.jpg"),
          getMediaPath("2596_Berluti/Visuals", "Capture d’écran 2024-12-16 à 14.04.58.jpg"),
          getMediaPath("2596_Berluti/Visuals", "Capture d’écran 2024-12-16 à 14.06.22.jpg"),
          getMediaPath("2596_Berluti/Visuals", "Capture d’écran 2024-12-16 à 14.05.18.jpg"),
        ],
      },
      {
        id: 104,
        master: 2637,
        vimeoId: 1039687526,
        projectDir: "2637_Berluti_OCT_2019",
        customer: "BERLUTI",
        name: "EDDIE PENG",
        production: "WEEPER & CO",
        director: ["MARCEL WEPPER"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2637_Berluti_OCT_2019", "mosaic.jpg"),
        video: getMediaPath("2637_Berluti_OCT_2019", "191223_Berluti_47s_16-9_H264.mp4"),
        XSvideo: getMediaPath("2637_Berluti_OCT_2019", "191223_Berluti_47s_16-9_H264.mp4"),
        year: "2019",
        description: {
          en: "",
          fr: ""
        },
        credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["EDITOR", "JP Mure"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2637_Berluti_OCT_2019/Visuals", "Capture d’écran 2024-12-16 à 13.55.14.jpg"),
          getMediaPath("2637_Berluti_OCT_2019/Visuals", "Capture d’écran 2024-12-16 à 13.55.33.jpg"),
          getMediaPath("2637_Berluti_OCT_2019/Visuals", "Capture d’écran 2024-12-16 à 13.56.16.jpg"),
          getMediaPath("2637_Berluti_OCT_2019/Visuals", "Capture d’écran 2024-12-16 à 13.57.02.jpg"),
          getMediaPath("2637_Berluti_OCT_2019/Visuals", "Capture d’écran 2024-12-16 à 13.58.11.jpg"),
          getMediaPath("2637_Berluti_OCT_2019/Visuals", "Capture d’écran 2024-12-16 à 13.57.23.jpg"),
        ],
      },
      {
        id: 105,
        master: 2852,
        vimeoId: 1039689922,
        projectDir: "2852_Hermes_Arceaux",
        customer: "HERMES",
        name: "HERMES ARCEAU",
        production: "U-TURN",
        director: ["BARNABY ROPER"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("2852_Hermes_Arceaux", "mosaic_Capture d’écran 2024-11-26 à 12.42.16.jpg"),
        video: getMediaPath("2852_Hermes_Arceaux", "ALTV_Woman_15sec_16-9_VF.mp4"),
        XSvideo: getMediaPath("2852_Hermes_Arceaux", "ALTV_Woman_15sec_16-9_VF.mp4"),
        year: "2023",
        description: {
          en: "One look at the Arceau Le Temps Voyageur tells you it's time to discover new horizons where reality is suspended in time and space.",
          fr: "Un coup d'œil à l'Arceau Le Temps Voyageur vous dit qu'il est temps de découvrir de nouveaux horizons où la réalité est suspendue dans le temps et l'espace."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["FLAME ARTISTS", "François Gilguy, Marc Latil, Vivien Salvagione"],
          ["VFX", "Serge Miot, Olivier Delequeuche, Edin Hadzic"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2852_Hermes_Arceaux/Visuals", "Capture d’écran 2024-11-26 à 12.44.40.jpg"),
          getMediaPath("2852_Hermes_Arceaux/Visuals", "Capture d’écran 2024-11-26 à 12.49.32.jpg"),
          getMediaPath("2852_Hermes_Arceaux/Visuals", "Capture d’écran 2024-12-16 à 12.55.32.jpg"),
        ],
      },
      {
        id: 106,
        master: 3055,
        vimeoId: 1039726622,
        projectDir: "3055_CHANEL_GABRIELLE_PACKSHOT",
        customer: "CHANEL",
        name: "Gabrielle Packshot",
        production: "Positive Films",
        director: ["Simone Cavadini"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath("3055_CHANEL_GABRIELLE_PACKSHOT/Visuals", "Capture d’écran 2024-12-16 à 12.19.24.jpg"),
        year: "2024",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["EDITOR", "JP Mure"],
          ["VFX", "Yves Bosson"],
          ["FLAME ARTIST", "Marc Latil"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("3055_CHANEL_GABRIELLE_PACKSHOT/Visuals", "Capture d’écran 2024-12-16 à 12.07.04.jpg"),
          getMediaPath("3055_CHANEL_GABRIELLE_PACKSHOT/Visuals", "Capture d’écran 2024-12-16 à 12.07.37.jpg"),
          getMediaPath("3055_CHANEL_GABRIELLE_PACKSHOT/Visuals", "Capture d’écran 2024-12-16 à 12.08.06.jpg"),
          getMediaPath("3055_CHANEL_GABRIELLE_PACKSHOT/Visuals", "Capture d’écran 2024-12-16 à 12.18.41.jpg"),
          getMediaPath("3055_CHANEL_GABRIELLE_PACKSHOT/Visuals", "Capture d’écran 2024-12-16 à 12.19.24.jpg"),
        ],
      },
      {
        id: 107,
        master: 3079,
        vimeoId: 1039693987,
        projectDir: "3079_CHLOÉ_KID",
        customer: "CHLOÉ KIDS",
        name: "Back to school",
        production: "EXCUSE MY FRENCH",
        director: ["KINGA BURZA"],
        tags: ["Art&Fashion"],
        mosaicImg: getMediaPath("3079_CHLOÉ_KID/Visuals", "Capture d’écran 2024-12-16 à 11.01.10.jpg"),
        video: getMediaPath("3079_CHLOÉ_KID", "240513_ChloeKids_Winter_15s.mp4"),
        XSvideo: getMediaPath("3079_CHLOÉ_KID", "240513_ChloeKids_Winter_15s.mp4"),
        year: "2024",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["EDITOR", "JP Mure"],
          ["AFTER EFFECTS", "Sébastien Filinger"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("3079_CHLOÉ_KID/Visuals", "Capture d’écran 2024-12-16 à 11.01.10.jpg"),
          getMediaPath("3079_CHLOÉ_KID/Visuals", "Capture d’écran 2024-12-16 à 11.01.21.jpg"),
          getMediaPath("3079_CHLOÉ_KID/Visuals", "Capture d’écran 2024-12-16 à 11.02.00.jpg"),
          getMediaPath("3079_CHLOÉ_KID/Visuals", "Capture d’écran 2024-12-16 à 11.04.07.jpg"),
          getMediaPath("3079_CHLOÉ_KID/Visuals", "Capture d’écran 2024-12-16 à 11.04.40.jpg"),
        ],
      },
      {
        id: 108,
        master: 2793,
        vimeoId: 1039982620,
        projectDir: "2793_LV_MENS_LOOKBOOK_21",
        customer: "LOUIS VUITON",
        name: "FALL",
        production: "PSYCHO",
        director: ["MARCEL WEPPER"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath("2793_LV_MENS_LOOKBOOK_21", "mosaic_Capture d’écran 2024-12-12 à 14.26.33.jpg"),
        video: getMediaPath("2793_LV_MENS_LOOKBOOK_21", "cut09_LV_CAPSULE_FALL copie.mp4"),
        XSvideo: getMediaPath("2793_LV_MENS_LOOKBOOK_21", "cut09_LV_CAPSULE_FALL copie.mp4"),
        year: "2021",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["EDITOR", "Etienne Penin"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2793_LV_MENS_LOOKBOOK_21/Visuals", "Capture d’écran 2024-12-12 à 14.19.21.jpg"),
          getMediaPath("2793_LV_MENS_LOOKBOOK_21/Visuals", "Capture d’écran 2024-12-12 à 14.20.36.jpg"),
          getMediaPath("2793_LV_MENS_LOOKBOOK_21/Visuals", "Capture d’écran 2024-12-12 à 14.20.55.jpg"),
          getMediaPath("2793_LV_MENS_LOOKBOOK_21/Visuals", "Capture d’écran 2024-12-12 à 14.21.13.jpg"),
          getMediaPath("2793_LV_MENS_LOOKBOOK_21/Visuals", "Capture d’écran 2024-12-12 à 14.21.36.jpg"),
        ],
      },
      {
        id: 109,
        master: 2715,
        vimeoId: 1039981907,
        projectDir: "2715bis_HERMES_VERNIS",
        customer: "HERMÈS",
        name: "LES ROUGES",
        production: "PSYCHO",
        director: ["FELIX COOPER"],
        tags: ["Beauty"],
        agency: "PUBLICIS",
        mosaicImg: getMediaPath("2715bis_HERMES_VERNIS", "mosaic_Hermes_Vernis.jpg"),
        video: getMediaPath("2715bis_HERMES_VERNIS", "210125_Hermes_Vernis_15s_WIP006_16x9_VI.mp4"),
        XSvideo: getMediaPath("2715bis_HERMES_VERNIS/9_16", "201012_Hermes_Vernis_15s_WIP006_9x16_VF_H264.mp4"),
        year: "2021",
        description: {en: "", fr: ""},
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["FLAME ARTISTS", "Christophe Richard, Yann Masson"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath("2715bis_HERMES_VERNIS/Visuals", "210125_Hermes_Vernis_15s_WIP006_16x9_VI.00_00_04_22.Still001.jpg"),
          getMediaPath("2715bis_HERMES_VERNIS/Visuals", "Capture d’écran 2024-11-13 à 13.02.22.jpg"),
          getMediaPath("2715bis_HERMES_VERNIS/Visuals", "Capture d’écran 2024-12-06 à 16.50.44.jpg"),
          getMediaPath("2715bis_HERMES_VERNIS/Visuals", "Capture d’écran 2024-12-06 à 16.54.43.jpg"),
          getMediaPath("2715bis_HERMES_VERNIS/Visuals", "Capture d’écran 2024-12-06 à 16.57.51.jpg"),
        ],
      },
      {
        id: 110,
        master: 2129,
        vimeoId: 169704068,
        projectDir: "2129_CHANEL_AHS2016",
        customer: "CHANEL",
        name: "ALLURE HOMME SPORT",
        production: "PSYCHO",
        director: ["JACOB SUTTON"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath('2129_CHANEL_AHS2016', 'mosaic_Capture d’écran 2024-12-11 à 18.06.58.jpg'),
        video: getMediaPath('2129_CHANEL_AHS2016', 'SKATE_30s_VF_VI_prores_proxy.mp4'),
        XSvideo: getMediaPath('2129_CHANEL_AHS2016', 'SKATE_30s_VF_VI_prores_proxy.mp4'),
        year: "2016",
        description: {
          en: "Chanel launches a new campaign for its fresh, invigorating fragrance Allure Homme Sport Cologne, created in 2007, embodied by three new ambassadors in a series of 3 aesthetic films directed by Jacob Sutton. Professional diver Hugo Parisi, actor Luke Grimes and skateboarder Adam Crigler boldly confront nature through 3 sports: diving, horseback riding and speed skating. A raw, poetic vision in Black & White, revealing the intensity and authenticity of this confrontation-communion with the elements, where the photographer-director's touch is recognizable.",
          fr: "Chanel lance une nouvelle campagne pour son parfum frais et tonique Allure Homme Sport Cologne, créé en 2007, incarné par trois nouveaux ambassadeurs dans une série de 3 films esthétiques réalisés par Jacob Sutton. Ainsi, le plongeur professionnel Hugo Parisi, l’acteur Luke Grimes et le skateur Adam Crigler se confrontent à la nature avec audace à travers 3 sports, plongeon, équitation et skate de vitesse. Une vision brute et poétique au Noir et Blanc révélateur de l’intensité et l’authenticité de cette confrontation-communion avec les éléments, où se reconnaît la touche du photographe- réalisateur.",
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Ruth Hegarty"],
          ["VFX SUPERVISOR", "Mathieu Caulet"],
          ["FLAME ARTISTS", "François Gilguy, Julien Aucouturier"],
          ["3D ARTIST", "Jonathan Chaillot"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [    
          getMediaPath('2129_CHANEL_AHS2016/Visuals', 'DIVE_30s_VO_VI_prores_proxy.mp4'),
          getMediaPath('2129_CHANEL_AHS2016/Visuals', 'HORSE_30s_VO_VI_prores_proxy.mp4'),
          getMediaPath('2129_CHANEL_AHS2016/Visuals', 'Capture d’écran 2024-12-11 à 18.07.38.jpg'),
          getMediaPath('2129_CHANEL_AHS2016/Visuals', 'Capture d’écran 2024-12-16 à 10.34.16.jpg'),
          getMediaPath('2129_CHANEL_AHS2016/Visuals', 'Capture d’écran 2024-12-16 à 10.52.55.jpg'),

        ],
      },
      {
        id: 111,
        master: 2605,
        vimeoId: "1048617120",
        projectDir: "2605_CHANEL",
        customer: "CHANEL",
        name: "Rouge Allure Camélia - Limited Edition",
        production: "PSYCHO",
        director: ["SOLVE SUNDSBO"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath('2605_CHANEL', 'mosaic.jpg'),
        video: getMediaPath('2605_CHANEL', '191203_ROUGE_ALLURE_MO_15s_1920x1080 copie.mp4'),
        XSvideo: getMediaPath('2605_CHANEL', '191203_ROUGE_ALLURE_MO_15s_1920x1080 copie.mp4'),
        year: "2020",
        description: {
          en: 'The camellia sets the tone. Kristen Stewart is wearing the ROUGE ALLURE VELVET 627 Camélia Carmin.',
          fr: 'Le camélia donne la couleur. Kristen Stewart porte le ROUGE ALLURE VELVET 627 Camélia Carmin.'
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "Adriana Legay"],
          ["FLAME", "François Gilguy, Damien Peiro, Léo Verdier"],
          ["GRADING", "Sylvain Canaux"]
        ],
        visuals: [
          getMediaPath('2605_CHANEL/Visuals', 'Capture d’écran 2025-01-09 à 11.32.55.jpg'),
          getMediaPath('2605_CHANEL/Visuals', 'Capture d’écran 2025-01-09 à 11.33.37.jpg'),
          getMediaPath('2605_CHANEL/Visuals', 'Capture d’écran 2025-01-09 à 11.34.20.jpg'),
        ],
      },
      {
        id: 112,
        master: 2969,
        vimeoId: "1048618917",
        projectDir: "2969_CHANEL_VERTIGO",
        customer: "CHANEL",
        name: "BLEU DE CHANEL REVEAL",
        production: "",
        director: ["MARIO SORENTI"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath('2969_CHANEL_VERTIGO', 'Mosaic_BLEU.jpg'),
        video: getMediaPath('2969_CHANEL_VERTIGO', 'CHANEL_BLEU_2023_30s_16x9_FR_TV_-23lufs.mp4'),
        XSvideo: getMediaPath('2969_CHANEL_VERTIGO', 'CHANEL_BLEU_2023_30s_16x9_FR_TV_-23lufs.mp4'),
        year: "2023",
        description: {
          fr: 'If You Were interview — BLEU DE CHANEL - Timothée Chalamet incarne le parfum pour homme, créé par Jacques Polge pour Chanel en 2010.  Une interview menée avec une énergie dynamique et brute.  « l’esprit visionnaire et avant-gardiste du prochain chapitre du jus : un homme qui ne se limite pas aux frontières ordinaires de la vie et qui porte son regard au-delà des décrets du visible ».',
          en: "If You Were interview - BLEU DE CHANEL - Thimotée Chalamet embodies the men's fragrance created by Jacques Polge for Chanel in 2010.  An interview conducted with raw, dynamic energy.  He describes “the visionary, avant-garde spirit of the next chapter of the juice: a man who doesn't limit himself to the ordinary boundaries of life, who looks beyond the decrees of the visible”."
        },
        credits: [
          ["POST-PRODUCER", "Aurélie Lobin"],
          ["FLAME ARTIST", "Anita Lech, Marc Latil"],
        ],
        visuals: [
          getMediaPath('2969_CHANEL_VERTIGO/VISUALS', 'Capture d’écran 2025-01-20 à 16.18.35.jpg'),
          getMediaPath('2969_CHANEL_VERTIGO/VISUALS', 'Capture d’écran 2025-01-20 à 16.19.08.jpg'),
          getMediaPath('2969_CHANEL_VERTIGO/VISUALS', '240202_SeaOfStrength_9x16_15s_Slide_v04_VI.mp4'),
        ],
      },
      {
        id: 113,
        master: 3074,
        vimeoId: "1048941790",
        projectDir: "3074_Stellantis",
        customer: "STELLANTIS",
        name: "Stellantis",
        production: "GRAND BAZAR",
        director: ["Katia Lewkowicz"],
        tags: ["Institutional"],
        mosaicImg: getMediaPath('3074_Stellantis', 'mosaic.jpg'),
        video: null,
        XSvideo: null,
        year: "2024",
        description: {en: '', fr: ''},
        credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["EDITOR", "Thierry Hoss"],
          ["GRADING", "Movie Magic International Srl"]
        ],
        visuals: [
          getMediaPath('3074_Stellantis/Visuals', 'visual1.jpg'),
          getMediaPath('3074_Stellantis/Visuals', 'visual3.jpg'),
          getMediaPath('3074_Stellantis/Visuals', 'visual2.jpg'),
          getMediaPath('3074_Stellantis/Visuals', 'visual4.jpg'),
          getMediaPath('3074_Stellantis/Visuals', 'visual5.jpg'),
        ],
      },
      {
        id: 114,
        master: 2918,
        vimeoId: 1049982409,
        projectDir: "2918_VanCleef",
        customer: "VAN CLEEF & ARPELS",
        name: "Haute Joaillerie",
        production: "DIGITAL / 3D",
        director: ["Yves Bosson"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath('2918_VanCleef', 'mosaic_VanCleef.jpg'),
        video: getMediaPath('2918_VanCleef', 'SnowFlake_SfnBeauty_NeckLace_16x9_VF_Gamma.mp4'),
        XSvideo: getMediaPath('2918_VanCleef', 'SnowFlake_SfnBeauty_NeckLace_16x9_VF_Gamma.mp4'),
        year: "2022",
        description: {
          en: '',
          fr: ''
        },
        credits: [
          ["POST-PRODUCER", "Daniel Rota"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["VFX", "Sylvery Bolotte, Edin Hadzic, Max Pasquiou"]
        ],
        visuals: [
          getMediaPath('2918_VanCleef/Visuals', 'Capture d’écran 2025-01-23 à 13.35.07.jpg'),
          getMediaPath('2918_VanCleef/Visuals', 'Capture d’écran 2025-01-23 à 13.35.56.jpg'),
          getMediaPath('2918_VanCleef/Visuals', 'Capture d’écran 2025-01-23 à 13.35.33.jpg'),
        ],
      },
      {
        id: 115,
        master: 3073,
        vimeoId: 1049982039,
        projectDir: "3073_Armani",
        customer: "GIORGIO ARMANI",
        name: "HOLIDAY",
        production: "URSUS",
        director: ["SOLVE SUNDSBO"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath('3073_Armani', 'mosaic_Armani.jpg'),
        video: getMediaPath('3073_Armani', 'Armani_Holiday_20s_v32_240516_16x9.mp4'),
        XSvideo: getMediaPath('3073_Armani', 'Armani_Holiday_20s_v32_240516_16x9.mp4'),
        year: "2024",
        description: {
          en: 'A gifting Adventure. Embark on an enchanting journey imbued with a festive magic to uncover selection of Armani Gifts, ideal for everyone to celebrate the season of sharing.',
          fr: "Embarquez pour un voyage enchanteur imprégné d'une magie festive et découvrez une sélection de cadeaux Armani, idéaux pour célébrer la saison du partage."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["VFX", "Yves Bosson"],
          ["FLAME ARTISTS", "Hugo Soriano, Vincent Heine, Camille Miranda, Alexis Bodiou"],
          ["GRADING", "Sylvain Canaux"]
        ],
        visuals: [
          getMediaPath('3073_Armani/Visuals', 'Capture d’écran 2025-01-23 à 13.23.49.jpg'),
          getMediaPath('3073_Armani/Visuals', 'Capture d’écran 2025-01-23 à 13.25.31.jpg'),
          getMediaPath('3073_Armani/Visuals', 'Capture d’écran 2025-01-23 à 13.22.53.jpg'),
          getMediaPath('3073_Armani/Visuals', 'visual1.jpg'),
          getMediaPath('3073_Armani/Visuals', 'Capture d’écran 2025-01-23 à 13.22.22.jpg'),
        ],
      },
      {
        id: 116,
        master: 3065,
        vimeoId: 1055513446,
        projectDir: "3065_HA_HYDRA_2024",
        customer: "L'ORÉAL",
        name: "HA HYDRA",
        production: "U-TURN",
        director: ["BARNABY ROPER"],
        tags: ["Beauty"],
        mosaicImg: getMediaPath('3065_HA_HYDRA_2024', 'Mosaic_3065_HA_HYDRA_2024.jpg'),
        video: getMediaPath('3065_HA_HYDRA_2024', 'HA_Dircut_v16_confo_3D_CURRENT_VA_MASTER_16x9.mp4'),
        XSvideo: getMediaPath('3065_HA_HYDRA_2024', 'HA_Dircut_v16_confo_3D_CURRENT_VA_MASTER_16x9.mp4'),
        year: "2025",
        description: {
          en: '',
          fr: ''
        },
        credits: [
          ["POST-PRODUCER", "Clément du Passage"],
          ["EDITOR", "JP Mure"],
          ["FLAME ARTIST", "Vivien Salvagione"],
          ["GRADING", "Sylvain Canaux"]
        ],
        visuals: [
          getMediaPath('3065_HA_HYDRA_2024/Visuals', 'Capture d’écran 2025-02-10 à 16.15.07.jpg'),
          getMediaPath('3065_HA_HYDRA_2024/Visuals', 'Capture d’écran 2025-02-10 à 16.15.30.jpg'),
          getMediaPath('3065_HA_HYDRA_2024/Visuals', 'Capture d’écran 2025-02-10 à 16.16.51.jpg'),
          getMediaPath('3065_HA_HYDRA_2024/Visuals', 'Capture d’écran 2025-02-10 à 16.15.54.jpg'),
          getMediaPath('3065_HA_HYDRA_2024/Visuals', 'Capture d’écran 2025-02-10 à 16.18.40.jpg'),
        ],
      },
      {
        id: 117,
        master: 3066,
        vimeoId: 1065103456,
        projectDir: "3066_AlainPaul_FW_25",
        customer: "ALAIN PAUL",
        name: "FALL WINTER 25",
        production: "",
        director: ["MARCEL WEPPER"],
        tags: ["Luxe"],
        mosaicImg: getMediaPath('3066_AlainPaul_FW_25', 'Mosaic_AlainPaul25.jpg'),
        video: getMediaPath('3066_AlainPaul_FW_25', 'ALAIN_PAUL_240320_FINAL_16_9.mp4'),
        XSvideo: getMediaPath('3066_AlainPaul_FW_25', 'ALAIN_PAUL_240320_FINAL_16_9.mp4'),
        year: "2025",
        description: {'en': '', 'fr': ''},
        credits: [
          ["POST-PRODUCER", "Aurélie Lobin"],
          ["EDITOR", "JP Mure"],
          ["AFTER EFFECTS", "Supralude"],
          ["GRADING", "Sylvain Canaux"]
        ],
        visuals: [
          getMediaPath('3066_AlainPaul_FW_25/Visuals', 'Capture d’écran 2025-03-06 à 15.52.30.jpg'),
          getMediaPath('3066_AlainPaul_FW_25/Visuals', 'Capture d’écran 2025-03-06 à 15.52.59.jpg'),
          getMediaPath('3066_AlainPaul_FW_25/Visuals', 'Capture d’écran 2025-03-06 à 15.53.36.jpg'),
          getMediaPath('3066_AlainPaul_FW_25/Visuals', 'Capture d’écran 2025-03-06 à 15.53.58.jpg'),
          getMediaPath('3066_AlainPaul_FW_25/Visuals', 'Capture d’écran 2025-03-06 à 15.55.10.jpg'),
        ],
      },
      {
        id: 118,
        master: 3072,
        vimeoId: 1065104191,
        projectDir: "3072_LOUIS VUITTON 520",
        customer: "LOUIS VUITTON",
        name: "LOUIS VUITTON 520",
        production: "",
        director: "",
        tags: ["Luxe"],
        mosaicImg: getMediaPath('3072_LOUIS VUITTON 520', 'mosaic_3072-LV.jpg'),
        video: getMediaPath('3072_LOUIS VUITTON 520', 'COMMERCIAL_DIGITAL_520_DAY_MAIN_20s_1920x1080.mov'),
        XSvideo: getMediaPath('3072_LOUIS VUITTON 520', 'COMMERCIAL_DIGITAL_520_DAY_MAIN_20s_1920x1080.mov'),
        year: "2025",
        description: {en: '', fr: ''},
        credits: [
          ["POST-PRODUCER", "Aurélie Lobin"],
          ["EDITOR", "JP Mure"],
          ["AFTER EFFECTS", "Supralude"],
          ["GRADING", "Sylvain Canaux"]
        ],
        visuals: [
          getMediaPath('3072_LOUIS VUITTON 520/Visuals', 'Capture d’écran 2025-02-11 à 14.51.40.jpg'),
          getMediaPath('3072_LOUIS VUITTON 520/Visuals', 'Capture d’écran 2025-02-11 à 14.47.13.jpg'),
          getMediaPath('3072_LOUIS VUITTON 520/Visuals', 'Capture d’écran 2025-02-11 à 14.49.30.jpg'),
          getMediaPath('3072_LOUIS VUITTON 520/Visuals', 'Capture d’écran 2025-02-11 à 14.46.08.jpg'),
          getMediaPath('3072_LOUIS VUITTON 520/Visuals', 'Capture d’écran 2025-02-11 à 14.50.16.jpg'),
        ],
      },
      {
        id: 119,
        master: 3089,
        vimeoId: 1065105139,
        projectDir: "3089_INTERMARCHÉ OPUSMARQUE",
        customer: "INTERMARCHÉ",
        name: "HISTOIRE D'UN AMOUR",
        production: "GRAND BAZAR",
        agency: "ROMANCE",
        director: ["KATIA LEWKOWICZ"],
        tags: ["Institutional"],
        mosaicImg: getMediaPath('3089_INTERMARCHÉ OPUSMARQUE', 'mosaic_3089-Intermarché.jpg'),
        video: getMediaPath('3089_INTERMARCHÉ OPUSMARQUE', 'ITM Love - 60s - Vers.2_240909.mov'),
        XSvideo: getMediaPath('3089_INTERMARCHÉ OPUSMARQUE', 'ITM Love - 60s - Vers.2_240909.mov'),
        year: "2024",
        description: {en: '', fr: ''},
        credits: [
          ["POST-PRODUCER", "Aurélie Lobin"],
          ["EDITOR", "Thierry Hoss"],
        ],
        visuals: [
          getMediaPath('3089_INTERMARCHÉ OPUSMARQUE/Visuals', 'Capture d’écran 2025-02-11 à 14.24.50.jpg'),
          getMediaPath('3089_INTERMARCHÉ OPUSMARQUE/Visuals', 'Capture d’écran 2025-02-11 à 14.26.43.jpg'),
          getMediaPath('3089_INTERMARCHÉ OPUSMARQUE/Visuals', 'Capture d’écran 2025-02-11 à 14.26.14.jpg'),
          getMediaPath('3089_INTERMARCHÉ OPUSMARQUE/Visuals', 'Capture d’écran 2025-02-11 à 14.22.45.jpg'),
          getMediaPath('3089_INTERMARCHÉ OPUSMARQUE/Visuals', 'Capture d’écran 2025-02-11 à 14.24.05.jpg'),
          getMediaPath('3089_INTERMARCHÉ OPUSMARQUE/Visuals', 'Capture d’écran 2025-02-11 à 14.22.07.jpg'),
        ],
      },
      {
        id: 120,
        master: 3091,
        vimeoId: 1065106456,
        projectDir: "3091_HR_2025",
        customer: "HELENA RUBINSTEIN",
        name: "A New beggining is a gift",
        production: "U-TURN",
        director: ["BARNABY ROPER"],
        agency: "PUBLICIS CONSEIL",
        tags: ["Beauty"],
        mosaicImg: getMediaPath('3091_HR_2025', 'mosaic_HelenaRubinstein_2025.jpg'),
        video: getMediaPath('3091_HR_2025', 'HRCNY25_15s_v19_16x9_VFX_NkS_241209_graded_confo_VA_sRGB.mp4'),
        XSvideo: getMediaPath('3091_HR_2025', 'HRCNY25_15s_v19_16x9_VFX_NkS_241209_graded_confo_VA_sRGB.mp4'),
        year: "2025",
        description: {en: '', fr: ''},
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["VFX SUPERVISOR", "Yves Bosson"],
          ["VFX ARTISTS", "Sylvery Bolotte, Edin Hadzic, Rémi Devouassoud"],
          ["FLAME ARTIST", "Christophe Richard, François Gilguy"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath('3091_HR_2025/Visuals', 'Capture d’écran 2025-02-26 à 14.56.44.jpg'),
          getMediaPath('3091_HR_2025/Visuals', 'Capture d’écran 2025-02-26 à 14.57.16.jpg'),
          getMediaPath('3091_HR_2025/Visuals', 'Capture d’écran 2025-02-26 à 14.58.38.jpg'),
        ],
      },
      {
        id: 121,
        master: 3114,
        vimeoId: 1065107385,
        projectDir: "3114_SNCFConnect25",
        customer: "SNCF CONNECT",
        name: "SNCF CONNECT",
        production: "GRAND BAZAR",
        agency: "Agence Rosa",
        director: ["Katia Lewkowicz"],
        tags: ["Institutional"],
        mosaicImg: getMediaPath('3114_SNCFConnect25', 'Mosaic_SNCF-Connect.jpg'),
        video: getMediaPath('3114_SNCFConnect25', 'SNCF_CONNECT_16x9_85s_250226_WEB_MASTER_1.mp4'),
        XSvideo: getMediaPath('3114_SNCFConnect25', 'SNCF_CONNECT_16x9_85s_250226_WEB_MASTER_1.mp4'),
        year: "2025",
        description: {
          en: '', 
          fr: ''
        },
        credits: [
          ["POST-PRODUCER(F)", "Aurélie Lobin"],
          ["DIGITAL EDITOR", "JP Mure"],
          ["EDITOR", "Thierry Hoss"],
          ["VFX", "Fred Sauzet Petit"],
          ["FLAME ARTIST", "François Gilguy"],
          ["GRADING", "Sylvain Canaux"],
        ],
        visuals: [
          getMediaPath('3114_SNCFConnect25/Visuals', 'Capture d’écran 2025-03-11 à 13.06.37.jpg'),
          getMediaPath('3114_SNCFConnect25/Visuals', 'Capture d’écran 2025-03-11 à 13.08.13.jpg'),
          getMediaPath('3114_SNCFConnect25/Visuals', 'Capture d’écran 2025-03-11 à 13.08.55.jpg'),
          getMediaPath('3114_SNCFConnect25/Visuals', 'Capture d’écran 2025-03-11 à 13.12.27.jpg'),
          getMediaPath('3114_SNCFConnect25/Visuals', 'Capture d’écran 2025-03-11 à 13.18.59.jpg'),
        ],
      },
      {
        id: 122,
        master: 3082,
        vimeoId: 1067287741,
        projectDir: "3082_Intermarché",
        customer: "INTERMARCHÉ",
        name: "DRIVE",
        production: "GRAND BAZAR",
        agency: "ROMANCE",
        director: ["Katia Lewkowicz"],
        tags: ["Institutional"],
        mosaicImg: getMediaPath('3082_Intermarché', 'Mosaic_3082_Intermarché.jpg'),
        video: getMediaPath('3082_Intermarché', 'Intermarche_ITM_André_45sec_grade_v1_NEW_PACKSHOT.mov'),
        XSvideo: getMediaPath('3082_Intermarché', 'Intermarche_ITM_André_45sec_grade_v1_NEW_PACKSHOT.mov'),
        year: "2024",
        description: {
          en: '',
          fr: ''
        },
        credits: [
          ["POST-PRODUCER", "Aurélie Lobin"],
          ["EDITOR", "Eric Jaquemin"],
          ["GRADING", "Sylvain Canaux"]
        ],
        visuals: [
          getMediaPath('3082_Intermarché/Visuals', 'Capture d’écran 2025-03-18 à 15.34.49.jpg'),
          getMediaPath('3082_Intermarché/Visuals', 'Capture d’écran 2025-03-18 à 15.35.33.jpg'),
          getMediaPath('3082_Intermarché/Visuals', 'Capture d’écran 2025-03-18 à 15.33.01.jpg'),
          getMediaPath('3082_Intermarché/Visuals', 'Capture d’écran 2025-03-18 à 15.34.12.jpg'),
          getMediaPath('3082_Intermarché/Visuals', 'Capture d’écran 2025-03-18 à 15.33.33.jpg'),
        ],
      },
      {
        id: 123,
        master: 3096,
        vimeoId: 1074334080,
        projectDir: "3096_OSAKA_Cartier",
        customer: "CARTIER",
        name: "Cartier Osaka",
        production: "U-TURN",
        director: ["BARNABY ROPER"],
        agency: "Publicis Conseil",
        tags: ["Luxe"],
        mosaicImg: getMediaPath('3096_OSAKA_Cartier', 'Capture d’écran 2025-04-10 à 15.45.40.jpg'),
        video: getMediaPath('3096_OSAKA_Cartier', 'Cartier_Osaka_30s_v12b_241008_16x9_confo_VA.mp4'),
        XSvideo: getMediaPath('3096_OSAKA_Cartier', 'Cartier_Osaka_30s_v12b_241008_16x9_confo_VA.mp4'),
        year: "2025",
        description: {
          en: 'When women thrive, humanity thrives. Cartier believes in the future of a brighter world. By empowering women. By coming together. By living in harmony and with true equality. Where each individual finds a purpose and contributes to shaping tomorrow. As a part of the human collective. WOMEN’S pavilion expo 2025 Osaka, Kansai, Japan',
          fr: "'When women thrive, humanity thrives' Cartier croit en un avenir meilleur pour le monde. Un monde où les femmes ont les moyens d’agir. Et vivent en harmonie et en toute égalité. Un monde où chaque individu contribue à construire l’avenir. En tant qu’acteur du collectif humain WOMEN’S pavilion expo 2025 Osaka, Kansai, Japon."
        },
        credits: [
          ["POST-PRODUCER", "Jean-Marc Raygade"],
          ["EDITOR", "JP Mure"],
          ["ASSISTANT EDITOR",  "Axel Butte, Alexis Cellé"],
          ["VFX RESEARCH", "Yves Bosson, Boris Coyere"],
          ["FLAME ARTIST",  " Hugo Soriano, Camille Miranda"],
          ["GRADING", "Sylvain Canaux"],
          ["GRADING ASSISTANT", "Norman Lemarié"],
        ],
        visuals: [
          getMediaPath('3096_OSAKA_Cartier/Visuals', 'Capture d’écran 2025-04-10 à 15.50.56.jpg'),
          getMediaPath('3096_OSAKA_Cartier/Visuals', 'Capture d’écran 2025-04-10 à 15.51.34.jpg'),
          getMediaPath('3096_OSAKA_Cartier/Visuals', 'Capture d’écran 2025-04-10 à 15.52.33.jpg'),
          getMediaPath('3096_OSAKA_Cartier/Visuals', 'Capture d’écran 2025-04-10 à 15.53.46.jpg'),
          getMediaPath('3096_OSAKA_Cartier/Visuals', 'Capture d’écran 2025-04-10 à 16.01.11.jpg'),
        ],
      },
    ];
  
export default projectsData;